import { FC } from 'react'

interface ConnectionIconProps {
	fill?: string
}

export const ConnectionIcon: FC<ConnectionIconProps> = ({ fill }) => {
	return (
		<svg
			width="30px"
			height="30px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18 3.70825C19.2447 5.11852 20 6.97101 20 8.9999C20 11.0288 19.2447 12.8813 18 14.2915M6 3.70825C4.75527 5.11852 4 6.97101 4 8.9999C4 11.0288 4.75527 12.8813 6 14.2915"
				stroke={fill ?? '#3894eb'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 6.35425C15.6224 7.05938 16 7.98563 16 9.00007C16 10.0145 15.6224 10.9408 15 11.6459M9 6.35425C8.37764 7.05938 8 7.98563 8 9.00007C8 10.0145 8.37764 10.9408 9 11.6459"
				stroke={fill ?? '#3894eb'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 9V20"
				stroke={fill ?? '#3894eb'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
