import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	name: string
	value: number
	measurement?: string
	isPCH?: boolean
}

export const SBV: FC<Props> = ({
	name,
	value,
	measurement,
	isPCH
}) => {
	const { t } = useTranslation()
	return (
		<Flex
			align="center"
			style={{ width: isPCH ? 164 : 240 }}
			gap={10}>
			<span
				style={{
					flexGrow: 1
				}}>
				{t(name)}
			</span>{' '}
			<b
				style={{
					fontSize: 26,
					color: '#3894eb',
					width: 60,
					height: 38,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: 4,
					backgroundColor: '#fff',
					border: '1px solid #DDE2EB',
					fontFamily: 'Bebas Neue'
				}}>
				{value}
			</b>
			{!!measurement && (
				<span style={{ color: '#8A9EB4', width: 15 }}>
					{t(measurement)}
				</span>
			)}
		</Flex>
	)
}
