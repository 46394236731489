import { FC } from 'react'

interface Props {
	color: string
	bgColor: string
}

const VentilationIcon: FC<Props> = ({ color, bgColor }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none">
			<rect width="44" height="44" rx="4" fill={bgColor} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 28.75C5 28.3358 5.33579 28 5.75 28H10H16H28H34H38.25C38.6642 28 39 28.3358 39 28.75C39 29.1642 38.6642 29.5 38.25 29.5H35.937C35.9781 29.6598 36 29.8274 36 30C36 31.1046 35.1046 32 34 32C32.8954 32 32 31.1046 32 30H30C30 31.1046 29.1046 32 28 32C26.8954 32 26 31.1046 26 30C26 29.8274 26.0219 29.6598 26.063 29.5H17.937C17.9781 29.6598 18 29.8274 18 30C18 31.1046 17.1046 32 16 32C14.8954 32 14 31.1046 14 30H12C12 31.1046 11.1046 32 10 32C8.89543 32 8 31.1046 8 30C8 29.8274 8.02188 29.6598 8.06301 29.5H5.75C5.33579 29.5 5 29.1642 5 28.75Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 11H35C36.1046 11 37 11.8954 37 13V24.5C37 25.6046 36.1046 26.5 35 26.5H9C7.89543 26.5 7 25.6046 7 24.5V18.8575C8.79021 20.1393 10.5173 20.7529 12 21L12 21.8439C12 22.6952 12.996 23.1572 13.6459 22.6073L17.5978 19.2634C18.0699 18.8639 18.0699 18.1361 17.5978 17.7366L13.6459 14.3927C12.996 13.8428 12 14.3047 12 15.1561V16C10.9121 16 9.08448 15.852 7 15.2343V13C7 11.8954 7.89543 11 9 11ZM29 14C29 13.4477 29.4477 13 30 13H34C34.5523 13 35 13.4477 35 14V18C35 18.5523 34.5523 19 34 19H30C29.4477 19 29 18.5523 29 18V14ZM22 13C21.4477 13 21 13.4477 21 14V18C21 18.5523 21.4477 19 22 19H26C26.5523 19 27 18.5523 27 18V14C27 13.4477 26.5523 13 26 13H22Z"
				fill={color}
			/>
			<path
				d="M13.8342 21.2502C13.5124 21.5395 13 21.3111 13 20.8783L13 20C9.8391 20 5 18 2 13C6 16 9.81736 17 13 17L13 16.0822C13 15.6558 13.4994 15.4251 13.8241 15.7015L16.5647 18.0343C16.7951 18.2304 16.7999 18.5846 16.5749 18.7869L13.8342 21.2502Z"
				fill={color}
			/>
		</svg>
	)
}

export default VentilationIcon
