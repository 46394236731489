import axios from '../axios'
import { Delta } from '../helpers/types/delta'

export const fetchDeltas: (pch: boolean) => Promise<Delta[]> = async (
	pch
) => {
	const { data } = await axios.get('/api/v1/delta-group', {
		params: { pch }
	})
	return data
}

export const fetchDeltaData: (
	carrierId: number,
	pch: boolean
) => Promise<Delta[]> = async (carrierId, pch) => {
	const { data } = await axios.get(
		`/api/v1/delta-group/data?carrierId=${carrierId}`,
		{
			params: { pch }
		}
	)
	return data
}
