import { FC } from 'react'
import { Line } from 'react-chartjs-2'
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip
} from 'chart.js'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

const SensorDataChart: FC = () => {
	return (
		<Line
			options={{
				responsive: true,
				plugins: {
					legend: {
						position: 'top'
					},
					tooltip: {
						mode: 'x',
						intersect: true,
						callbacks: {
							label: (x) => {
								return x.formattedValue
							},
							title: (x) => {
								return `Время: ${x[0].label}`
							}
						},
						backgroundColor: '#2D4056',
						position: 'nearest',
						bodySpacing: 5,
						bodyFont: {
							size: 14,
							lineHeight: 1.5
						},
						titleFont: {
							size: 14,
							lineHeight: 1.5,
							weight: 'bold'
						},
						cornerRadius: 6,
						padding: 10,
						boxHeight: 1,
						boxWidth: 1,
						boxPadding: 16,
						xAlign: 'center',
						yAlign: 'bottom'
					}
				},
				elements: {
					point: {
						borderWidth: 4.5,
						hoverRadius: 7,
						radius: 7,
						pointStyle: 'circle',
						backgroundColor: 'white',
						borderColor: 'white'
					},
					line: {
						borderJoinStyle: 'round',
						borderWidth: 2,
						tension: 0.4,
						fill: false,
						segment: {
							borderWidth: 0
						}
					}
				},
				layout: {
					padding: {
						top: 100
					},
					autoPadding: false
				}
			}}
			data={{
				labels: [
					'Red',
					'Blue',
					'Yellow',
					'Green',
					'Purple',
					'Orange'
				],
				datasets: [
					{
						label: '# of Votes',
						data: [12, 19, 3, 5, 2, 3],
						backgroundColor: ['rgba(255, 99, 132, 0.2)'],
						borderColor: ['rgba(255, 99, 132, 1)'],
						borderWidth: 1
					},
					{
						label: '# of Votes',
						data: [2, 3, 12, 19, 3, 5],
						backgroundColor: ['rgba(54, 162, 235, 0.2)'],
						borderColor: ['rgba(54, 162, 235, 1)'],
						borderWidth: 1
					}
				]
			}}
		/>
	)
}

export default SensorDataChart
