import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../components'
import { RNUsers } from '../helpers/routeNames'
import { CellCols } from '../helpers/cellColumns'
import { userStore } from '../store/user'
import { Link, useNavigate } from 'react-router-dom'

const Users: FC = () => {
	const { t } = useTranslation()
	const { users } = userStore()
	const navigate = useNavigate()

	const columns = CellCols(RNUsers, [
		{
			title: t('Name'),
			dataIndex: 'name',
			key: 'name',
			render: (text: string, a: any) => (
				<Link to={`${RNUsers}/${a.id}`}>{text}</Link>
			)
		},
		{
			title: t('Email'),
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: t('IsDeleted'),
			dataIndex: 'isDeleted',
			key: 'isDeleted',
			render: (text: boolean) => <>{t(text ? 'Yes' : 'No')}</>
		}
	])
	const [searchEls, setSearchEls] = useState(users)

	useEffect(() => {
		setSearchEls(users)
	}, [users])

	const onSearch = (value: string) => {
		if (value === '') {
			setSearchEls(users)
			return
		}
		setSearchEls(
			users.filter((el) => {
				return el.name
					.toLowerCase()
					.includes(value.toLowerCase())
			})
		)
	}

	const onAdd = () => {
		navigate(RNUsers + '/new')
	}

	const onRefresh = () => {}
	return (
		<div className="users">
			<header className="page-header">
				<h1 className="page-h1">{t('Users')}</h1>
			</header>
			<main>
				<Table
					dataSource={searchEls}
					columns={columns}
					onSearch={onSearch}
					onAdd={onAdd}
					onRefresh={onRefresh}
				/>
			</main>
		</div>
	)
}

export default Users
