import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { RNCarriers } from '../helpers/routeNames'
import { carrierStore } from '../store/carrier'

const CarrierEdit: FC = () => {
	const { t } = useTranslation()
	const { id } = useParams<{ id: string }>()
	const navigate = useNavigate()

	const {
		carriers,
		updateCarrier,
		deleteCarrier: deleteCarrierFC,
		restoreCarrier
	} = carrierStore()

	const carrier = carriers.find((el) => el.id === +id!)

	const onFinish = async (values: any) => {
		await updateCarrier({
			...values,
			id: +id!,
			carrierGroupId: 1
		})
		navigate(RNCarriers)
	}

	const deleteCarrier = async () => {
		if (carrier?.isDeleted) {
			await restoreCarrier(+id!)
		} else if (carrier?.isDeleted === false) {
			await deleteCarrierFC(+id!)
		}
		navigate(RNCarriers)
	}

	return (
		<div className="carriers">
			<header className="page-header">
				<h1 className="page-h1">
					{t('EditCarrier')} № {id}
				</h1>
			</header>
			<main className="carrier-one">
				<Form
					labelCol={{ span: 3 }}
					onFinish={onFinish}
					initialValues={{
						name: carrier?.name,
						factoryNumber: carrier?.factoryNumber
					}}>
					<Form.Item
						label={t('Name')}
						name={'name'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Name') as string} />
					</Form.Item>

					<Form.Item
						label={t('Factory Number')}
						name={'factoryNumber'}
						rules={[{ required: true }]}>
						<Input
							placeholder={
								t('Factory Number') as string
							}
						/>
					</Form.Item>

					{/* <Form.Item
						label={t('IsDeleted?')}
						name={'isDeleted'}
						rules={[{ required: true }]}
						valuePropName="checked">
						<Checkbox name="isDeleted" />
					</Form.Item> */}
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{t('Save')}
						</Button>
						<Button
							htmlType="button"
							onClick={() => navigate(RNCarriers)}
							style={{ marginLeft: '20px' }}
							danger>
							{t('Cancel')}
						</Button>
						<Button
							type="primary"
							htmlType="button"
							onClick={deleteCarrier}
							style={{ marginLeft: '20px' }}
							danger>
							{carrier?.isDeleted
								? t('Restore')
								: t('Delete')}
						</Button>
					</Form.Item>
				</Form>
			</main>
		</div>
	)
}

export default CarrierEdit
