import { Flex, Modal } from 'antd'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deltaStore } from '../store/delta'
import { Delta } from '../helpers/types/delta'

export const DeltaComponent: FC<{
	carrierId: number
	isPCH?: boolean
}> = ({ carrierId, isPCH }) => {
	const { t } = useTranslation()
	const {
		fetchDeltas,
		fetchPCHDeltas,
		delta: deltaR,
		pchDelta,
		deltaData,
		pchDeltaData,
		fetchDeltaData,
		fetchPCHDeltaData,
		isFetched
	} = deltaStore()

	const [selected, setSelected] = useState<
		Delta['delta'][0] & {
			index: number
			index2: number
			name: string
			color: string
		}
	>()

	useEffect(() => {
		async function a() {
			if (isPCH) {
				if (!pchDelta.length)
					await fetchPCHDeltas({ pch: !!isPCH })
				await fetchPCHDeltaData(carrierId, !!isPCH)
			} else {
				if (!deltaR.length) await fetchDeltas()
				await fetchDeltaData(carrierId)
			}
		}
		a()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		carrierId,
		fetchDeltas,
		fetchDeltaData,
		fetchPCHDeltas,
		fetchPCHDeltaData,
		isPCH
	])

	if (!(isPCH ? pchDeltaData : deltaData) || !isFetched)
		return <></>

	const deltaDataColor = (
		deltaData: Record<number, Delta[]>,
		index: number,
		index2: number,
		index3: number
	) => {
		if (
			(!index && index !== 0) ||
			(!index2 && index2 !== 0) ||
			(!index3 && index3 !== 0) ||
			!(isPCH ? pchDeltaData : deltaData)[carrierId]
		)
			return '#C1C8D6'
		const value = (isPCH ? pchDeltaData : deltaData)[carrierId][
			index
		]?.delta[index2]?.deltaData[0]?.data[index3]
		if (!value || value === 0) return '#C1C8D6'
		else if (value === 1) return '#4AC382'
		else if (value === 2) return '#EEAD4A'
		else if (value === 3) return '#F05742'
		return '#C1C8D6'
	}

	// const isLightColor = (hexColor: string) => {
	// 	const rgb = hexColor.startsWith('#')
	// 		? parseInt(hexColor.slice(1), 16)
	// 		: parseInt(hexColor, 16)
	// 	const r = (rgb >> 16) & 0xff
	// 	const g = (rgb >> 8) & 0xff
	// 	const b = rgb & 0xff

	// 	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

	// 	return luminance > 0.5
	// }

	const deltaStyle: CSSProperties = {
		width: 'fit-content',
		gap: 5
	}

	const deltaDataStyle: CSSProperties = {
		backgroundColor: 'var(--bg-color-zarafshon)',
		height: 540,
		width: 52,
		borderRadius: 6
	}

	const deltaHeaderStyle: CSSProperties = {
		margin: 0,
		textAlign: 'center',
		fontSize: 17,
		fontWeight: 700,
		color: '#142D49',
		height: 20
	}

	const deltaHRStyle: (color: string) => CSSProperties = (
		color
	) => {
		return {
			height: 2,
			backgroundColor: color,
			borderRadius: 2,
			border: 'none',
			margin: 0
		}
	}

	return (
		<Flex gap={10}>
			{(isPCH ? pchDelta : deltaR).map((deltaGroup, index) => {
				if (
					!deltaGroup.color ||
					!deltaGroup.name ||
					!deltaGroup.id
				)
					return <></>
				return (
					<Flex style={deltaStyle} vertical key={index}>
						<h3 style={deltaHeaderStyle}>
							{deltaGroup.name}
						</h3>
						<hr style={deltaHRStyle(deltaGroup.color)} />
						<Flex gap={10}>
							{[...deltaGroup.delta]
								.sort((a, b) => a.serial - b.serial)
								.map((delta, index2) => (
									<div
										key={index2}
										style={deltaDataStyle}
										onClick={() =>
											setSelected({
												...delta,
												index,
												index2,
												name: deltaGroup.name!,
												color: deltaGroup.color!
											})
										}>
										<Flex
											justify="space-between"
											style={{ height: 30 }}>
											<div
												style={{
													width: 24,
													height: 24,
													borderRadius:
														'6px 0',
													backgroundColor:
														deltaGroup.color,
													verticalAlign:
														'middle',
													textAlign:
														'center',
													color:
														// isLightColor(
														// 	deltaGroup.color
														// )
														// 	? '#000' :
														'#fff',
													fontSize: 16,
													fontWeight: 600,
													paddingTop: 2
												}}>
												{delta.serial}
											</div>
											<Flex
												align="center"
												justify="center"
												style={{
													height: 30,
													paddingRight: 5
												}}>
												<b
													style={{
														color: '#3894eb'
													}}>
													{t('IN')}
												</b>
											</Flex>
										</Flex>
										{delta.inputCodes.map(
											(code, index3) => (
												<Flex
													key={index3}
													justify="space-between"
													align="center"
													style={{
														height: 30,
														padding:
															'0 5px'
													}}>
													{code}{' '}
													<div
														style={{
															backgroundColor:
																deltaDataColor(
																	isPCH
																		? pchDeltaData
																		: deltaData,
																	index,
																	index2,
																	index3
																),
															width: 12,
															height: 12,
															borderRadius: 6
														}}></div>
												</Flex>
											)
										)}

										<Flex
											justify="flex-end"
											align="center"
											style={{
												height: 30,
												paddingRight: 5
											}}>
											<b
												style={{
													color: '#3894eb'
												}}>
												{t('OUT')}
											</b>
										</Flex>
										{delta.outputCodes.map(
											(code, index3) => (
												<Flex
													key={index3}
													justify="space-between"
													align="center"
													style={{
														height: 30,
														padding:
															'0 5px'
													}}>
													{code}{' '}
													<div
														style={{
															backgroundColor:
																deltaDataColor(
																	isPCH
																		? pchDeltaData
																		: deltaData,
																	index,
																	index2,
																	index3 +
																		8
																),
															width: 12,
															height: 12,
															borderRadius: 6
														}}></div>
												</Flex>
											)
										)}
									</div>
								))}
						</Flex>
					</Flex>
				)
			})}
			<Modal
				open={!!selected}
				onCancel={() => setSelected(undefined)}
				footer={null}>
				{selected && (
					<Flex
						gap={10}
						style={{ overflow: 'auto', width: '100%' }}>
						<Flex style={deltaStyle} vertical>
							<h3 style={deltaHeaderStyle}>
								{selected.name}
							</h3>
							<hr
								style={deltaHRStyle(selected.color)}
							/>
							<Flex gap={10}>
								<div style={deltaDataStyle}>
									<Flex
										justify="space-between"
										style={{ height: 30 }}>
										<div
											style={{
												width: 24,
												height: 24,
												borderRadius: '6px 0',
												backgroundColor:
													selected.color,
												verticalAlign:
													'middle',
												textAlign: 'center',
												color:
													// isLightColor(
													// 	selected.color
													// )
													// 	? '#000' :
													'#fff',
												fontSize: 16,
												fontWeight: 600,
												paddingTop: 2
											}}>
											{selected.serial}
										</div>
										<Flex
											align="center"
											justify="center"
											style={{
												height: 30,
												paddingRight: 5
											}}>
											<b
												style={{
													color: '#3894eb'
												}}>
												{t('IN')}
											</b>
										</Flex>
									</Flex>
									{selected.inputCodes.map(
										(code, index3) => (
											<Flex
												key={index3}
												justify="space-between"
												align="center"
												style={{
													height: 30,
													padding: '0 5px'
												}}>
												{code}{' '}
												<div
													style={{
														backgroundColor:
															deltaDataColor(
																isPCH
																	? pchDeltaData
																	: deltaData,
																selected.index,
																selected.index2,
																index3
															),
														width: 12,
														height: 12,
														borderRadius: 6
													}}></div>
											</Flex>
										)
									)}

									<Flex
										justify="flex-end"
										align="center"
										style={{
											height: 30,
											paddingRight: 5
										}}>
										<b
											style={{
												color: '#3894eb'
											}}>
											{t('OUT')}
										</b>
									</Flex>
									{selected.outputCodes.map(
										(code, index3) => (
											<Flex
												key={index3}
												justify="space-between"
												align="center"
												style={{
													height: 30,
													padding: '0 5px'
												}}>
												{code}{' '}
												<div
													style={{
														backgroundColor:
															deltaDataColor(
																isPCH
																	? pchDeltaData
																	: deltaData,
																selected.index,
																selected.index2,
																index3 +
																	8
															),
														width: 12,
														height: 12,
														borderRadius: 6
													}}></div>
											</Flex>
										)
									)}
								</div>
							</Flex>
						</Flex>
						<Flex
							vertical
							style={{ width: 'max-content' }}>
							<Flex
								vertical
								style={{
									backgroundColor: 'var(--bg-color-zarafshon)',
									marginTop: 62,
									borderRadius: 4,
									width: 'max-content',
									minWidth: '100%'
								}}>
								{selected.inputNumbers.map(
									(number, index) => (
										<Flex
											key={index}
											align="center"
											gap={10}
											style={{
												height: 30,
												padding: '0 5px',
												width: '100%'
											}}>
											<span
												style={{
													width: 95
												}}>
												{t('INPUT №')}{' '}
												{number}
											</span>
											<span
												style={{
													flex: 1,
													width: 'max-content'
												}}>
												{selected.inputNames[
													index
												] +
													selected
														.inputNames[
														index
													]}
											</span>
										</Flex>
									)
								)}
							</Flex>
							<Flex
								vertical
								style={{
									backgroundColor: 'var(--bg-color-zarafshon)',
									marginTop: 30,
									borderRadius: 4,
									width: 'max-content',
									minWidth: '100%'
								}}>
								{selected.outputNumbers.map(
									(number, index) => (
										<Flex
											key={index}
											align="center"
											gap={10}
											style={{
												height: 30,
												padding: '0 5px',
												width: '100%'
											}}>
											<span
												style={{
													width: 95
												}}>
												{t('OUTPUT №')}{' '}
												{number}
											</span>
											<span
												style={{
													flex: 1,
													width: 'max-content'
												}}>
												{
													selected
														.outputNames[
														index
													]
												}
											</span>
										</Flex>
									)
								)}
							</Flex>
						</Flex>
					</Flex>
				)}
			</Modal>
		</Flex>
	)
}
