import { FC } from 'react'

interface NetworkIconProps {
	count?: number
}

const NetworkIcon: FC<NetworkIconProps> = ({ count = 4 }) => {
	return (
		<svg
			width="32"
			height="29"
			viewBox="0 0 32 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="29" rx="4" fill="#203042" />
			<rect
				x="22"
				y="6"
				width="3"
				height="15"
				rx="1.5"
				fill={count > 3 ? '#74C24F' : '#475D75'}
			/>
			<rect
				x="17"
				y="9"
				width="3"
				height="12"
				rx="1.5"
				fill={count > 2 ? '#74C24F' : '#475D75'}
			/>
			<rect
				x="12"
				y="12"
				width="3"
				height="9"
				rx="1.5"
				fill={count > 1 ? '#74C24F' : '#475D75'}
			/>
			<rect
				x="7"
				y="15"
				width="3"
				height="6"
				rx="1.5"
				fill={count > 0 ? '#74C24F' : '#475D75'}
			/>
		</svg>
	)
}

export default NetworkIcon
