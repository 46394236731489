import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../components'
import { RNSensors } from '../helpers/routeNames'
import { CellCols } from '../helpers/cellColumns'
import { sensorStore } from '../store/sensor'
import { Link, useNavigate } from 'react-router-dom'

const Sensors: FC = () => {
	const { t } = useTranslation()
	const { sensors } = sensorStore()
	const navigate = useNavigate()

	const columns = CellCols(RNSensors, [
		{
			title: t('Name'),
			dataIndex: 'name',
			key: 'name',
			render: (text: string, a: any) => (
				<Link to={`${RNSensors}/${a.id}`}>{text}</Link>
			)
		},
		{
			title: t('Code'),
			dataIndex: 'code',
			key: 'code'
		},
		{
			title: t('Type'),
			dataIndex: 'type',
			key: 'type'
		},
		{
			title: t('Measurement'),
			dataIndex: 'measurement',
			key: 'measurement'
		},
		{
			title: t('IsDeleted'),
			dataIndex: 'isDeleted',
			key: 'isDeleted',
			render: (text: boolean) => <>{t(text ? 'Yes' : 'No')}</>
		}
	])
	const [searchEls, setSearchEls] = useState(sensors)

	useEffect(() => {
		setSearchEls(sensors)
	}, [sensors])

	const onSearch = (value: string) => {
		if (value === '') {
			setSearchEls(sensors)
			return
		}
		setSearchEls(
			sensors.filter((el) => {
				return el.name
					.toLowerCase()
					.includes(value.toLowerCase())
			})
		)
	}

	const onAdd = () => {
		navigate(RNSensors + '/new')
	}

	const onRefresh = () => {}
	return (
		<div className="sensors">
			<header className="page-header">
				<h1 className="page-h1">{t('Sensors')}</h1>
			</header>
			<main>
				<Table
					dataSource={searchEls as any[]}
					columns={columns}
					onSearch={onSearch}
					onAdd={onAdd}
					onRefresh={onRefresh}
				/>
			</main>
		</div>
	)
}

export default Sensors
