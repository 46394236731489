import axios from '../axios'
import { Carrier } from '../helpers/types/carrier'

export const fetchCarriers = async () => {
	const { data } = await axios.get('/api/v1/carrier')
	return data
}

export const addCarrier = async (body: Partial<Carrier>) => {
	const { data } = await axios.post('/api/v1/carrier', body)
	return data
}

export const updateCarrier = async (body: Partial<Carrier>) => {
	const { data } = await axios.put(
		`/api/v1/carrier/${body.id}`,
		body
	)
	return data
}

export const deleteCarrier = async (id: number) => {
	const { data } = await axios.delete(`/api/v1/carrier/${id}`)
	return data
}

export const restoreCarrier = async (id: number) => {
	const { data } = await axios.delete(
		`/api/v1/carrier/restore/${id}`
	)
	return data
}
