import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const CellCols = (
	route: string,
	children: Array<Object>,
	disableEdit?: boolean
) => {
	const { t } = useTranslation()
	return [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			render: (text: string) => (
				<Link to={`${route}/${text}`}>{text}</Link>
			)
		},
		...children,
		{
			title: t('Actions'),
			key: 'actions',
			render: (text: string, a: any) => (
				<>
					<Link
						type="link"
						to={`${route}/${a.id}`}
						style={{ marginRight: '1rem' }}>
						<EyeOutlined
							style={{
								fontSize: 20,
								color: '#9CA7BC'
							}}
						/>
					</Link>
					{disableEdit ? null : (
						<Link
							type="link"
							to={`${route}/edit/${a.id}`}>
							<EditOutlined
								style={{
									fontSize: 20,
									color: '#9CA7BC'
								}}
							/>
						</Link>
					)}
				</>
			),
			width: 120
		}
	]
}
