import { FC } from 'react'

interface Props {
	percent: number
	color: string
}

const WaterBoilerIcon: FC<Props> = ({ percent, color }) => {
	return (
		<div style={{ position: 'relative', width: 110, height: 73 }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="110"
				height="73"
				viewBox="0 0 110 73"
				fill="none">
				<rect
					x="25"
					y="3"
					width="20"
					height="4"
					fill={color}
				/>
				<rect
					x="29"
					y="67"
					width="54"
					height="4"
					fill={color + 'd4'}
				/>
				<rect
					x="21"
					y="71"
					width="70"
					height="2"
					fill={color}
				/>
				<rect
					x="23"
					width="24"
					height="3"
					fill={color + 'e3'}
				/>
				<g filter="url(#filter0_ii_4094_15622)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M25 67C25.3367 67 25.67 66.9945 26 66.9834V67H85H86V66.9834C101.908 66.4528 110 53.2318 110 37C110 20.7682 101.908 7.54718 86 7.01655V7H85H26V7.01655C25.67 7.00555 25.3367 7 25 7C8.43146 7 0 20.4315 0 37C0 53.5685 8.43146 67 25 67Z"
						fill={'url(#paint0_linear_4094_15622' + color}
					/>
				</g>
				<defs>
					<filter
						id="filter0_ii_4094_15622"
						x="0"
						y="2"
						width="110"
						height="70"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="BackgroundImageFix"
							result="shape"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="-5" />
						<feGaussianBlur stdDeviation="7.5" />
						<feComposite
							in2="hardAlpha"
							operator="arithmetic"
							k2="-1"
							k3="1"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
						/>
						<feBlend
							mode="normal"
							in2="shape"
							result="effect1_innerShadow_4094_15622"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="5" />
						<feGaussianBlur stdDeviation="7.5" />
						<feComposite
							in2="hardAlpha"
							operator="arithmetic"
							k2="-1"
							k3="1"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_innerShadow_4094_15622"
							result="effect2_innerShadow_4094_15622"
						/>
					</filter>
					<linearGradient
						id={'paint0_linear_4094_15622' + color}
						x1="45.5"
						y1="67"
						x2="45.5"
						y2="7"
						gradientUnits="userSpaceOnUse"
						fill={color}>
						<stop stopColor={color} />
						<stop offset="1" stopColor={color + '84'} />
					</linearGradient>
				</defs>
			</svg>
			<p
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					fontSize: '1.5rem',
					fontWeight: 'bold',
					color: '#fff'
				}}>
				{percent}%
			</p>
		</div>
	)
}

export default WaterBoilerIcon
