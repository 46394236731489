import { FC } from 'react'

interface SensorGroupIconProps {
	isActive?: boolean
}

export const SensorGroupIcon: FC<SensorGroupIconProps> = ({
	isActive = false
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.98008 9.00004C9.98008 10.0935 9.09364 10.9799 8.00017 10.9799C6.9067 10.9799 6.02026 10.0935 6.02026 9.00004C6.02026 7.90656 6.9067 7.02013 8.00017 7.02013C9.09364 7.02013 9.98008 7.90656 9.98008 9.00004Z"
				fill={isActive ? '#fff' : '#61748A'}
			/>
			<path
				d="M11.9999 13.0002C14.2091 10.791 14.2091 7.20911 11.9999 4.99989M3.99962 13.0002C1.7904 10.791 1.7904 7.20911 3.99962 4.99989"
				stroke={isActive ? '#fff' : '#61748A'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle
				cx="21.0002"
				cy="21"
				r="2"
				fill={isActive ? '#fff' : '#61748A'}
			/>
			<path
				d="M25.0004 25.0001C27.2096 22.7909 27.2096 19.2091 25.0004 16.9999M17.0001 25.0001C14.7909 22.7909 14.7909 19.2091 17.0001 16.9999"
				stroke={isActive ? '#fff' : '#61748A'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	)
}
