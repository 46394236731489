import { Flex } from 'antd'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CollapseIcon } from '..'
import NotificationModal from '../NotificationModal'
import { SettingsIcon } from '../icons/SettingsIcon'
import { notificationStore } from '../../store/notification'
import { Carrier } from '../../helpers/types/carrier'
import { RNCarriers } from '../../helpers/routeNames'
import { ConnectionIcon } from '../icons/ConnectionIcon'

interface HeaderSwitchCarrierNotificationProps {
	hidden?: boolean
	carrier?: Carrier
	isCarrierPermission?: boolean | '' | null
}

export const HeaderSwitchCarrierNotification: FC<
	HeaderSwitchCarrierNotificationProps
> = ({ hidden, carrier, isCarrierPermission }) => {
	const navigate = useNavigate()
	const { hash } = useLocation()

	const {
		isOpen: isNotificationModalOpen,
		setIsOpen: setIsNotificationModalOpen
	} = notificationStore()

	const reconstruction = true || isCarrierPermission

	return (
		<Flex
			align="center"
			justify="space-between"
			style={{ marginBottom: 8 }}
			hidden={hidden}>
			<Flex flex={1} align="center" justify="flex-start">
				<div onClick={() => navigate('#start')}>
					<ConnectionIcon
						fill={hash === '#start' ? '#fff' : undefined}
					/>
				</div>
			</Flex>
			<Flex
				flex={1}
				align="center"
				justify="center"
				style={{ cursor: 'pointer' }}
				onClick={() =>
					navigate(
						encodeURI(
							`${RNCarriers}/${carrier!.id}?select&back=${hash}`
						)
					)
				}>
				<div style={{ width: 30, height: 30 }}></div>
				<h2
					style={{
						margin: 0,
						color: hash === '#start' ? '#fff' : '#142D49'
					}}>
					{carrier ? carrier.factoryNumber : '00000'}
				</h2>
				<CollapseIcon
					collapsed={false}
					expandedColor={
						hash === '#start' ? '#fff' : '#142D49'
					}
				/>
			</Flex>
			<Flex
				flex={1}
				align="center"
				justify="flex-end"
				className="search">
				<div className="search-notification">
					<NotificationModal
						isOpen={isNotificationModalOpen}
						setIsOpen={setIsNotificationModalOpen}
					/>
				</div>
				<div
					className="search-notification"
					style={{
						cursor: reconstruction
							? 'not-allowed'
							: 'pointer',
						filter: reconstruction ? 'grayscale(1)' : ''
					}}
					onClick={() => {
						if (reconstruction) return
						navigate('#settings')
					}}>
					<SettingsIcon selected={false} />
				</div>
			</Flex>
		</Flex>
	)
}
