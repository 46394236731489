import { SyncOutlined } from '@ant-design/icons'
import { FC } from 'react'

interface Props {
	loading: boolean
}

export const SyncIcon: FC<Props> = ({ loading }) => {
	return (
		<SyncOutlined
			style={{
				fontSize: 20,
				color: '#9CA7BC',
				cursor: 'pointer',
				marginLeft: 20
			}}
			spin={loading}
		/>
	)
}
