import { FC } from 'react'
import { Table, TableProps, Input, Button } from 'antd'
import { PlusOutlined, SyncOutlined } from '@ant-design/icons'

export const TableComponent: FC<
	TableProps<Record<string, string | number | boolean>> & {
		onSearch: (value: string) => void
		onAdd: () => void
		onRefresh: () => void
		disableAddButton?: boolean
		disableEditButton?: boolean
	}
> = ({
	dataSource,
	columns,
	onSearch,
	onAdd,
	onRefresh,
	disableAddButton,
	disableEditButton
}) => {
	if (dataSource && dataSource[0]?.hasOwnProperty('order')) {
		dataSource = [...dataSource].sort((a, b) => {
			if (a.order < b.order) return -1
			if (a.order > b.order) return 1
			return 0
		})
	}
	return (
		<>
			<Table
				dataSource={dataSource}
				columns={columns}
				title={() => (
					<>
						<Input.Search
							placeholder="Search"
							onSearch={onSearch}
							style={{
								maxWidth: 255,
								marginRight: 'auto'
							}}
						/>
						<Button
							onClick={onAdd}
							disabled={disableAddButton}>
							<PlusOutlined />
						</Button>
						<Button type="default" onClick={onRefresh}>
							<SyncOutlined />
						</Button>
					</>
				)}
			/>
			<style>{`
				.ant-table-title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 1rem;
				}
			`}</style>
		</>
	)
}
