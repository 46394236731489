import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../components'
import { RNCarriers } from '../helpers/routeNames'
import { CellCols } from '../helpers/cellColumns'
import { carrierStore } from '../store/carrier'
import { Link, useNavigate } from 'react-router-dom'

const Carriers: FC = () => {
	const { t } = useTranslation()
	const { carriers } = carrierStore()
	const navigate = useNavigate()

	const columns = CellCols(RNCarriers, [
		{
			title: t('Name'),
			dataIndex: 'name',
			key: 'name',
			render: (text: string, a: any) => (
				<Link to={`${RNCarriers}/${a.id}`}>{text}</Link>
			)
		},
		{
			title: t('FactoryNumber'),
			dataIndex: 'factoryNumber',
			key: 'factoryNumber'
		},
		{
			title: t('IsDeleted'),
			dataIndex: 'isDeleted',
			key: 'isDeleted',
			render: (text: boolean) => <>{t(text ? 'Yes' : 'No')}</>
		}
	])
	const [searchEls, setSearchEls] = useState(carriers)

	useEffect(() => {
		setSearchEls(carriers)
	}, [carriers])

	const onSearch = (value: string) => {
		if (value === '') {
			setSearchEls(carriers)
			return
		}
		setSearchEls(
			carriers.filter((el) => {
				return el.name
					.toLowerCase()
					.includes(value.toLowerCase())
			})
		)
	}

	const onAdd = () => {
		navigate(RNCarriers + '/new')
	}

	const onRefresh = () => {}
	return (
		<div className="carriers">
			<header className="page-header">
				<h1 className="page-h1">{t('Carriers')}</h1>
			</header>
			<main>
				<Table
					dataSource={searchEls}
					columns={columns}
					onSearch={onSearch}
					onAdd={onAdd}
					onRefresh={onRefresh}
				/>
			</main>
		</div>
	)
}

export default Carriers
