import { Flex, Switch } from 'antd'
import { FC } from 'react'
import { CHV } from './sensors/CHV'
import { useTranslation } from 'react-i18next'
import { CarrierSpeed } from './CarrierSpeed'
import { sensorStore } from '../store/sensor'

interface Props {
	carrierId: number
}

export const CarrierOneSettingsRNGComp: FC<Props> = ({
	carrierId
}) => {
	const { t } = useTranslation()
	const { sensors } = sensorStore()

	const finderFC = (code: string) => {
		return Number(
			sensors
				.find((s) => {
					return (
						s.code === code &&
						!!s.sensorData.find(
							(a) => a.carrierId === carrierId
						)
					)
				})
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.value || 0
		)
	}

	return (
		<Flex gap={10}>
			<Flex vertical gap={10} style={{ width: '100%' }}>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px'
					}}>
					<h3 style={{ margin: 0 }}>{t('RNGSensors')}</h3>
					<Flex gap={10}>
						<CHV
							text="MaxNetworkVoltage"
							value={finderFC('rngs_maxnetvolt_1')}
							value2={finderFC('rngs_maxnetvolt_2')}
						/>
						<CHV
							text="NetworkVoltage"
							value={finderFC('rngs_netvolt_1')}
							value2={finderFC('rngs_netvolt_2')}
						/>
					</Flex>
					<Flex gap={10}>
						<CHV
							text="NetworkCurrent"
							value={finderFC('rngs_netcurrent_1')}
							value2={finderFC('rngs_netcurrent_2')}
						/>
						<CHV
							text="BatteryCurrent"
							value={finderFC('rngs_batcurrent_1')}
							value2={finderFC('rngs_batcurrent_2')}
						/>
					</Flex>
				</Flex>
				<Flex
					gap={10}
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px'
					}}>
					<Flex gap={10} vertical>
						<h3 style={{ margin: 0 }}>
							{t('ExcitationWinding')}
						</h3>
						<Flex gap={10}>
							<Flex gap={10} vertical>
								<CHV
									id={0}
									text="SET"
									value={finderFC('ex_set_1')}
									value2={finderFC('ex_set_2')}
								/>
							</Flex>
							<Flex gap={10} vertical>
								<CHV
									text="1...6A"
									value={finderFC('ex_1_6a_1')}
									value2={finderFC('ex_1_6a_2')}
								/>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					gap={10}
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px'
					}}>
					<Flex gap={10} vertical>
						<h3 style={{ margin: 0 }}>
							{t('Controller')}
						</h3>
						<CHV
							text="NetworkVoltage"
							value={finderFC('cn_netvolt_1')}
							value2={finderFC('cn_netvolt_2')}
						/>
					</Flex>
				</Flex>
			</Flex>
			<Flex vertical gap={10}>
				<Flex
					gap={10}
					vertical
					align="center"
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: 230
					}}>
					<h3 style={{ margin: 0, marginRight: 'auto' }}>
						{t('CarrierSpeed')}
					</h3>
					<CarrierSpeed speed={finderFC('carrier_speed')} />
				</Flex>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: 230
					}}>
					<h3 style={{ margin: 0 }}>{t('BatteryType')}</h3>
					<Flex gap={10} vertical>
						<Flex
							align="center"
							justify="center"
							style={{
								width: 200,
								height: 50,
								border: '3px solid #DDE2EB',
								borderRadius: '4px 15px 15px 4px',
								backgroundColor: 'var(--bg-color-zarafshon)',
								color: '#3894eb',
								fontSize: 20
							}}>
							<b>Щелочная</b>
						</Flex>
						<Switch
							style={{ margin: '0 auto' }}
							value={
								sensors
									.find(
										(s) =>
											s.code ===
											'battery_type_2'
									)
									?.sensorData.find(
										(s) =>
											s.carrierId === carrierId
									)?.value === '0' || false
							}
						/>
						<hr
							style={{
								width: '100%',
								borderColor: '#DDE2EB'
							}}
						/>
					</Flex>
					<h3 style={{ margin: 0 }}>
						{t('BatteryTemperature')}
					</h3>
					<div
						style={{
							width: 50,
							height: 28,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							borderRadius: 4,
							backgroundColor: '#F3F5F8',
							border: '1px solid #DDE2EB',
							fontFamily: 'Bebas Neue',
							padding: '5px'
						}}>
						<b
							style={{
								fontSize: 26,
								color: '#3894eb'
							}}>
							{finderFC('battery_temperature')}
						</b>
						<span
							style={{
								marginTop: 'auto',
								color: '#8A9EB4'
							}}>
							C&deg;
						</span>
					</div>

					<style>
						{`.ant-switch-handle::before {border-radius: 2px !important}.ant-switch {border-radius: 4px !important}`}
					</style>
				</Flex>
			</Flex>
		</Flex>
	)
}
