import { FC } from 'react'
import { authStore } from '../store/auth'

const Test: FC = () => {
	const { userinfo } = authStore()
	return (
		<div>
			<button onClick={userinfo}>123123123</button>
		</div>
	)
}

export default Test
