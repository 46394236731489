export const RNWelcome = '/welcome'
export const RNCarrierGroups = '/carrier-groups'
export const RNCarriers = '/carriers'
export const RNSensors = '/sensors'
export const RNLogging = '/logging'
export const RNUsers = '/users'
export const RNBalance = '/balance'
export const RNProfile = '/profile'
export const RNLogin = '/login'
export const RNLogout = '/logout'
export const RNNotFound = '/404'
export const RNCarrierSelect = '/select'