import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input, Table } from 'antd'
import { RNUsers } from '../helpers/routeNames'
import { userStore } from '../store/user'
import { carrierStore } from '../store/carrier'
import { carrierPermissionStore } from '../store/carrierPermission'

const UserOne: FC = () => {
	const { t } = useTranslation()
	const { id } = useParams<{ id: string }>()
	const navigate = useNavigate()

	const {
		users,
		isFetched,
		updateUser,
		deleteUser: deleteUserFC,
		restoreUser
	} = userStore()

	const { carriers, isFetched: isFetchedCarriers } = carrierStore()
	const {
		carrierPermission,
		fetchCarrierPermission,
		createCarrierPermission,
		deleteCarrierPermission,
		isFetched: isFetchedCarrierPermission
	} = carrierPermissionStore()

	const user = users.find((el) => el.id === +id!)

	const onFinish = async (values: any) => {
		await updateUser({ ...values, id: +id! })
		navigate(RNUsers)
	}

	const deleteUser = async () => {
		if (!user) return
		if (user.isDeleted) {
			await restoreUser(+id!)
		} else if (user.isDeleted === false) {
			await deleteUserFC(+id!)
		}
		navigate(RNUsers)
	}

	useEffect(() => {
		if (!id) return
		fetchCarrierPermission(+id)
	}, [id, fetchCarrierPermission])

	return (
		<div className="users">
			<header className="page-header">
				<h1 className="page-h1">
					{t('ViewUser')} № {id}
				</h1>
			</header>
			<main className="user-one">
				{!(isFetched && user) ? (
					<div>{t('Loading')}...</div>
				) : (
					<Form
						labelCol={{ span: 2 }}
						size="large"
						onFinish={onFinish}
						initialValues={{
							name: user.name,
							email: user.email
							// isDeleted: user?.isDeleted
						}}>
						<Form.Item
							label={t('Name')}
							name={'name'}
							rules={[{ required: true }]}>
							<Input
								placeholder={t('Name') as string}
							/>
						</Form.Item>

						<Form.Item
							label={t('Email')}
							name={'email'}
							rules={[{ required: true }]}>
							<Input
								placeholder={t('Email') as string}
							/>
						</Form.Item>

						{/* <Form.Item
						label={t('IsDeleted')+'?'}
						name={'isDeleted'}
						rules={[{ required: true }]}
						valuePropName="checked">
						<Checkbox name="isDeleted" />
					</Form.Item> */}
						{/* <pre>
							{JSON.stringify(
								carrierPermission,
								null,
								2
							)}
						</pre> */}
						<h2>{t('CarrierPermission')}</h2>
						{!(
							isFetchedCarriers &&
							isFetchedCarrierPermission
						) ? (
							<div>{t('Loading')}...</div>
						) : (
							<Table
								columns={[
									{
										title: t('CanView'),
										width: 200,
										align: 'left',
										render: (text, record) => (
											<Input
												type="checkbox"
												id={record.id.toString()}
												checked={
													!!carrierPermission[
														+id!
													]?.find(
														(el) =>
															el.carrierId ===
																record.id &&
															el.userId ===
																+id! &&
															el.type ===
																'view'
													) || false
												}
												onChange={(e) => {
													if (
														e.target
															.checked
													) {
														createCarrierPermission(
															{
																carrierId:
																	record.id,
																userId: +id!,
																type: 'view'
															}
														)
													} else {
														deleteCarrierPermission(
															carrierPermission[
																+id!
															]?.find(
																(
																	el
																) =>
																	el.carrierId ===
																		record.id &&
																	el.userId ===
																		+id! &&
																	el.type ===
																		'view'
															)?.id!,
															record.id,
															+id!,
															'view'
														)
													}
												}}
											/>
										)
									},
									{
										title: t('CanViewSettings'),
										width: 200,
										align: 'left',
										render: (text, record) => (
											<Input
												type="checkbox"
												id={record.id.toString()}
												checked={
													!!carrierPermission[
														+id!
													]?.find(
														(el) =>
															el.carrierId ===
																record.id &&
															el.userId ===
																+id! &&
															el.type ===
																'settings'
													) || false
												}
												onChange={(e) => {
													if (
														e.target
															.checked
													) {
														createCarrierPermission(
															{
																carrierId:
																	record.id,
																userId: +id!,
																type: 'settings'
															}
														)
													} else {
														deleteCarrierPermission(
															carrierPermission[
																+id!
															]?.find(
																(
																	el
																) =>
																	el.carrierId ===
																		record.id &&
																	el.userId ===
																		+id! &&
																	el.type ===
																		'settings'
															)?.id!,
															record.id,
															+id!,
															'settings'
														)
													}
												}}
											/>
										)
									},
									{
										title: t('CarrierName'),
										dataIndex: 'name',
										key: 'name'
									},
									{
										title: t('FactoryNumber'),
										dataIndex: 'factoryNumber',
										key: 'factoryNumber'
									}
								]}
								dataSource={carriers}
								pagination={false}
							/>
						)}
						<Form.Item>
							<Button type="primary" htmlType="submit">
								{t('Save')}
							</Button>
							<Button
								htmlType="button"
								onClick={() => navigate(RNUsers)}
								style={{ marginLeft: '20px' }}
								danger>
								{t('Cancel')}
							</Button>
							<Button
								type="primary"
								htmlType="button"
								onClick={deleteUser}
								style={{ marginLeft: '20px' }}
								danger>
								{user.isDeleted
									? t('Restore')
									: t('Delete')}
							</Button>
						</Form.Item>
					</Form>
				)}
			</main>
		</div>
	)
}

export default UserOne
