import {
	CarriagesIcon,
	LoggingIcon,
	LogoutIcon,
	SensorIcon
} from '../components'
import {
	RNCarriers,
	RNLogout,
	// RNLogging,
	RNUsers,
	// RNSensors,
	RNBalance
} from './routeNames'

export type Children = Pick<
	SideBarMenuItem,
	'path' | 'name' | 'isAdmin'
>

export interface SideBarMenuItem {
	path: string
	name: string
	Icon: any
	last?: boolean
	isAdmin?: boolean
	children?: Children[]
	isOpen?: boolean
}

export const sideBarMenuItems: (
	carriersBar: Children[]
) => SideBarMenuItem[] = (carriersBar) => [
	// {
	// 	path: RNCarrierGroups,
	// 	name: 'Carrier Groups',
	// 	Icon: CarriagesIcon,
	// },
	{
		path: RNCarriers,
		name: 'Carriers',
		Icon: CarriagesIcon,
		children: carriersBar
	},
	// {
	// 	path: RNSensors,
	// 	name: 'Sensors',
	// 	Icon: SensorIcon
	// },
	{
		path: RNBalance,
		name: 'Balance',
		Icon: SensorIcon,
		isAdmin: true
	},
	// {
	// 	path: RNLogging,
	// 	name: 'Logging',
	// 	Icon: LoggingIcon
	// },
	{
		path: RNUsers,
		name: 'Users',
		Icon: LoggingIcon,
		isAdmin: true
	},
	{
		path: RNLogout,
		name: 'Logout',
		Icon: LogoutIcon,
		last: true
	}
]
