import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
// import eruda from 'eruda'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

const language = localStorage.getItem('language') || 'ru'
i18next.use(initReactI18next).init({
	resources: {
		ru: {
			translation: {
				Welcome:
					"Добро пожаловать в приложение онлайн-мониторинг O'zbekiston Temir yo'llari!",
				Monitoring: 'Мониторинг',
				Search: 'Поиск',
				'Sensors Group': 'Группы датчиков',
				'Have a nice day!': 'Хорошего дня!',
				Heating: 'Отопление',
				'High voltage heating 3000 V':
					'Высоковольтное отопление 3000 В',
				'Electric heater': 'Электронагреватель',
				Conditioning: 'Кондиционирование',
				ClimateControl: 'Климат контроль',
				Auto: 'Авто',
				Manual: 'Ручной',
				Specified: 'Заданная',
				Coupe: 'Купе',
				Outdoor: 'Наружная',
				SupplyFan: 'Приточный вентилятор',
				HighPressure: 'Высокое давление',
				LowPressure: 'Низкое давление',
				CompressorFrequency: 'Частота компрессора',
				'Damper 1 open': 'Заслонка 1 открыта',
				'Damper 2 open': 'Заслонка 2 открыта',
				WaterBoiler: 'Водонагреватель',
				Temperature: 'Температура',
				Aqualite: 'Аквалит',
				Megalith: 'Мегалит',
				FireDamper: 'Пожарная заслонка',
				'Drinking water tank': 'Бак питьевой воды',
				MainLightning: 'Основное освещение',
				EmergencyLighting: 'Аварийное освещение',
				'Enter/exit': 'Вход/выход',
				Capacitor: 'Конденсатор',
				Compressor: 'Компрессор',
				HighHeat: 'Высоковольтное отопление',
				LowHeat: 'Низковольтное отопление',
				Calorifel: 'Калорифер',
				Climate: 'Климат',
				RNG: 'РНГ',
				PCH: 'ПЧ',
				ThermalSensors: 'Термодатчики',
				ThermalSensor: 'Термодатчик',
				Timers: 'Таймеры',
				SensorInversion: 'Инверсия датчика',
				WaterAvailablity: 'Наличие воды',
				WaterTemperature: 'Температура воды',
				WaterTank: 'Бак воды',
				PressureSensors: 'Датчики давления',
				SensorNotConfigured: 'Датчик не настроен',
				SensorConfigured: 'Датчик настроен',
				ResetResource: 'Сброс ресурса',
				Status: 'Статус',
				DELIXI: 'DELIXI',
				NoAccidents: 'Нет аварий',
				RNGSensors: 'Датчики РНГ',
				ExcitationWinding: 'Возбуждение обмотки',
				Controller: 'Контроллер',
				CarrierSpeed: 'Скорость вагона',
				BatteryType: 'Тип батареи',
				BatteryTemperature: 'Температура батареи',
				CheckTheBalance: 'Проверьте баланс',
				Power: 'Питание',
				Battery: 'Батарея',
				Батарея: 'Батарея',
				Генератор: 'Генератор',
				VoltageNetwork: 'Напряжение сети',
				Volt: 'В',
				AmperageNetwork: 'Ток сети',
				Amp: 'А',
				AmperageBattery: 'Ток батареи',
				TemperatureBattery: 'Температура батареи',
				Control: 'Управление',
				ControllerManagement: 'Управление контроллером',
				RNGControl: 'Управление РНГ',
				InternalCommunication: 'Внутренняя связь',
				LeftDoor: 'Левая дверь',
				RightDoor: 'Правая дверь',
				Protection: 'Защита',
				VTS: 'ВТС',
				VNS: 'ВНС',
				'MIN.1': 'МИН.1',
				'MIN.2': 'МИН.2',
				Carriers: 'Вагоны',
				NewCarrier: 'Новый вагон',
				Loading: 'Загрузка',
				CurrentIndications: 'Текущие показания',
				Logging: 'Логирование',
				Diagram: 'Диаграмма',
				Description: 'Описание',
				Submit: 'Сохранить',
				changePassword: 'Изменить пароль',
				oldPassword: 'Старый пароль',
				newPassword: 'Новый пароль',
				confirmPassword: 'Подтвердите пароль',
				Cancel: 'Отмена',
				'INPUT №': 'ВХОД №',
				'OUTPUT №': 'ВЫХОД №',
				Notifications: 'Уведомления',
				MarkAllAsRead: 'Отметить все как прочитанные',
				uploadPhoto: 'Загрузить фото',
				MaximalVoltage: 'Максимальное напряжение',
				ViewCarrier: 'Просмотр вагона',
				Name: 'Название',
				FactoryNumber: 'Заводской номер',
				Save: 'Сохранить',
				IsDeleted: 'Удален',
				Delete: 'Удалить',
				Restore: 'Восстановить',
				Redirecting: 'Перенаправление',
				Login: 'Вход',
				ProfileImage: 'Изображение профиля',
				fullname: 'ФИО',
				position: 'Должность',
				role: 'Роль',
				Sensors: 'Датчики',
				AddingNewSensor: 'Добавление нового датчика',
				Code: 'Код',
				Measurement: 'Измерение',
				Type: 'Тип',
				ViewSensor: 'Просмотр датчика',
				ViewUser: 'Просмотр пользователя',
				AddUser: 'Добавить пользователя',
				Email: 'Электронная почта',
				Users: 'Пользователи',
				Logout: 'Выход',
				Home: 'Главная',
				Lightning: 'Освещение',
				Consumers: 'Потребители',
				Climat: 'Климат',
				Settings: 'Настройки',
				MaxNetworkVoltage: 'Макс. напряжение сети',
				NetworkVoltage: 'Напряжение сети',
				NetworkCurrent: 'Ток сети',
				BatteryCurrent: 'Ток батареи',
				Timer: 'Таймер',
				sec: 'сек',
				'StopDC-DC': 'Стоп DC-DC',
				NotEnoughVoltage: 'Недос. напряжения',
				PCHDrawerOpen: 'Открыт ящик ПЧ',
				StopSupply: 'Стоп приточка',
				'DC-DCFault': 'Неисправность DC-DC',
				StopCondensator: 'Стоп конденсатор',
				ProtectionTemp: 'Защита по температуре',
				AnalogModule: 'Аналоговый модуль',
				inputVoltage: 'Входное напряжение',
				DrawerTemp: 'Температура ящика',
				Chastota: 'Частота',
				'DC-DCVoltage': 'DC-DC напряжение',
				Hz: 'Гц',
				InputAmper: 'Входной ток',
				VoltageAdjustment110: 'Подстройка напряжение 110 В',
				VoltageAdjustment450: 'Подстройка напряжение 450 В',
				CurrentSensorAdjustment: 'Подстройка тока датчика',
				TempSensorAdjustment:
					'Подстройка температуры датчика',
				Work: 'Работа',
				CarrierName: 'Название вагона',
				CanView: 'Может просматривать',
				CanViewSettings: 'Доступ к настройкам',
				EditCarrier: 'Редактирование вагона',
				Actions: 'Действия',
				NoResults: 'Нет результатов',
				Balance: 'Баланс',
				ReplanishmentDate: 'Дата пополнения',
				BalanceOfCarrier: 'Баланс вагона',
				WaterOverheat: 'Перегрев воды',
				LastUpdate: 'Последнее обновление',
				UpdateNow: 'Обновить сейчас',
				CarrierPermission: 'Разрешение на вагон',
				'Coming Soon!': 'Скоро!',
				Time: 'Время',
				Source: 'Источник',
				EventText: 'Текст события',
				EventLog: 'Журнал событии',
				FireDamperClosed: 'Не открылась пожарная заслонка',
				FireDamperOpen: 'Пожарная заслонка открыто',
				'New sensor data in carrier № ':
					'Новые данные датчика в вагоне № ',
				'Click to see more': 'Нажмите, чтобы увидеть больше',
				SelectCarrier: 'Выберите вагон',
				TestMode: 'ТЕСТОВЫЙ РЕЖИМ',
				NoData: 'Нет данных',
				Yes: 'Да',
				No: 'Нет',
				Continue: 'Продолжить',
				GoAhead: 'Вперед!'
			}
		},
		uz: {
			translation: {
				Welcome:
					"O'zbekiston Temir Yo'llari onlayn monitoring ilovasiga xush kelibsiz!",
				Monitoring: 'Monitoring',
				Search: 'Qidirish',
				'Sensors Group': 'Sensorlar guruhi',
				'Have a nice day!': "Kuningiz yaxshi o'tsin!",
				Heating: 'Isitish',
				'High voltage heating 3000 V':
					'Yuqori quvvatli isitish 3000 V',
				'Electric heater': 'Elektr isitgich',
				Conditioning: 'Konditsioner',
				ClimateControl: 'Iqlim nazorati',
				Auto: 'Avto',
				Manual: 'Ruchnoy',
				Specified: "Ko'rsatilgan",
				Coupe: 'Kupe',
				Outdoor: 'Tashqari',
				SupplyFan: 'Taminotchi quvurlar',
				HighPressure: 'Yuqori bosim',
				LowPressure: 'Past bosim',
				CompressorFrequency: 'Kompressor chastotasi',
				'Damper 1 open': 'Zaslonka 1 ochiq',
				'Damper 2 open': 'Zaslonka 2 ochiq',
				WaterBoiler: 'Suv isitgich',
				Temperature: 'Harorat',
				Aqualite: 'Aqualit',
				Megalith: 'Megalit',
				FireDamper: "Yong'in zaslonka",
				'Drinking water tank': 'Ichimlik suvi tanki',
				MainLightning: "Asosiy yorug'lik",
				EmergencyLighting: "Avariyalik yorug'lik",
				'Enter/exit': 'Kirish/chiquvchi',
				Capacitor: 'Kondensator',
				Compressor: 'Kompressor',
				HighHeat: 'Yuqori isitish',
				LowHeat: 'Past isitish',
				Calorifel: 'Kalorifer',
				Climate: 'Iqlim',
				RNG: 'RNG',
				PCH: 'PCH',
				ThermalSensors: 'Termo sensorlar',
				ThermalSensor: 'Termo sensor',
				Timers: 'Taymerlar',
				SensorInversion: 'Sensor inversiyasi',
				WaterAvailablity: 'Suv mavjudligi',
				WaterTemperature: 'Suv harorati',
				WaterTank: 'Suv tanki',
				PressureSensors: 'Bosim sensorlari',
				SensorNotConfigured: 'Sensor sozlanmagan',
				SensorConfigured: 'Sensor sozlangan',
				ResetResource: 'Resursni tiklash',
				Status: 'Holat',
				DELIXI: 'DELIXI',
				NoAccidents: "Aksidentlar yo'q",
				RNGSensors: 'RNG sensorlari',
				ExcitationWinding: "Kuchlantirish o'simligi",
				Controller: 'Boshqaruvchi',
				CarrierSpeed: 'Yuk tizimi tezligi',
				BatteryType: 'Batareya turi',
				BatteryTemperature: 'Batareya harorati',
				CheckTheBalance: 'Balansni tekshiring',
				Power: 'Quvvat',
				Battery: 'Batareya',
				Батарея: 'Batareya',
				Генератор: 'Generator',
				VoltageNetwork: 'Tarmoq quvvati',
				Volt: 'V',
				AmperageNetwork: 'Tarmoq amperaji',
				Amp: 'А',
				AmperageBattery: 'Batareya amperaji',
				TemperatureBattery: 'Batareya harorati',
				Control: 'Boshqarish',
				ControllerManagement: 'Boshqaruvchi boshqarish',
				RNGControl: 'RNG boshqarish',
				InternalCommunication: 'Ichki aloqa',
				LeftDoor: 'Chap eshik',
				RightDoor: "O'ng eshik",
				Protection: 'Himoya',
				VTS: 'VTS',
				VNS: 'VNS',
				'MIN.1': 'MIN.1',
				'MIN.2': 'MIN.2',
				Carriers: 'Vagonlar',
				NewCarrier: 'Yangi vagon',
				Loading: 'Yuklanmoqda',
				CurrentIndications: "Joriy ko'rsatmalar",
				Logging: 'Logging',
				Diagram: 'Diagramma',
				Description: 'Tavsif',
				Submit: 'Saqlash',
				changePassword: "Parolni o'zgartirish",
				oldPassword: 'Eski parol',
				newPassword: 'Yangi parol',
				confirmPassword: 'Parolni tasdiqlang',
				Cancel: 'Bekor qilish',
				'INPUT №': 'KIRISH №',
				'OUTPUT №': 'CHIQISH №',
				Notifications: 'Bildirishnomalar',
				MarkAllAsRead: "Barchasini o'qilgan deb belgilang",
				uploadPhoto: 'Rasm yuklash',
				MaximalVoltage: 'Maksimal quvvat',
				ViewCarrier: "Vagonni ko'rish",
				Name: 'Nomi',
				FactoryNumber: 'Zavod raqami',
				Save: 'Saqlash',
				IsDeleted: "O'chirilgan",
				Delete: "O'chirish",
				Restore: 'Qayta tiklash',
				Redirecting: "Yo'naltirish",
				Login: 'Kirish',
				ProfileImage: 'Profil rasmi',
				fullname: 'FIO',
				position: 'Lavozim',
				role: 'Rol',
				Sensors: 'Sensorlar',
				AddingNewSensor: "Yangi sensor qo'shish",
				Code: 'Kod',
				Measurement: "O'lchov",
				Type: 'Turi',
				ViewSensor: "Sensorni ko'rish",
				ViewUser: "Foydalanuvchini ko'rish",
				AddUser: "Foydalanuvchi qo'shish",
				Email: 'Elektron pochta',
				Users: 'Foydalanuvchilar',
				Logout: 'Chiqish',
				Home: 'Bosh sahifa',
				Lightning: "Yorug'lik",
				Consumers: 'Istemolchilar',
				Climat: 'Iqlim',
				Settings: 'Sozlamalar',
				MaxNetworkVoltage: 'Maksimal tarmoq quvvati',
				NetworkVoltage: 'Tarmoq quvvati',
				NetworkCurrent: 'Tarmoq amperaji',
				BatteryCurrent: 'Batareya amperaji',
				Timer: 'Taymer',
				sec: 'sek',
				'StopDC-DC': 'Stop DC-DC',
				NotEnoughVoltage: 'Quvvat yetarli emas',
				PCHDrawerOpen: 'PCH yashigi ochiq',
				StopSupply: 'Stop pritojka',
				'DC-DCFault': 'DC-DCda xatolik',
				StopCondensator: 'Stop kondensator',
				ProtectionTemp: "Temperatura bo'yicha himoya",
				AnalogModule: 'Analog modul',
				inputVoltage: 'Kirish quvvati',
				DrawerTemp: 'Yashik harorati',
				Chastota: 'Chastota',
				'DC-DCVoltage': 'DC-DC quvvati',
				Hz: 'Gts',
				InputAmper: 'Kirish amperaji',
				VoltageAdjustment110: '110 V quvvatini moslash',
				VoltageAdjustment450: '450 V quvvatini moslash',
				CurrentSensorAdjustment: 'Sensor amperajini moslash',
				TempSensorAdjustment: 'Sensor haroratini moslash',
				Work: 'Ish holatida',
				CarrierName: 'Vagon nomi',
				CanView: "Ko'rishi mumkin",
				CanViewSettings: 'Sozlamalar',
				EditCarrier: 'Vagonni tahrirlash',
				Actions: 'Harakatlar',
				NoResults: 'Natijalar yoq',
				Balance: 'Balans',
				ReplanishmentDate: "To'ldirish sanasi",
				BalanceOfCarrier: 'Vagon balansi',
				WaterOverheat: 'Suv qizib ketishi',
				LastUpdate: "So'nggi yangilanish",
				UpdateNow: 'Hozir yangilash',
				CarrierPermission: "Vagon bo'yicha ruxsat",
				'Coming Soon!': 'Tez orada!',
				Time: 'Vaqt',
				Source: 'Manba',
				EventText: 'Voqea mazmuni',
				EventLog: 'Voqealar jurnali',
				FireDamperClosed: 'Өрт сөндіруші жабық',
				FireDamperOpen: 'Өрт сөндіргіші ашық',
				'New sensor data in carrier № ':
					"Yangi datchik ma'lumoti, vagon № ",
				'Click to see more': "Ko'proq ko'rish uchun bosing",
				SelectCarrier: 'vagonni tanlang',
				TestMode: 'TEST REJIMIDA',
				NoData: "Ma'lumot yo'q",
				Yes: 'Xa',
				No: "Yo'q",
				Continue: 'Davom etish',
				GoAhead: 'Davom etish!'
			}
		},
		kz: {
			translation: {
				Welcome:
					'Өзбекстан темір жолының онлайн мониторинг қосымшасына қош келдіңіз!',
				Monitoring: 'Мониторинг',
				Search: 'Іздеу',
				'Sensors Group': 'Сенсорлар тобы',
				'Have a nice day!': 'Күніңіз әрі қонақты болсын!',
				Heating: 'Жылу',
				'High voltage heating 3000 V':
					'Жоғары токтардың 3000 В жылуы',
				'Electric heater': 'Электр жылуы',
				Conditioning: 'Кондиционер',
				ClimateControl: 'Жылу назариясы',
				Auto: 'Авто',
				Manual: 'Қолмен',
				Specified: 'Анықталған',
				Coupe: 'Купе',
				Outdoor: 'Сыртқы',
				SupplyFan: 'Тасымалдау желісі',
				HighPressure: 'Жоғары тыңдау',
				LowPressure: 'Төмен тыңдау',
				CompressorFrequency: 'Компрессор частотасы',
				'Damper 1 open': 'Заслонка 1 ашық',
				'Damper 2 open': 'Заслонка 2 ашық',
				WaterBoiler: 'Су қыздырғыш',
				Temperature: 'Температура',
				Aqualite: 'Аквалит',
				Megalith: 'Мегалит',
				FireDamper: 'Ауа заслонкасы',
				'Drinking water tank': 'Ішімді су таны',
				MainLightning: 'Негізгі жарық',
				EmergencyLighting: 'Авариялық жарық',
				'Enter/exit': 'Кіру/шығу',
				Capacitor: 'Конденсатор',
				Compressor: 'Компрессор',
				HighHeat: 'Жоғары жылу',
				LowHeat: 'Төмен жылу',
				Calorifel: 'Калорифер',
				Climate: 'Жылу',
				RNG: 'РНГ',
				PCH: 'ПЧ',
				ThermalSensors: 'Термодатчиктер',
				ThermalSensor: 'Термодатчик',
				Timers: 'Таймерлер',
				SensorInversion: 'Датчик инверсиясы',
				WaterAvailablity: 'Су қолжетімділігі',
				WaterTemperature: 'Су температурасы',
				WaterTank: 'Су таны',
				PressureSensors: 'Тыңдау датчиктері',
				SensorNotConfigured: 'Датчик орнатылмаған',
				SensorConfigured: 'Датчик орнатылған',
				ResetResource: 'Ресурсты қайта орнату',
				Status: 'Мәртебесі',
				DELIXI: 'DELIXI',
				NoAccidents: 'Авариялар жоқ',
				RNGSensors: 'РНГ датчиктері',
				ExcitationWinding: 'Қозғалту жүргізу',
				Controller: 'Басқарушы',
				CarrierSpeed: 'Көлік жылуы',
				BatteryType: 'Батарея түрі',
				BatteryTemperature: 'Батарея температурасы',
				CheckTheBalance: 'Балансты тексеріңіз',
				Power: 'Қуат',
				Battery: 'Батарея',
				Батарея: 'Батарея',
				Генератор: 'Генератор',
				VoltageNetwork: 'Желі жарықтың денесі',
				Volt: 'В',
				AmperageNetwork: 'Желі жарықтың амперажы',
				Amp: 'А',
				AmperageBattery: 'Батарея амперажы',
				TemperatureBattery: 'Батарея температурасы',
				Control: 'Басқару',
				ControllerManagement: 'Басқарушы басқаруы',
				RNGControl: 'РНГ басқаруы',
				InternalCommunication: 'Ішкі байланыс',
				LeftDoor: 'Сол жақты есік',
				RightDoor: 'Оң жақты есік',
				Protection: 'Қорғау',
				VTS: 'ВТС',
				VNS: 'ВНС',
				'MIN.1': 'МИН.1',
				'MIN.2': 'МИН.2',
				Carriers: 'Вагондар',
				NewCarrier: 'Жаңа вагон',
				Loading: 'Жүктелуде',
				CurrentIndications: 'Ағымды көрсеткіштер',
				Logging: 'Логлау',
				Diagram: 'Диаграмма',
				Description: 'Сипаттама',
				Submit: 'Сақтау',
				changePassword: 'Құпия сөзді өзгерту',
				oldPassword: 'Ескі құпия сөз',
				newPassword: 'Жаңа құпия сөз',
				confirmPassword: 'Құпия сөзді растау',
				Cancel: 'Болдырмау',
				'INPUT №': 'КІРУ №',
				'OUTPUT №': 'ШЫҒУ №',
				Notifications: 'Хабарландырулар',
				MarkAllAsRead: 'Барлығын оқылған деп белгілеу',
				uploadPhoto: 'Фото жүктеу',
				MaximalVoltage: 'Максималды дене',
				ViewCarrier: 'Вагонды қарау',
				Name: 'Атауы',
				FactoryNumber: 'Завод нөмірі',
				Save: 'Сақтау',
				IsDeleted: 'Жойылған',
				Delete: 'Жою',
				Restore: 'Қалпына келтіру',
				Redirecting: 'Жөнелту',
				Login: 'Кіру',
				ProfileImage: 'Профиль суреті',
				fullname: 'Аты-жөні',
				position: 'Лауазым',
				role: 'Роль',
				Sensors: 'Сенсорлар',
				AddingNewSensor: 'Жаңа сенсор қосу',
				Code: 'Код',
				Measurement: 'Өлшем',
				Type: 'Тип',
				ViewSensor: 'Сенсорды қарау',
				ViewUser: 'Пайдаланушыны қарау',
				AddUser: 'Пайдаланушы қосу',
				Email: 'Электрондық пошта',
				Users: 'Пайдаланушылар',
				Logout: 'Шығу',
				Home: 'Басты бет',
				Lightning: 'Жарық',
				Consumers: 'Тұтынушылар',
				Climat: 'Жылу',
				Settings: 'Параметрлер',
				MaxNetworkVoltage: 'Максималды желі денесі',
				NetworkVoltage: 'Желі денесі',
				NetworkCurrent: 'Желі амперажы',
				BatteryCurrent: 'Батарея амперажы',
				Timer: 'Таймер',
				sec: 'сек',
				'StopDC-DC': 'Стоп DC-DC',
				NotEnoughVoltage: 'Дене жетпейді',
				PCHDrawerOpen: 'PCH шулуғы ашық',
				StopSupply: 'Стоп приточка',
				'DC-DCFault': 'DC-DCда қате',
				StopCondensator: 'Стоп конденсатор',
				ProtectionTemp: 'Температура бойынша қорғау',
				AnalogModule: 'Аналогды модуль',
				inputVoltage: 'Кіру денесі',
				DrawerTemp: 'Шулуқ температурасы',
				Chastota: 'Частота',
				'DC-DCVoltage': 'DC-DC денесі',
				Hz: 'Гц',
				InputAmper: 'Кіру амперажы',
				VoltageAdjustment110: '110 V денесін қалайту',
				VoltageAdjustment450: '450 V денесін қалайту',
				CurrentSensorAdjustment: 'Сенсор амперажын қалайту',
				TempSensorAdjustment: 'Сенсор температурасын қалайту',
				Work: 'Жұмыс',
				CarrierName: 'Вагон атауы',
				CanView: 'Көру мүмкін',
				CanViewSettings: 'Параметрлер',
				EditCarrier: 'Вагонды өңдеу',
				Actions: 'Әрекеттер',
				NoResults: 'Нәтижелер жоқ',
				Balance: 'Баланс',
				ReplanishmentDate: 'Толтыру күні',
				BalanceOfCarrier: 'Вагон балансы',
				WaterOverheat: 'Су қызып кетуі',
				LastUpdate: 'Соңғы жаңарту',
				UpdateNow: 'Қазір жаңарту',
				CarrierPermission: 'Вагон рұқсаты',
				'Coming Soon!': 'Жақында келеді!',
				Time: 'Уақыт',
				Source: 'Дереккөз',
				EventText: 'Оқиға мәтіні:',
				EventLog: 'Оқиғалар журналы',
				FireDamperClosed: 'Не открылась пожарная заслонка',
				FireDamperOpen: 'Пожарная заслонка открыто',
				'New sensor data in carrier № ':
					'Жаңа сенсор деректері, № тасымалдаушыда',
				'Click to see more': 'Толығырақ көру үшін басыңыз',
				SelectCarrier: 'Каретканы таңдаңыз',
				TestMode: 'СЫНАҚ РЕЖИМІ',
				NoData: 'Мәліметтер жоқ',
				Yes: 'Иә',
				No: 'Жоқ',
				Continue: 'Жалғастыру',
				GoAhead: 'Алға!'
			}
		}
	},
	lng: language,
	fallbackLng: 'ru',

	interpolation: {
		escapeValue: false
	}
})

i18next.on('languageChanged', (lng: string) => {
	localStorage.setItem('language', lng)
})

if (!localStorage.getItem('language')) {
	localStorage.setItem('language', 'en')
}

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
)

reportWebVitals()
// eruda.init()
