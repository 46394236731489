import { FC } from 'react'

interface LogoutIconProps {
	isActive?: boolean
}

export const LogoutIcon: FC<LogoutIconProps> = ({
	isActive = false
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.79 19.29C14.18 19.68 14.81 19.68 15.2 19.29L18.79 15.7C18.8827 15.6075 18.9563 15.4976 19.0064 15.3766C19.0566 15.2557 19.0824 15.126 19.0824 14.995C19.0824 14.864 19.0566 14.7343 19.0064 14.6134C18.9563 14.4924 18.8827 14.3825 18.79 14.29L15.2 10.7C15.013 10.513 14.7594 10.408 14.495 10.408C14.2306 10.408 13.977 10.513 13.79 10.7C13.603 10.887 13.498 11.1406 13.498 11.405C13.498 11.6694 13.603 11.923 13.79 12.11L15.67 14H7C6.45 14 6 14.45 6 15C6 15.55 6.45 16 7 16H15.67L13.79 17.88C13.4 18.27 13.41 18.91 13.79 19.29ZM22 4H8C7.46957 4 6.96086 4.21071 6.58579 4.58579C6.21071 4.96086 6 5.46957 6 6V9C6 9.55 6.45 10 7 10C7.55 10 8 9.55 8 9V7C8 6.45 8.45 6 9 6H21C21.55 6 22 6.45 22 7V23C22 23.55 21.55 24 21 24H9C8.45 24 8 23.55 8 23V21C8 20.45 7.55 20 7 20C6.45 20 6 20.45 6 21V24C6 25.1 6.9 26 8 26H22C23.1 26 24 25.1 24 24V6C24 4.9 23.1 4 22 4Z"
				fill={isActive ? '#fff' : '#61748A'}
			/>
		</svg>
	)
}
