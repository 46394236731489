import { FC } from 'react'
import { sensorStore } from '../../store/sensor'
import { Skeleton } from 'antd'

interface BGCProps {
	color: string
	text: string
	alignStart?: boolean
}

export const SensorBGC: FC<BGCProps> = ({
	color,
	text,
	alignStart
}) => {
	const { isFetched: isSensorsFetched } = sensorStore()

	if (!isSensorsFetched)
		return (
			<Skeleton.Input block
				active
				style={{ minWidth: 'auto', width: '100%' }}
			/>
		)

	return (
		<div
			style={{
				backgroundColor: `var(--${color})`,
				borderRadius: '4px',
				textAlign: alignStart ? 'start' : 'center',
				padding: '5.5px',
				width: '100%',
				boxSizing: 'border-box'
			}}>
			{text}
		</div>
	)
}
