import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Flex, Input, List } from 'antd'
import { useTranslation } from 'react-i18next'
import { CollapseIcon } from '../components'
import { carrierStore } from '../store/carrier'
import { RNCarriers, RNCarrierSelect } from '../helpers/routeNames'
import { useLocation, useNavigate } from 'react-router-dom'
import { Carrier } from '../helpers/types/carrier'
import { TickIcon } from '../components/icons/TickIcon'
import { utilityStore } from '../store/utils'

interface CarrierSelectProps {
	carrierId?: number
}

const CarrierSelect: FC<CarrierSelectProps> = ({ carrierId }) => {
	const { t } = useTranslation()
	const { carriers } = carrierStore()
	const { hash, search, pathname } = useLocation()

	const {
		sideBarShown,
		headerSwitchCarrierNotificationShown,
		setHeaderSwitchCarrierNotificationShown
	} = utilityStore()

	const navigate = useNavigate()

	const [carrierSearch, setCarrierSearch] = useState<Carrier[]>([])
	const [searchCarrier, setSearchCarrier] = useState<string>('')
	const [selectedCarrier, setSelectedCarrier] = useState<Carrier>()

	useEffect(() => {
		setCarrierSearch(carriers)
	}, [carriers])

	useEffect(() => {
		if (!sideBarShown && search.includes('select'))
			setHeaderSwitchCarrierNotificationShown(false)
		else setHeaderSwitchCarrierNotificationShown(true)
		return () => setHeaderSwitchCarrierNotificationShown(true)
	}, [
		search,
		setHeaderSwitchCarrierNotificationShown,
		sideBarShown
	])

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^0-9]/g, '')

		setSearchCarrier(value)
		if (value === '') {
			setCarrierSearch(carriers)
			return
		}

		setCarrierSearch(
			carriers.filter((el) => {
				return el.name
					.toLowerCase()
					.includes(value.toLowerCase())
			})
		)
	}

	return (
		<Flex
			vertical
			gap={10}
			style={{
				height: `calc(100% - ${
					headerSwitchCarrierNotificationShown ? 36 : 0
				}px)`
			}}>
			<Flex
				vertical
				gap={16}
				style={{
					backgroundColor: 'var(--bg-color-zarafshon)'
				}}>
				{pathname !== RNCarrierSelect && (
					<span
						style={{
							rotate: '270deg',
							transform: 'scale(1.8) translateY(-3px)',
							width: 33,
							height: 33,
							cursor: 'pointer'
						}}
						onClick={() => navigate(hash)}>
						<CollapseIcon
							collapsed
							collapsedColor="#3894eb"
						/>
					</span>
				)}
				<h2 style={{ margin: 0 }}>{t('SelectCarrier')}</h2>
				<div>
					<Input.Search
						type="search"
						placeholder={t('Search')}
						value={searchCarrier}
						onChange={onSearch}
						title="123123"
						size="large"
					/>
				</div>
			</Flex>
			<List
				style={{
					background: '#fff',
					margin: '0 -20px -16px',
					height: '100%'
				}}>
				{carrierSearch.length ? (
					carrierSearch.map((carrier) => (
						<List.Item
							key={carrier.id}
							style={{
								backgroundColor:
									carrierId === carrier?.id
										? '#cacaca'
										: '#fff',
								// color: '#142d49',

								fontSize: 17,
								padding: '16px 20px',
								cursor:
									carrierId !== carrier?.id
										? 'pointer'
										: 'not-allowed',
								borderBottom: '1px solid #EAECF3',
								display:
									selectedCarrier?.id === carrier.id
										? 'flex'
										: 'block',
								justifyContent: 'space-between',
								color:
									selectedCarrier?.id === carrier.id
										? '#3894eb'
										: '#142D49'
							}}
							onClick={() => {
								if (carrierId === carrier?.id) return
								setSelectedCarrier(carrier)
							}}>
							{carrier.name}
							{selectedCarrier?.id === carrier.id && (
								<TickIcon />
							)}
						</List.Item>
					))
				) : (
					<List.Item
						style={{
							fontSize: 17,
							padding: '16px 36px'
						}}>
						{t('NoData')} 🙈🙈🙈
					</List.Item>
				)}
			</List>
			<Button
				size="large"
				type="primary"
				disabled={!selectedCarrier}
				style={{
					position: 'absolute',
					bottom: 20,
					right: 20,
					left: 20
				}}
				onClick={() =>
					navigate(RNCarriers + '/' + selectedCarrier!.id)
				}>
				{t('Continue')}
			</Button>
		</Flex>
	)
}

export default CarrierSelect
