import { FC } from 'react'
import {
	RNCarriers,
	RNProfile,
	RNLogin,
	RNLogout,
	RNCarrierGroups,
	RNLogging,
	RNUsers,
	RNSensors,
	RNBalance,
	RNWelcome,
	RNCarrierSelect
} from './helpers/routeNames'
import Test from './pages/Test'
import Login from './pages/Login'
import Users from './pages/Users'
import Logout from './pages/Logout'
import UserOne from './pages/UserOne'
import Carrier from './pages/Carrier'
import CarrierOne from './pages/CarrierOne'
import CarrierEdit from './pages/CarrierEdit'
import CarrierAdd from './pages/CarrierAdd'
import UserAdd from './pages/UserAdd'
import Sensors from './pages/Sensor'
// import SensorOne from './pages/SensorOne'
import SensorAdd from './pages/SensorAdd'
import SensorEdit from './pages/SensorEdit'
import Profile from './pages/Profile'
import Balance from './pages/Balance'
import BalanceOne from './pages/BalanceOne'
import Welcome from './pages/Welcome'
import CarrierSelect from './pages/CarrierSelect'

export interface IElementProps {
	sideBarShown: boolean
	carrierId?: number
}

interface Route {
	path: string
	Element: FC<IElementProps>
}

export const Routes: Route[] = [
	{
		path: RNWelcome,
		Element: Welcome
	},
	{
		path: RNCarriers,
		Element: Carrier
	},
	{
		path: RNCarriers + '/:id',
		Element: CarrierOne
	},
	{
		path: RNCarriers + '/new',
		Element: CarrierAdd
	},
	{
		path: RNCarriers + '/edit/:id',
		Element: CarrierEdit
	},
	{
		path: RNCarrierGroups,
		Element: Test
	},
	{
		path: RNCarrierGroups + '/:id',
		Element: Test
	},
	{
		path: RNSensors,
		Element: Sensors
	},
	{
		path: RNBalance,
		Element: Balance
	},
	{
		path: RNBalance + '/:id',
		Element: BalanceOne
	},
	{
		path: RNSensors + '/:id',
		Element: SensorEdit //SensorOne
	},
	{
		path: RNSensors + '/new',
		Element: SensorAdd
	},
	{
		path: RNSensors + '/edit/:id',
		Element: SensorEdit
	},
	{
		path: RNLogging,
		Element: Test
	},
	{
		path: RNUsers,
		Element: Users
	},
	{
		path: RNUsers + '/:id',
		Element: UserOne
	},
	{
		path: RNUsers + '/new',
		Element: UserAdd
	},
	{
		path: RNUsers + '/edit/:id',
		Element: UserOne
	},
	{
		path: RNProfile,
		Element: Profile
	},
	{
		path: RNCarrierSelect,
		Element: CarrierSelect
	},
	{
		path: '*',
		Element: Test
	},
	{
		path: RNLogout,
		Element: Logout
	}
]

export const loginRoutes: Route[] = [
	{
		path: RNLogin,
		Element: Login
	},
	{
		path: '*',
		Element: Test
	}
]
