import { FC, useEffect, useState } from 'react'
import { NotificationIcon } from './icons/NotificationIcon'
import { Button, Flex, List, Modal, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { notificationStore } from '../store/notification'
import { authStore } from '../store/auth'
import { useLocation } from 'react-router-dom'
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	InfoCircleOutlined,
	QuestionCircleOutlined,
	ShareAltOutlined
} from '@ant-design/icons'
import { RNLogin, RNLogout } from '../helpers/routeNames'
import { utilityStore } from '../store/utils'

interface NotificationModalProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
}

const NotificationModal: FC<NotificationModalProps> = ({
	isOpen,
	setIsOpen
}) => {
	const { t } = useTranslation()
	const location = useLocation()
	const {
		fetchNotifications,
		isNotificationFetched,
		notification,
		currentPage,
		totalPages,
		count,
		readNotification,
		readAllNotification
	} = notificationStore()
	const { sideBarShown } = utilityStore()
	const { user, isLoggedIn } = authStore()
	const [initLoading, setInitLoading] = useState(true)
	const [loading, setLoading] = useState(false)

	const onLoadMore = async () => {
		if (!user) return
		setLoading(true)
		await fetchNotifications(10, notification.length, user.id)
		setLoading(false)
	}

	useEffect(() => {
		if (
			isLoggedIn &&
			user &&
			!notification.length &&
			!isNotificationFetched &&
			location.pathname !== RNLogout &&
			location.pathname !== RNLogin
		) {
			const initials = async () => {
				setInitLoading(true)
				await fetchNotifications(10, 0, user!.id)
				setInitLoading(false)
				setLoading(false)
			}
			initials()
		}
	}, [
		isLoggedIn,
		user,
		notification.length,
		isNotificationFetched,
		fetchNotifications,
		location.pathname
	])

	const loadMore =
		!initLoading && !loading ? (
			<div
				style={{
					textAlign: 'center',
					marginTop: 12,
					height: 32,
					lineHeight: '32px'
				}}>
				<Button onClick={onLoadMore}>loading more</Button>
			</div>
		) : null

	const notificationTypes = {
		success: (
			<CheckCircleOutlined
				style={{ fontSize: 24, color: '#4AC382' }}
			/>
		),
		error: (
			<CloseCircleOutlined
				style={{ fontSize: 24, color: '#F05742' }}
			/>
		),
		info: <InfoCircleOutlined style={{ fontSize: 24 }} />,
		warning: (
			<QuestionCircleOutlined
				style={{ fontSize: 24, color: '#EEAD4A' }}
			/>
		)
	}

	return (
		<>
			<NotificationIcon
				count={count}
				color={!sideBarShown && '#3894eb'}
				onClick={() => setIsOpen(true)}
			/>
			<Modal
				title={t('Notifications')}
				open={isOpen}
				footer={
					<Button
						onClick={readAllNotification}
						block
						disabled={!count}
						type="primary">
						{t('MarkAllAsRead')}
					</Button>
				}
				onCancel={() => setIsOpen(false)}>
				<List
					loading={initLoading}
					itemLayout="horizontal"
					loadMore={
						currentPage < totalPages ? loadMore : null
					}
					locale={{ emptyText: t('NoData') }}
					dataSource={notification}
					style={{
						overflowY: 'auto',
						height: 'calc(100vh - 250px)'
					}}
					renderItem={(item) => (
						<List.Item
							actions={[
								<a
									href={item.link}
									rel="noreferrer"
									target="_blank"
									onClick={() => {
										readNotification(item.id)
										setIsOpen(false)
									}}>
									<ShareAltOutlined
										style={{ fontSize: 24 }}
									/>
								</a>
							]}>
							<Skeleton
								avatar
								title={false}
								loading={loading}
								active>
								<List.Item.Meta
									avatar={
										notificationTypes[item.type]
									}
									title={
										<Flex>
											{item.title}
											{!item.isRead && (
												<span
													style={{
														color: '#3894eb',
														fontSize: 30,
														lineHeight:
															'16px'
													}}>
													•
												</span>
											)}
										</Flex>
									}
									description={item.description}
									style={{ alignItems: 'center' }}
								/>
							</Skeleton>
						</List.Item>
					)}
				/>
			</Modal>
		</>
	)
}

export default NotificationModal
