import { create } from 'zustand'
import { Delta } from '../helpers/types/delta'
import { fetchDeltaData, fetchDeltas } from '../api/delta'
import { Carrier } from '../helpers/types/carrier'
import { DeltaData } from '../helpers/types/deltaData'

export interface DeltaState {
	delta: Delta[]
	deltaData: Record<Carrier['id'], Delta[]>
	pchDelta: Delta[]
	pchDeltaData: Record<Carrier['id'], Delta[]>
	isFetched: boolean
	fetchDeltas: () => Promise<void>
	fetchPCHDeltas: ({ pch }: { pch: boolean }) => Promise<void>
	fetchDeltaData: (carrierId: number) => Promise<void>
	fetchPCHDeltaData: (
		carrierId: number,
		pch: boolean
	) => Promise<void>
	setNewDeltaData: (data: DeltaData & { isPCH?: boolean }) => void
}

export const deltaStore = create<DeltaState>((set) => ({
	delta: [],
	deltaData: {},
	pchDelta: [],
	pchDeltaData: {},
	isFetched: false,
	fetchDeltas: async () => {
		const delta = await fetchDeltas(false)
		set({ delta })
	},
	fetchPCHDeltas: async ({ pch }) => {
		const pchDelta = await fetchDeltas(pch)
		set({ pchDelta })
	},
	fetchDeltaData: async (carrierId) => {
		if (carrierId in deltaStore.getState().deltaData) return
		set({ isFetched: false })
		const deltaData = await fetchDeltaData(carrierId, false)
		set({
			deltaData: {
				...deltaStore.getState().deltaData,
				[carrierId]: deltaData
			},
			isFetched: true
		})
	},
	fetchPCHDeltaData: async (carrierId, pch) => {
		if (carrierId in deltaStore.getState().pchDeltaData) return
		set({ isFetched: false })
		const pchDeltaData = await fetchDeltaData(carrierId, pch)
		set({
			pchDeltaData: {
				...deltaStore.getState().pchDeltaData,
				[carrierId]: pchDeltaData
			},
			isFetched: true
		})
	},
	setNewDeltaData: (data) => {
		if (data.isPCH) {
			if (!deltaStore.getState().pchDeltaData[data.carrierId]) {
				return
			}
			delete data.isPCH
			set({
				pchDeltaData: {
					...deltaStore.getState().pchDeltaData,
					[data.carrierId]: [
						...deltaStore
							.getState()
							.pchDeltaData[data.carrierId].map(
								(item) => {
									return {
										delta: item.delta.map(
											(DD) => {
												if (
													DD.id ===
													data.deltaId
												) {
													DD.deltaData.unshift(
														data
													)
													return DD
												}
												return DD
											}
										)
									}
								}
							)
					]
				}
			})
		} else {
			if (!deltaStore.getState().deltaData[data.carrierId]) {
				return
			}
			delete data.isPCH
			set({
				deltaData: {
					...deltaStore.getState().deltaData,
					[data.carrierId]: [
						...deltaStore
							.getState()
							.deltaData[data.carrierId].map((item) => {
								return {
									delta: item.delta.map((DD) => {
										if (DD.id === data.deltaId) {
											DD.deltaData.unshift(data)
											return DD
										}
										return DD
									})
								}
							})
					]
				}
			})
		}
	}
}))
