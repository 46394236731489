import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { authStore } from '../store/auth'
import { notificationStore } from '../store/notification'

const Logout: FC = () => {
	const { t } = useTranslation()
	const { logout } = authStore()
	const { clearNotification } = notificationStore()

	useEffect(() => {
		const timer = setTimeout(() => {
			async function init() {
				logout()
				clearNotification()
			}
			init()
		}, 500)

		return () => {
			clearTimeout(timer)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<h1>
			<center>{t('Have a nice day!')}</center>
		</h1>
	)
}

export default Logout
