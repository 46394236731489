import { FC } from 'react'

export interface IconProps {
	selected: boolean
}

const ClimatIcon: FC<IconProps> = ({ selected }) => {
	return (
		<svg
			width="18"
			height="25"
			viewBox="0 0 18 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 13C2.68629 13 0 15.6863 0 19C0 22.3137 2.68629 25 6 25C9.31371 25 12 22.3137 12 19C12 15.6863 9.31371 13 6 13ZM6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19C8 17.8954 7.10457 17 6 17Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 3C3 1.34315 4.34315 0 6 0C7.65685 0 9 1.34315 9 3V5H7C6.44772 5 6 5.44772 6 6C6 6.55228 6.44772 7 7 7H9V9H7C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H9V15H3V3Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 6C16.6569 6 18 4.65685 18 3C18 1.34315 16.6569 0 15 0C13.3431 0 12 1.34315 12 3C12 4.65685 13.3431 6 15 6ZM15 4C15.5523 4 16 3.55228 16 3C16 2.44772 15.5523 2 15 2C14.4477 2 14 2.44772 14 3C14 3.55228 14.4477 4 15 4Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
		</svg>
	)
}

export default ClimatIcon
