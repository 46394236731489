import { CSSProperties, FC } from 'react'
import LightningIcon from './icons/LightningIcon'
import ConsumersIcon from './icons/ConsumersIcon'
import ClimatIcon, { IconProps } from './icons/ClimatIcon'
import MonitoringIcon from './icons/MonitoringIcon'
import { SettingsIcon } from './icons/SettingsIcon'
import { useTranslation } from 'react-i18next'
import { Flex } from 'antd'
import { Link } from 'react-router-dom'
import HomeIcon from './icons/HomeIcon'

interface CarrierOneTabsProps {
	hash: string
	disabled?: boolean
	sideBarShown: boolean
}

const CarrierOneTabs: FC<CarrierOneTabsProps> = ({
	hash,
	disabled,
	sideBarShown
}) => {
	const { t } = useTranslation()

	const sideBarTopStyles: CSSProperties = {
		width: '761px',
		borderRadius: '6px',
		height: '73px',
		minHeight: '73px',
		boxSizing: 'border-box',
		boxShadow: '0px 4px 8px 0px #E0E7F2',
		transition: 'all 1s ease-in-out',
		overflow: 'hidden',
		gap: '1px'
	}

	const navBarTopStyles: CSSProperties = {
		width: '100%',
		height: '70px',
		position: 'fixed',
		minHeight: '70px',
		left: '0px',
		bottom: '0px',
		boxSizing: 'border-box',
		boxShadow: '0px -4px 15px 0px #5B78AB2E',
		zIndex: 5,
		borderRadius: '10px 10px 0 0',
		overflow: 'hidden'
	}

	const data: {
		Icon: FC<IconProps>
		text: string
		link: string
		disabled?: boolean
	}[] = [
		{
			Icon: HomeIcon,
			text: t('Home'),
			link: ''
		},
		{
			Icon: LightningIcon,
			text: t('Lightning'),
			link: '#lightning'
		},
		{
			Icon: ConsumersIcon,
			text: t('Consumers'),
			link: '#consumers'
		},
		{
			Icon: ClimatIcon,
			text: t('Climat'),
			link: '#climat'
		},
		{
			Icon: MonitoringIcon,
			text: t('Monitoring'),
			link: '#monitoring'
		},
		{
			Icon: SettingsIcon,
			text: t('Settings'),
			link: '#settings',
			disabled
		}
	]
	return (
		<Flex
			style={sideBarShown ? sideBarTopStyles : navBarTopStyles}>
			{data
				.filter((d) =>
					sideBarShown ? d : d.link !== '#settings'
				)
				.map(({ Icon, link, text, disabled }, i) => (
					<Link
						to={disabled ? '#' : link}
						key={i}
						style={{
							flex: 1,
							flexBasis: 125,
							backgroundColor:
								hash === link ||
								(link !== '' &&
									hash.includes(link + '-'))
									? `var(--default)`
									: '#fff',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							textDecoration: 'none',
							gap: sideBarShown ? 12 : 5,
							cursor: disabled
								? 'not-allowed'
								: 'pointer',
							filter: disabled
								? 'grayscale(100%)'
								: 'none'
						}}>
						<Icon
							selected={
								hash === link ||
								(link !== '' &&
									hash.includes(link + '-'))
							}
						/>
						<p
							style={{
								color:
									hash === link ||
									(link !== '' &&
										hash.includes(link + '-'))
										? '#fff'
										: '#142D49',
								fontSize: 12
							}}>
							{sideBarShown ? text.toUpperCase() : text}
						</p>
					</Link>
				))}
		</Flex>
	)
}

export default CarrierOneTabs
