import { FC } from 'react'

const MegalithIcon: FC = () => {
	return (
		<div
			style={{ position: 'relative', width: 110, height: 100 }}>
			<svg
				width="130"
				height="135"
				viewBox="0 0 130 135"
				fill="none"
				transform="translate(-15, -20)"
				xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_dd_4090_15199)">
					<circle
						cx="65"
						cy="70"
						r="50"
						transform="rotate(90 65 70)"
						fill="url(#paint0_linear_4090_15199)"
					/>
				</g>
				<circle
					cx="65"
					cy="70"
					r="35"
					fill="url(#paint1_linear_4090_15199)"
				/>
				<g filter="url(#filter1_di_4090_15199)">
					<circle cx="65" cy="70" r="25" fill="#E7EDF8" />
				</g>
				<path
					d="M65.1392 66.2961C66.0488 66.2961 66.8827 66.5893 67.5649 67.0957C68.3735 66.3761 69.839 66.1362 71.1024 65.15C74.362 62.6179 71.0772 56.7275 62.8145 58.2468C56.7249 59.3662 59.378 65.0701 61.5005 68.3751C62.2586 67.1224 63.6231 66.2961 65.1392 66.2961Z"
					fill="#142D49"
				/>
				<path
					d="M60.7932 70.8537C60.7932 70.7471 60.7932 70.6138 60.8185 70.5072C59.7825 70.1607 58.8476 68.8813 57.382 68.215C53.6676 66.4825 50.4838 72.4529 55.8659 79.2229C59.833 84.2071 63.1684 78.983 64.8109 75.4114C62.562 75.2249 60.7932 73.2525 60.7932 70.8537Z"
					fill="#142D49"
				/>
				<path
					d="M69.0054 68.8289C69.3086 69.442 69.4602 70.135 69.4602 70.8546C69.4602 72.6937 68.4242 74.2929 66.9334 75.0126C67.1355 76.132 66.5796 77.5713 66.7565 79.2238C67.186 83.4617 73.68 83.4084 76.5605 75.1192C78.6578 69.0422 72.7703 68.5624 69.0054 68.8289Z"
					fill="#142D49"
				/>
				<path
					d="M68.0452 70.8555C68.0452 69.1497 66.7313 67.7637 65.1141 67.7637C63.497 67.7637 62.183 69.1497 62.183 70.8555C62.183 72.5613 63.497 73.9473 65.1141 73.9473C66.7313 73.9473 68.0452 72.5613 68.0452 70.8555Z"
					fill="#142D49"
				/>
				<defs>
					<filter
						id="filter0_dd_4090_15199"
						x="0"
						y="0"
						width="130"
						height="135"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="-5" />
						<feGaussianBlur stdDeviation="7.5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_4090_15199"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="5" />
						<feGaussianBlur stdDeviation="5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.823529 0 0 0 0 0.85098 0 0 0 0 0.905882 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_dropShadow_4090_15199"
							result="effect2_dropShadow_4090_15199"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect2_dropShadow_4090_15199"
							result="shape"
						/>
					</filter>
					<filter
						id="filter1_di_4090_15199"
						x="40"
						y="45"
						width="50"
						height="51"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="1" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_4090_15199"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect1_dropShadow_4090_15199"
							result="shape"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="1" />
						<feComposite
							in2="hardAlpha"
							operator="arithmetic"
							k2="-1"
							k3="1"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.791007 0 0 0 0 0.830116 0 0 0 0 0.908333 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="shape"
							result="effect2_innerShadow_4090_15199"
						/>
					</filter>
					<linearGradient
						id="paint0_linear_4090_15199"
						x1="115"
						y1="80.9524"
						x2="15"
						y2="80.9524"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#D0D7E6" />
						<stop offset="1" stopColor="white" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_4090_15199"
						x1="65"
						y1="105"
						x2="65"
						y2="35"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#95A5C4" />
						<stop offset="1" stopColor="#EFF2FF" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	)
}

export default MegalithIcon
