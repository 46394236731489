import axios from '../axios'
import { Canvas, CanvasData } from '../helpers/types/canvas'

export const fetchCanvas = async () => {
	const { data } = await axios.get('/api/v1/canvas')
	return data as Canvas[]
}

export const fetchCanvasData = async (carrierId: number) => {
	const { data } = await axios.get(
		`/api/v1/canvas/data?carrierId=${carrierId}`
	)
	return data as CanvasData[]
}
