import { $authHost } from '.'
import { NotificationResponse } from '../helpers/types/notification'

export const fetchNotifications: (
	limit: number,
	offset: number
) => Promise<NotificationResponse> = async (
	limit: number,
	offset: number
) => {
	const { data } = await $authHost.get('/api/v1/notification', {
		params: {
			limit,
			offset
		}
	})
	return data
}

export const fetchNotificationCount: () => Promise<number> =
	async () => {
		const { data } = await $authHost.get(
			'/api/v1/notification/count'
		)
		return data
	}

export const readNotification: (id: number) => Promise<void> = async (
	id: number
) => {
	await $authHost.post(`/api/v1/notification/read/${id}`)
}

export const readAllNotification: () => Promise<void> = async () => {
	await $authHost.post(`/api/v1/notification/read`)
}
