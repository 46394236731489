import axios from '../axios'
import { Balance, BalanceList } from '../helpers/types/balance'

export const fetchListBalance = async () => {
	const { data } = await axios.get('/api/v1/balance')
	return data as BalanceList[]
}

export const fetchBalance = async (carrierId: number) => {
	const { data } = await axios.get(`/api/v1/balance/${carrierId}`)
	return data as Balance[]
}
