import { CSSProperties, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RNCarriers } from '../helpers/routeNames'
import { carrierStore } from '../store/carrier'
// import CarriersSidebar from '../components/CarriersSidebar'
// import CarriersTab from '../components/CarriersTab'
import { CarrierOneSidebar } from '../components/CarrierOneSidebar'
import CarrierOneTabs from '../components/CarrierOneTabs'
import { Flex } from 'antd'
import { CarrierOneHome } from '../components/CarrierOneHome'
import { CarrierOneClimat } from '../components/CarrierOneClimat'
import { CarrierOneConsumers } from '../components/CarrierOneConsumers'
import { CarrierOneLightning } from '../components/CarrierOneLightning'
import { CarrierOneMonitoring } from '../components/CarrierOneMonitoring'
import { CarrierOneSettings } from '../components/CarrierOneSettings'
import CarrierOneLogging from '../components/CarrierOneLogging'
import { loggingStore } from '../store/logging'
import SensorDataChart from '../components/SensorDataChart'
import { canvasStore } from '../store/canvas'
import { carrierPermissionStore } from '../store/carrierPermission'
import { authStore } from '../store/auth'
import { sensorStore } from '../store/sensor'
import { IElementProps } from '../routes'
import { HeaderSwitchCarrierNotification } from '../components/mobile-components/HeaderSwitchCarrierNotification'
import { utilityStore } from '../store/utils'
import CarrierSelect from './CarrierSelect'

const CarrierOne: FC<IElementProps> = ({ sideBarShown }) => {
	const { t } = useTranslation()
	const { id } = useParams<{ id: string }>()
	const navigate = useNavigate()
	const location = useLocation()

	const { user } = authStore()
	const { carriers } = carrierStore()
	const { fetchLoggings } = loggingStore()
	const { fetchCanvasData } = canvasStore()
	const { fetchSensors } = sensorStore()
	const { carrierPermission } = carrierPermissionStore()
	const { headerSwitchCarrierNotificationShown } = utilityStore()

	const carrier = carriers.find((el) => el.id === +id!)

	const isCarrierPermission =
		user &&
		id &&
		!!carrierPermission[user.id]?.find(
			(el) => el.type === 'settings' && el.carrierId === +id
		)

	const carriersStyle: CSSProperties = {
		height: `calc(100vh - ${location.search.includes('?select') ? 0 : 70}px)`,
		boxSizing: 'border-box'
	}

	const carriersItemsStyle: CSSProperties = {
		height: `calc(100% - ${sideBarShown ? 54 : 32}px)`,
		display: 'flex',
		width: '100%',
		gap: '16px'
	}

	useEffect(() => {
		if (!id || isNaN(Number(id))) return
		// const fetchData = async () => {
		// 	await Promise.all([
		fetchSensors(+id)
		fetchLoggings(+id!)
		fetchCanvasData(+id)
		// 	])
		// }
		// fetchData()
	}, [id, fetchLoggings, fetchCanvasData, fetchSensors])

	if (!id || isNaN(Number(id))) {
		navigate(RNCarriers)
		return <center>{t('Redirecting')}...</center>
	}

	return (
		<div style={carriersStyle}>
			{sideBarShown ? (
				<header className="page-header">
					<h1 className="page-h1">
						{t('ViewCarrier')} № {carrier?.factoryNumber}
					</h1>
				</header>
			) : (
				<HeaderSwitchCarrierNotification
					carrier={carrier}
					hidden={!headerSwitchCarrierNotificationShown}
					isCarrierPermission={isCarrierPermission}
				/>
			)}
			{location.search.includes('?select') ? (
				<CarrierSelect carrierId={+id} />
			) : (
				<main style={carriersItemsStyle}>
					{/* <CarriersSidebar id={+id!} /> */}
					<Flex
						style={{ width: '100%', overflowY: 'auto' }}
						gap={16}
						vertical>
						{/* <CarriersTab search={location.search} /> */}
						{location.search === '' ? ( //'?indicate' ? (
							<Flex
								gap={16}
								style={{
									overflowY: 'auto',
									width: sideBarShown
										? '1001px'
										: '100%',
									height: '100%',
									position: 'relative'
								}}
								className="carrierTabForScrollbar">
								{((!sideBarShown &&
									location.hash === '#start') ||
									sideBarShown) && (
									<CarrierOneSidebar
										carrierId={+id}
										sideBarShown={sideBarShown}
									/>
								)}
								<Flex
									vertical
									gap={16}
									style={{
										width:
											!sideBarShown &&
											location.hash === '#start'
												? 0
												: '100%',
										position:
											!sideBarShown &&
											location.hash === '#start'
												? 'absolute'
												: 'unset'
									}}>
									<CarrierOneTabs
										hash={location.hash}
										disabled={
											!isCarrierPermission
										}
										sideBarShown={sideBarShown}
									/>
									{location.hash === '' ? (
										<>
											<CarrierOneHome
												carrierId={+id}
											/>
											<CarrierOneLogging
												id={+id}
											/>
										</>
									) : location.hash ===
									  '#lightning' ? (
										<CarrierOneLightning
											carrierId={+id}
											sideBarShown={
												sideBarShown
											}
										/>
									) : location.hash ===
									  '#consumers' ? (
										<CarrierOneConsumers
											carrierId={+id}
											sideBarShown={
												sideBarShown
											}
										/>
									) : location.hash ===
									  '#climat' ? (
										<CarrierOneClimat
											carrierId={+id}
											sideBarShown={
												sideBarShown
											}
										/>
									) : location.hash.includes(
											'#monitoring'
									  ) ? (
										<CarrierOneMonitoring
											id={+id}
										/>
									) : location.hash.includes(
											'#settings'
									  ) && isCarrierPermission ? (
										<CarrierOneSettings
											carrierId={+id}
										/>
									) : (
										''
									)}
								</Flex>
							</Flex>
						) : location.search === '?desc' ? (
							<h1 style={{ margin: 0 }}>
								{t('Coming Soon!')}
							</h1>
						) : location.search === '?diagram' ? (
							<SensorDataChart />
						) : location.search === '?logging' ? (
							<CarrierOneLogging id={+id} />
						) : (
							<></>
						)}
					</Flex>
				</main>
			)}
		</div>
	)
}

export default CarrierOne
