import { Flex, Input, Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ChangePassword } from '../components/ChangePassword'
import { UploadProfileImage } from '../components/UploadProfileImage'
import { authStore } from '../store/auth'
import { LoginButton } from '@telegram-auth/react'

const Profile: FC = () => {
	const { t } = useTranslation()
	const { user, tgWidget } = authStore()

	// useEffect(() => {
	// 	const script = document.createElement('script')
	// 	script.src = 'https://telegram.org/js/telegram-widget.js?22'
	// 	script.async = true
	// 	script.setAttribute('data-telegram-login', 'zarafshon_robot')
	// 	script.setAttribute('data-size', 'large')
	// 	script.setAttribute(
	// 		'data-auth-url',
	// 		'https://us.temiryol.uz/auth/telegram/callback/'
	// 	)
	// 	script.setAttribute('data-request-access', 'write')
	// 	document.getElementById('telegram-login')?.appendChild(script)
	// }, [])

	return (
		<Flex
			justify="space-between"
			vertical
			style={{
				height: 'calc(100% - 70px)',
				boxSizing: 'border-box'
			}}>
			<Flex gap="middle">
				<Flex vertical gap="small" justify="space-between">
					<p>{t('ProfileImage')}</p>
					<UploadProfileImage
						src="/personpro.png"
						name="User"
					/>
				</Flex>
				<Flex vertical component={Form}>
					<div style={{ marginBottom: '10px' }}>
						<p>{t('fullname')}</p>
						<Input size="large" value={user?.name} />
					</div>
					{/* <div style={{ marginBottom: '10px' }}>
						<p>{t('position')}</p>
						<Input size="large" />
					</div>
					<div style={{ marginBottom: '10px' }}>
						<p>{t('role')}</p>
						<Input size="large" />
					</div> */}
				</Flex>
				<Flex>
					<ChangePassword />
				</Flex>
			</Flex>
			{/* <Flex
				justify="end"
				align="center"
				className="last"
				gap="16px">
				<Button
					size="large"
					style={{
						backgroundColor: '#8091B1',
						color: '#fff',
						borderColor: '#8091B1',
						width: '160px',
						height: '45px'
					}}>
					{t('Cancel')}
				</Button>
				<Button
					type="primary"
					color="secondary"
					style={{
						backgroundColor: '#4AC382',
						color: '#fff',
						borderColor: '#4AC382',
						width: '160px',
						height: '45px'
					}}
					htmlType="submit"
					size="large">
					{t('Save')}
				</Button>
			</Flex> */}
			{/* <div id="telegram-login"></div> */}
			<LoginButton
				botUsername={'zarafshon_robot'}
				// authCallbackUrl={`${process.env.REACT_APP_API_URL}/api/v1/auth/tg-widget/`}
				buttonSize="large" // "large" | "medium" | "small"
				showAvatar={true}
				onAuthCallback={tgWidget}
				requestAccess="write"
				lang="ru"
			/>
		</Flex>
	)
}

export default Profile
