import { Flex, Skeleton, Switch /*, Tooltip */ } from 'antd'
import { CSSProperties, FC } from 'react'
import { sensorStore } from '../../store/sensor'
import { utilityStore } from '../../store/utils'

interface SensorCHDProps {
	name: string
	code?: string
	value: boolean
	color?: 'red' | 'yellow' | 'green'
}

export const SensorCHD: FC<SensorCHDProps> = ({
	name,
	value,
	color
	// code
}) => {
	const { sideBarShown } = utilityStore()
	const { isFetched: isSensorsFetched } = sensorStore()
	const chdBgStyles = {
		backgroundColor: '#EAECF3',
		width: sideBarShown ? 125 : '100%',
		minWidth: sideBarShown ? 125 : 112,
		flex: sideBarShown ? 0 : 1,
		height: sideBarShown ? 103 : 123,
		border: '1px solid #EAECF3'
	}

	const chdNameStyles: CSSProperties = {
		fontSize: (sideBarShown ? 14 : 17) + 'px',
		color: '#142D49',
		backgroundColor: '#fff',
		padding: '0 10px',
		height: `${sideBarShown ? 60 : 70}px`,
		textAlign: 'center'
	}

	const colors = {
		red: '#F05742',
		yellow: '#EAB11E',
		green: '#74C24F',
		gray: '#bcc6d8'
	}

	if (!isSensorsFetched)
		return (
			<Skeleton.Input
				active
				size="large"
				style={{
					width: '127px',
					minWidth: '127px',
					height: '103px',
					border: '1px solid #EAECF3'
				}}
			/>
		)

	return (
		// <Tooltip title={code || name}>
		<Flex style={chdBgStyles} vertical gap={1}>
			<Flex
				justify="center"
				align="center"
				style={chdNameStyles}>
				{name}
			</Flex>

			<Flex
				style={{
					backgroundColor: '#F5F7FB',
					width: sideBarShown ? 124 : 'auto',
					minWidth: sideBarShown ? 124 : 112,
					textAlign: 'center',
					padding: sideBarShown ? '11px 0' : '10px 0'
				}}
				justify="center"
				align="center"
				gap={5}>
				<Switch
					checked={value}
					style={{
						backgroundColor: colors[color!],
						...(!sideBarShown && {
							width: 58,
							height: 32
						})
					}}
				/>
			</Flex>
			<style>
				{`
					.ant-switch {
						border-radius: ${sideBarShown ? 4 : 6}px !important;
						
						.ant-switch-handle {
					    	width: ${sideBarShown ? 18 : 26}px;
							height: ${sideBarShown ? 18 : 26}px;
							top: ${sideBarShown ? 2 : 3}px;
							inset-inline-start: ${sideBarShown ? 2 : 3}px;
						
							&::before {
								border-radius: ${sideBarShown ? 2 : 4}px !important;
							}
						}
						
						&.ant-switch-checked {
							.ant-switch-handle {
								inset-inline-start: calc(100% - ${sideBarShown ? 20 : 29}px);
							}
						}
					}
				`}
			</style>
		</Flex>
		// </Tooltip>
	)
}
