import { Form, Input } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { Logo } from '../components'
import { authStore } from '../store/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { RNWelcome } from '../helpers/routeNames'
import { userStore } from '../store/user'
import { AxiosError } from 'axios'
import { carrierStore } from '../store/carrier'
import { utilityStore } from '../store/utils'

const Login: FC = () => {
	const { t } = useTranslation()
	const { login, isTelegramUser } = authStore()
	const { fetchUsers } = userStore()
	const { fetchCarriers } = carrierStore()
	const { sideBarShown } = utilityStore()

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (sideBarShown) return
		if (
			(window as any)?.Telegram?.WebApp?.initData &&
			isTelegramUser
		) {
			const tg = (window as any).Telegram.WebApp

			tg.setHeaderColor('#2D4056')
			tg.setBackgroundColor('#2D4056')
			document.documentElement.style.setProperty(
				'--bg-color-zarafshon',
				'#2D4056'
			)

			return () => {
				tg.setHeaderColor('#EBEFF6')
				tg.setBackgroundColor('#EBEFF6')
				document.documentElement.style.setProperty(
					'--bg-color-zarafshon',
					'#EBEFF6'
				)
			}
		}
		document.documentElement.style.setProperty(
			'--bg-color-zarafshon',
			'#2D4056'
		)
		return () => {
			document.documentElement.style.setProperty(
				'--bg-color-zarafshon',
				'#EBEFF6'
			)
		}
	}, [sideBarShown, isTelegramUser])

	const loginFirstDivStyle: React.CSSProperties = {
		width: '100%',
		height: '100vh',
		display: 'flex',
		flexDirection: sideBarShown ? 'row' : 'column',
		boxSizing: 'border-box',
		justifyContent: 'center',
		backgroundColor: sideBarShown ? '' : '#2D4056'
	}

	const loginSecondDivStyle: React.CSSProperties = {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#2D4056',
		flex: sideBarShown ? 1 : 0,
		padding: 16,
		boxSizing: 'border-box'
	}
	const loginHeaderStyle: React.CSSProperties = {
		color: sideBarShown ? '#142D49' : '#fff',
		fontSize: '30px',
		marginBottom: sideBarShown ? '20px' : 0,
		marginTop: 0,
		fontFamily: "'MuseoModerno', 'SFProDisplay', sans-serif",
		fontWeight: 400,
		textAlign: 'center'
	}

	const loginButtonStyle: React.CSSProperties = {
		width: '350px',
		height: '44px',
		backgroundColor: '#4AC382',
		color: '#FFF',
		fontSize: '15px',
		borderRadius: '4px',
		border: 'none'
	}

	const loginInputStyle: React.CSSProperties = {
		width: '350px',
		height: '44px',
		borderRadius: '4px',
		marginBottom: sideBarShown ? 20 : 12,
		fontSize: '20px'
	}

	// const loginLogoStyle: React.CSSProperties = {
	// 	width: '67px',
	// 	height: '78px'
	// }

	const loginFirmnameStyle: React.CSSProperties = {
		fontFamily: 'MuseoModerno',
		color: '#fff',
		fontSize: '38px',
		margin: '16px 0 0',
		lineHeight: '38px',
		textAlign: 'center'
	}

	const loginFirmDescStyle: React.CSSProperties = {
		fontFamily: 'SFProDisplay',
		fontWeight: 400,
		fontSize: '23px',
		margin: 0,
		color: '#8797AA',
		letterSpacing: '.4px',
		lineHeight: '23px',
		marginTop: 10
	}

	const loginCountryStyle: React.CSSProperties = {
		fontFamily: 'MuseoModerno',
		fontSize: '24px',
		margin: '40px 0 0 0',
		color: '#fff',
		lineHeight: '40px',
		textAlign: 'center'
	}

	const onFinish = async (values: any) => {
		try {
			await login(values.email, values.password)
			await fetchUsers()
			await fetchCarriers()
			// const carrierId = carriers.map((carrier) => carrier.id)
			// await fetchSensors(2)
			navigate(
				decodeURIComponent(
					location.search.replace('?path=', '')
				) || RNWelcome
			)
		} catch (err: any) {
			if (err instanceof AxiosError) {
				alert(err.response?.data.message)
			} else {
				console.log(err)
			}
		}
	}

	return (
		<div style={loginFirstDivStyle}>
			<Form
				style={{
					...loginSecondDivStyle,
					backgroundColor: sideBarShown
						? '#F9FBFF'
						: '#2D4056',
					paddingTop: 0
				}}
				onFinish={onFinish}
				// initialValues={{
				// 	email: 'test@zarafshon.org',
				// 	password: '123456'
				// }}
				layout="vertical">
				<h1 style={loginHeaderStyle}>{t('Login')}</h1>
				<Form.Item
					name="email"
					label={
						<div
							style={{
								color: sideBarShown ? '#000' : '#fff'
							}}>
							E-mail
						</div>
					}
					style={{ marginBottom: 0 }}>
					<Input
						type="email"
						style={loginInputStyle}
						autoFocus
					/>
				</Form.Item>
				<Form.Item
					name="password"
					label={
						<div
							style={{
								color: sideBarShown ? '#000' : '#fff'
							}}>
							Пароль
						</div>
					}
					style={{ marginBottom: 0, color: '#fff' }}>
					<Input
						type="password"
						style={{
							...loginInputStyle,
							fontSize: '36px'
						}}
					/>
				</Form.Item>
				<Form.Item
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'flex-end',
						marginBottom: 0
					}}>
					<button style={loginButtonStyle}>
						{t('Login')}
					</button>
				</Form.Item>
			</Form>
			<div
				style={{
					...loginSecondDivStyle,
					order: sideBarShown ? 0 : -1,
					justifyContent: !sideBarShown
						? 'flex-end'
						: 'center'
				}}>
				{/* <Logo style={loginLogoStyle} /> */}

				<img
					src="/logo.png"
					alt="Logo"
					width={sideBarShown ? 150 : 67}
				/>
				<h2 style={loginFirmnameStyle}>
					O'zbekiston Temir yo'llari
					{/* <span style={{ color: '#ACDC54' }}>on</span> */}
				</h2>
				<h4 style={loginFirmDescStyle}>Online monitoring</h4>
				{sideBarShown && (
					<h3 style={loginCountryStyle}>Uzbekistan</h3>
				)}
			</div>
		</div>
	)
}

export default Login
