import axios from '../axios'
import { Logging } from '../helpers/types/logging'

export const fetchLoggings = async (carrierId: number) => {
	const { data } = await axios.get('/api/v1/logging', {
		headers: {
			'x-carrier-id': carrierId.toString()
		}
	})
	return data as Logging[]
}
