import { FC } from 'react'

interface Props {
	canvasData: number[]
}

export const CarrierOneConditioningCanvas: FC<Props> = ({
	canvasData
}) => {
	const dataMapFill = (data: number) => {
		switch (data) {
			case 0:
				return '#C9D4E9'
			case 1:
				return '#A0E768'
			default:
				return '#C9D4E9'
		}
	}

	const dataMapStroke = (data: number) => {
		switch (data) {
			case 0:
				return '#9CB4DB'
			case 1:
				return '#7AB050'
			default:
				return '#9CB4DB'
		}
	}

	return (
		<svg
			width="746"
			height="130"
			viewBox="0 0 746 130"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="746" height="130" rx="6" fill="#F2F4F8" />
			<path d="M0 45H673" stroke="#C1CCDE" strokeWidth="2" />
			<path
				d="M143 105H225"
				stroke="#C1CCDE"
				strokeWidth="2"
			/>
			<path
				d="M143 106L143 45"
				stroke="#C1CCDE"
				strokeWidth="2"
			/>
			<path
				d="M225 106L225 45"
				stroke="#C1CCDE"
				strokeWidth="2"
			/>
			<text
				x="26"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				УПРАВЛЕНИЕ
			</text>
			<text
				x="140"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				КНП. ВКЛ. ПР. ВЕНТ
			</text>
			<text
				x="260"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				КОНТАКТОР ПР. ВЕРТ
			</text>
			<text
				x="390"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				ТЕРМОДАТЧИК ПР.
			</text>
			<text
				x="520"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				АВАРИЯ 1 КАНАЛ
			</text>
			<text
				x="640"
				y="22"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				ПРИТОЧНЫЙ ВЕНТ.
			</text>
			<text
				x="152"
				y="83"
				fill="#142D49"
				fontSize={10}
				fontWeight={500}>
				ВЕНТИЛЯТОР
			</text>
			<rect
				x="43.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[0])}
				stroke={dataMapStroke(canvasData[0])}
			/>
			<rect
				x="169.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[1])}
				stroke={dataMapStroke(canvasData[1])}
			/>
			<rect
				x="169.5"
				y="90.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[2])}
				stroke={dataMapStroke(canvasData[2])}
			/>
			<rect
				x="295.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[3])}
				stroke={dataMapStroke(canvasData[3])}
			/>
			<rect
				x="421.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[4])}
				stroke={dataMapStroke(canvasData[4])}
			/>
			<rect
				x="547.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				fill={dataMapFill(canvasData[5])}
				stroke={dataMapStroke(canvasData[5])}
			/>
			<rect
				x="673.5"
				y="30.5"
				width="29"
				height="29"
				rx="3.5"
				stroke="#9CB4DB"
			/>
			<rect
				x="677.5"
				y="34.5"
				width="21"
				height="21"
				rx="3.5"
				fill={dataMapFill(canvasData[6])}
				stroke={dataMapStroke(canvasData[6])}
			/>
		</svg>
	)
}
