import { Flex } from 'antd'
import { CSSProperties, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DeltaComponent } from './DeltaComponent'
import { SensorBGC } from './sensors'
import { SBV } from './sensors/SBV'
import { CHV } from './sensors/CHV'
import { CHM } from './sensors/CHM'
import { sensorStore } from '../store/sensor'

interface Props {
	carrierId: number
}

export const CarrierOneSettingsPCHComp: FC<Props> = ({
	carrierId
}) => {
	const { t } = useTranslation()
	const { sensors } = sensorStore()

	const finderColor = (code: string) => {
		return (
			sensors
				.find((s) => s.code === code)
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.color || 'gray'
		)
	}

	const finderFC = (code: string, isOne?: boolean) => {
		return Number(
			sensors
				.find((s) => s.code === code)
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.value
		) || isOne
			? 1
			: 0
	}

	const CarrierOneSettingsInnerBlockStyle: CSSProperties = {
		backgroundColor: '#fff',
		padding: 10,
		borderRadius: '6px'
	}
	return (
		<Flex gap={10} vertical>
			<Flex gap={10}>
				<Flex gap={10} vertical>
					{/*  style={{ width: '100%' }}> */}
					<Flex
						gap={10}
						style={CarrierOneSettingsInnerBlockStyle}
						vertical>
						<h3 style={{ margin: 0 }}>{t('Status')}</h3>
						<Flex gap={10}>
							<SensorBGC
								alignStart={true}
								color={finderColor('sts_stopDC-DC')}
								text="StopDC-DC"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_not_enough_voltage'
								)}
								text="NotEnoughVoltage"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_pch_drawer_open'
								)}
								text="PCHDrawerOpen"
							/>
						</Flex>
						<Flex gap={10}>
							<SensorBGC
								alignStart={true}
								color={finderColor('sts_stop_supply')}
								text="StopSupply"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor('sts_redirecting')}
								text="Redirecting"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor('sts_DC-DCFault')}
								text="DC-DCFault"
							/>
						</Flex>
						<Flex gap={10}>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_stop_condensator'
								)}
								text="StopCondensator"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_high_current'
								)}
								text="HighCurrent"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor('sts_overheat75')}
								text="Overheat75"
							/>
						</Flex>
						<Flex gap={10}>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_stop_compressor'
								)}
								text="StopCompressor"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_protection_temp'
								)}
								text="ProtectionTemp"
							/>
							<SensorBGC
								alignStart={true}
								color={finderColor(
									'sts_analog_module'
								)}
								text="AnalogModule"
							/>
						</Flex>
					</Flex>
					<Flex
						gap={10}
						style={CarrierOneSettingsInnerBlockStyle}
						vertical>
						<h3 style={{ margin: 0 }}>
							{t('Monitoring')} DC-DC 450
						</h3>
						<Flex gap={10}>
							<Flex gap={10} vertical>
								<SBV
									name="inputVoltage"
									value={finderFC('input_voltage')}
									measurement="Volt"
								/>
								<SBV
									name="DC-DCVoltage"
									value={finderFC('dc_dcvoltage')}
									measurement="Volt"
								/>
							</Flex>
							<div
								style={{
									height: '100%',
									backgroundColor: '#DDE2EB',
									width: 1
								}}></div>
							<Flex gap={10} vertical>
								<SBV
									name="InputAmper"
									value={finderFC('input_amper')}
									measurement="Amp"
								/>
								<SBV
									name="DrawerTemp"
									value={finderFC('drawer_temp')}
									measurement="C&deg;"
								/>
							</Flex>
						</Flex>
					</Flex>
					<Flex
						gap={10}
						style={CarrierOneSettingsInnerBlockStyle}
						vertical>
						<h3 style={{ margin: 0 }}>{t('DELIXI')}</h3>
						<Flex gap={10}>
							<Flex
								gap={10}
								vertical
								style={{ flex: 1 }}>
								<Flex gap={10} align="center">
									<h3
										style={{
											margin: 0,
											flex: 1
										}}>
										CH1
									</h3>
									<b style={{ color: '#74C24F' }}>
										{sensors
											.find(
												(s) =>
													s.code ===
													'ch1_status'
											)
											?.sensorData.find(
												(s) =>
													s.carrierId ===
													carrierId
											)?.value === '0'
											? t('NoAccidents')
											: !sensors
														.find(
															(s) =>
																s.code ===
																'ch1_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value
												? ''
												: 'ERR' +
													sensors
														.find(
															(s) =>
																s.code ===
																'ch1_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value}
									</b>
								</Flex>
								<SBV
									name="Chastota"
									value={finderFC('ch1_chastota')}
									isPCH={true}
									measurement="Hz"
								/>
								<SBV
									name="Voltage"
									value={finderFC('ch1_voltage')}
									isPCH={true}
									measurement="Volt"
								/>
								<SBV
									name="Current"
									value={finderFC('ch1_current')}
									isPCH={true}
									measurement="Amp"
								/>
							</Flex>
							<div
								style={{
									height: '100%',
									backgroundColor: '#DDE2EB',
									width: 1
								}}></div>
							<Flex
								gap={10}
								vertical
								style={{ flex: 1 }}>
								<Flex gap={10} align="center">
									<h3
										style={{
											margin: 0,
											flex: 1
										}}>
										CH2
									</h3>
									<b style={{ color: '#74C24F' }}>
										{sensors
											.find(
												(s) =>
													s.code ===
													'ch2_status'
											)
											?.sensorData.find(
												(s) =>
													s.carrierId ===
													carrierId
											)?.value === '0'
											? t('NoAccidents')
											: !sensors
														.find(
															(s) =>
																s.code ===
																'ch2_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value
												? ''
												: 'ERR' +
													sensors
														.find(
															(s) =>
																s.code ===
																'ch2_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value}
									</b>
								</Flex>
								<SBV
									name="Chastota"
									value={finderFC('ch2_chastota')}
									isPCH={true}
									measurement="Hz"
								/>
								<SBV
									name="Voltage"
									value={finderFC('ch2_voltage')}
									isPCH={true}
									measurement="Volt"
								/>
								<SBV
									name="Current"
									value={finderFC('ch2_current')}
									isPCH={true}
									measurement="Amp"
								/>
							</Flex>
							<div
								style={{
									height: '100%',
									backgroundColor: '#DDE2EB',
									width: 1
								}}></div>
							<Flex
								gap={10}
								vertical
								style={{ flex: 1 }}>
								<Flex gap={10} align="center">
									<h3
										style={{
											margin: 0,
											flex: 1
										}}>
										CH3
									</h3>
									<b style={{ color: '#74C24F' }}>
										{sensors
											.find(
												(s) =>
													s.code ===
													'ch3_status'
											)
											?.sensorData.find(
												(s) =>
													s.carrierId ===
													carrierId
											)?.value === '0'
											? t('NoAccidents')
											: !sensors
														.find(
															(s) =>
																s.code ===
																'ch3_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value
												? ''
												: 'ERR' +
													sensors
														.find(
															(s) =>
																s.code ===
																'ch3_status'
														)
														?.sensorData.find(
															(s) =>
																s.carrierId ===
																carrierId
														)?.value}
									</b>
								</Flex>
								<SBV
									name="Chastota"
									value={finderFC('ch3_chastota')}
									isPCH={true}
									measurement="Hz"
								/>
								<SBV
									name="Voltage"
									value={finderFC('ch3_voltage')}
									isPCH={true}
									measurement="Volt"
								/>
								<SBV
									name="Current"
									value={finderFC('ch3_current')}
									isPCH={true}
									measurement="Amp"
								/>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					gap={10}
					style={{
						...CarrierOneSettingsInnerBlockStyle,
						// width: 241,
						overflow: 'auto'
					}}>
					<DeltaComponent
						carrierId={+carrierId}
						isPCH={true}
					/>
				</Flex>
			</Flex>
			<Flex
				gap={10}
				style={CarrierOneSettingsInnerBlockStyle}
				vertical>
				<h3 style={{ margin: 0 }}>DC-DC 450</h3>
				<Flex gap={10}>
					<CHM
						multiplier={finderFC('max_voltage_1', true)}
						name={'MaximalVoltage'}
						value={finderFC('max_voltage_2')}
						measurement="V"
					/>
					<CHV
						text="VoltageAdjustment110"
						value={finderFC('adjust_volt_110_1')}
						value2={finderFC('adjust_volt_110_2')}
					/>
					<CHV
						text="VoltageAdjustment450"
						value={finderFC('adjust_volt_450_1')}
						value2={finderFC('adjust_volt_450_2')}
					/>
				</Flex>
				<Flex gap={10}>
					<CHM
						multiplier={finderFC('max_current_1', true)}
						name={'MaximalCurrent'}
						value={finderFC('max_current_2')}
						measurement="Amp"
					/>
					<CHV
						text="CurrentSensorAdjustment"
						value={finderFC('cur_sen_adj_1')}
						value2={finderFC('cur_sen_adj_2')}
					/>
					<CHV
						text="TempSensorAdjustment"
						value={finderFC('temp_sen_adj_1')}
						value2={finderFC('temp_sen_adj_2')}
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
