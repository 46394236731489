import { create } from 'zustand'
import { Canvas, CanvasData } from '../helpers/types/canvas'
import { fetchCanvas, fetchCanvasData } from '../api/canvas'
import { Carrier } from '../helpers/types/carrier'

export interface CanvasState {
	canvas: Canvas[]
	canvasData: Record<Carrier['id'], CanvasData[]>
	fetchCanvas: () => Promise<void>
	fetchCanvasData: (carrierId: number) => Promise<void>
	setNewCanvasData: (data: CanvasData['canvasData'][0]) => void
	isFetched: boolean
}

export const canvasStore = create<CanvasState>((set) => ({
	canvas: [],
	canvasData: {},
	isFetched: false,
	fetchCanvas: async () => {
		const data = await fetchCanvas()
		set(() => ({ canvass: data, isFetched: true }))
	},
	fetchCanvasData: async (carrierId) => {
		if (carrierId in canvasStore.getState().canvasData) return
		const data = await fetchCanvasData(carrierId)
		set((state) => ({
			canvasData: {
				...state.canvasData,
				[carrierId]: data
			}
		}))
	},
	setNewCanvasData: (data) => {
		set((canvas) => ({
			canvasData: {
				...canvas.canvasData,
				[data.carrierId]: canvas.canvasData[
					data.carrierId
				].map((canvasData) => {
					if (canvasData.id === data.canvasId) {
						return {
							...canvasData,
							canvasData: canvasData.canvasData.map(
								(CD) => {
									if (
										CD.canvasId === data.canvasId
									) {
										return {
											...CD,
											value: data.value
										}
									}
									return CD
								}
							)
						}
					}
					return canvasData
				})
			}
		}))
	}
}))
