import { create } from 'zustand'
import { Carrier } from '../helpers/types/carrier'
import {
	addCarrier,
	deleteCarrier,
	fetchCarriers,
	restoreCarrier,
	updateCarrier
} from '../api/carrier'

export interface CarrierState {
	carriers: Carrier[]
	isFetched: boolean
	fetchCarriers: () => Promise<void>
	addCarrier: (carrier: Carrier) => Promise<void>
	updateCarrier: (carrier: Carrier) => Promise<void>
	deleteCarrier: (id: number) => Promise<void>
	restoreCarrier: (id: number) => Promise<void>
}

export const carrierStore = create<CarrierState>((set) => ({
	carriers: [],
	isFetched: false,
	fetchCarriers: async () => {
		const data = await fetchCarriers()
		set(() => ({ carriers: data, isFetched: true }))
	},
	addCarrier: async (carrier: Carrier) => {
		const data = await addCarrier({
			...carrier,
			carrierGroupId: carrier.carrierGroupId || 1
		})
		set((carriers) => ({
			carriers: [...carriers.carriers, data]
		}))
	},
	updateCarrier: async (carrier: Carrier) => {
		await updateCarrier(carrier)
		set((carriers) => ({
			carriers: carriers.carriers.map((u) =>
				u.id === carrier.id ? carrier : u
			)
		}))
	},
	deleteCarrier: async (id: number) => {
		await deleteCarrier(id)
		set((carriers) => ({
			carriers: carriers.carriers.map((u) =>
				u.id === id ? { ...u, isDeleted: true } : u
			)
		}))
	},
	restoreCarrier: async (id: number) => {
		await restoreCarrier(id)
		set((carriers) => ({
			carriers: carriers.carriers.map((u) =>
				u.id === id ? { ...u, isDeleted: false } : u
			)
		}))
	}
}))
