import { FC } from 'react'

interface LoggingIconProps {
	isActive?: boolean
}

export const LoggingIcon: FC<LoggingIconProps> = ({
	isActive = false
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 22.3333C5 22.5764 5.09578 22.8096 5.26627 22.9815C5.43675 23.1534 5.66799 23.25 5.90909 23.25H11.3636C11.966 23.253 12.5504 23.4569 13.0256 23.8301C13.5009 24.2032 13.8403 24.7244 13.9909 25.3125C14.0414 25.5095 14.1554 25.684 14.3151 25.8085C14.4747 25.933 14.6709 26.0004 14.8727 26H15.1273C15.3291 26.0004 15.5253 25.933 15.6849 25.8085C15.8446 25.684 15.9586 25.5095 16.0091 25.3125C16.1597 24.7244 16.4991 24.2032 16.9744 23.8301C17.4496 23.4569 18.034 23.253 18.6364 23.25H24.0909C24.332 23.25 24.5632 23.1534 24.7337 22.9815C24.9042 22.8096 25 22.5764 25 22.3333V8.58333C25 8.34022 24.9042 8.10706 24.7337 7.93515C24.5632 7.76324 24.332 7.66667 24.0909 7.66667H18.6364C17.6523 7.66332 16.6943 7.98532 15.9091 8.58333C15.9091 7.36776 15.4302 6.20197 14.5778 5.34243C13.7253 4.48289 12.5692 4 11.3636 4H10.4545C10.2134 4 9.98221 4.09658 9.81172 4.26849C9.64123 4.44039 9.54545 4.67355 9.54545 4.91667V7.66667H5.90909C5.66799 7.66667 5.43675 7.76324 5.26627 7.93515C5.09578 8.10706 5 8.34022 5 8.58333V22.3333ZM18.6364 9.5H23.1818V21.4167H18.6364C17.6524 21.414 16.6946 21.7359 15.9091 22.3333V12.25C15.9091 11.5207 16.1964 10.8212 16.7079 10.3055C17.2194 9.78973 17.913 9.5 18.6364 9.5ZM11.3636 5.83333C12.087 5.83333 12.7806 6.12306 13.2921 6.63879C13.8036 7.15451 14.0909 7.85399 14.0909 8.58333V18.6667C13.3057 18.0686 12.3477 17.7467 11.3636 17.75V5.83333ZM6.81818 9.5H9.54545V18.6667C9.54545 18.9098 9.64123 19.1429 9.81172 19.3148C9.98221 19.4868 10.2134 19.5833 10.4545 19.5833H11.3636C12.087 19.5833 12.7806 19.8731 13.2921 20.3888C13.8036 20.9045 14.0909 21.604 14.0909 22.3333C13.3054 21.7359 12.3476 21.414 11.3636 21.4167H6.81818V9.5Z"
				fill={isActive ? '#fff' : '#61748A'}
			/>
		</svg>
	)
}
