import axios from '../axios'
import { Sensor } from '../helpers/types/sensor'

export const fetchSensors = async (carrierId: number) => {
	const { data } = await axios.get('/api/v1/sensor', {
		headers: {
			'x-carrier-id': carrierId
		}
	})
	return data as Sensor[]
}

export const addSensor = async (body: Partial<Sensor>) => {
	const { data } = await axios.post('/api/v1/sensor', body)
	return data
}

export const updateSensor = async (body: Partial<Sensor>) => {
	const { data } = await axios.put(
		`/api/v1/sensor/${body.id}`,
		body
	)
	return data
}

export const deleteSensor = async (id: number) => {
	const { data } = await axios.delete(`/api/v1/sensor/${id}`)
	return data
}

export const restoreSensor = async (id: number) => {
	const { data } = await axios.delete(
		`/api/v1/sensor/restore/${id}`
	)
	return data
}
