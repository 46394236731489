import axios from '../axios'
import { User } from '../helpers/types/user'

export const fetchUsers = async () => {
	const { data } = await axios.get('/api/v1/user')
	return data
}

export const addUser = async (body: Partial<User>) => {
	const { data } = await axios.post('/api/v1/auth/register', body)
	return data
}

export const updateUser = async (body: Partial<User>) => {
	const { data } = await axios.put(`/api/v1/user/${body.id}`, body)
	return data
}

export const changeAvatar = async (form: FormData) => {
	const { data } = await axios.post(`/api/v1/user/avatar`, form, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}

export const deleteUser = async (id: number) => {
	const { data } = await axios.delete(`/api/v1/user/${id}`)
	return data
}

export const restoreUser = async (id: number) => {
	const { data } = await axios.delete(`/api/v1/user/restore/${id}`)
	return data
}
