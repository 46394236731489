import { FC, useEffect, useState } from 'react'
import { CollapseIcon, ProfileIcon } from './'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Children, sideBarMenuItems } from '../helpers/sideBarRoutes'
import { carrierStore } from '../store/carrier'
import { Button, Flex, Input, Modal, Spin } from 'antd'
import { GlobalOutlined } from '@ant-design/icons'
import { RNProfile, RNWelcome } from '../helpers/routeNames'
import NotificationModal from './NotificationModal'
import { notificationStore } from '../store/notification'
import i18next from 'i18next'
import { authStore } from '../store/auth'
import { sensorStore } from '../store/sensor'

export const SideBar: FC = () => {
	const location = useLocation()
	const { t } = useTranslation()

	const { carriers } = carrierStore()
	const { user } = authStore()

	const sideBarCarriers = [
		...carriers.map((carrier) => ({
			path: `/carriers/${carrier.id}`,
			name: carrier.name
		}))
		// {
		// 	path: '/carriers/new',
		// 	name: t('NewCarrier'),
		// 	isAdmin: true
		// }
	]

	const [sideBarMenu, setSideBarMenu] = useState(
		sideBarMenuItems(sideBarCarriers)
	)

	const [searchEls, setSearchEls] = useState<Children[]>([])
	const [searchValue, setSearchValue] = useState('')
	const {
		isOpen: isNotificationModalOpen,
		setIsOpen: setIsNotificationModalOpen
	} = notificationStore()
	const [isLangModalOpen, setIsLangModalOpen] = useState(false)

	const { isFetched: isSensorsFetched } = sensorStore()

	const onSearch = (value: string) => {
		setSearchValue(value)
		if (value === '') {
			setSearchEls([])
			return
		}
		const children = sideBarMenu.find((el) =>
			el.path.includes('/carriers')
		)?.children

		if (!children) return
		setSearchEls(
			children.filter((el) =>
				el.name.toLowerCase().includes(value.toLowerCase())
			)
		)
	}

	useEffect(() => {
		setSideBarMenu(sideBarMenuItems(sideBarCarriers))
	}, [carriers]) // eslint-disable-line react-hooks/exhaustive-deps

	if (!user) return <></>

	return (
		<div className="sideBar">
			<Link to={RNWelcome} className="sideBar-logo">
				{/* <Logo /> */}
				<img src="/logo.png" alt="Logo" width={30} />
				<div>
					<p className="sideBar-logo-name">O'zbekiston</p>
					<p className="sideBar-logo-country">
						Temir Yo'llari
					</p>
				</div>
			</Link>
			<Flex
				align="center"
				justify="space-evenly"
				className="search"
				style={{
					backgroundColor: '#2D4056',
					padding: '5px 10px'
				}}>
				<div className="search-notification">
					<GlobalOutlined
						style={{ fontSize: 30, color: '#BCC6D8' }}
						onClick={() => setIsLangModalOpen(true)}
					/>
					<Modal
						open={isLangModalOpen}
						footer={null}
						onCancel={() => setIsLangModalOpen(false)}>
						<Flex vertical gap={16}>
							<Flex vertical gap={8}>
								<Button
									type={
										i18next.language === 'ru'
											? 'primary'
											: 'default'
									}
									onClick={() => {
										i18next.changeLanguage('ru')
										setIsLangModalOpen(false)
									}}>
									Русский
								</Button>
								<Button
									type={
										i18next.language === 'uz'
											? 'primary'
											: 'default'
									}
									onClick={() => {
										i18next.changeLanguage('uz')
										setIsLangModalOpen(false)
									}}>
									O'zbek
								</Button>
								<Button
									type={
										i18next.language === 'kz'
											? 'primary'
											: 'default'
									}
									onClick={() => {
										i18next.changeLanguage('kz')
										setIsLangModalOpen(false)
									}}>
									Қазақ
								</Button>
							</Flex>
						</Flex>
					</Modal>
				</div>
				<div className="search-notification">
					<NotificationModal
						isOpen={isNotificationModalOpen}
						setIsOpen={setIsNotificationModalOpen}
					/>
				</div>
				<Link to={RNProfile} className="search-profile">
					<ProfileIcon
						isActive={location.pathname === RNProfile}
					/>
				</Link>
			</Flex>
			<ul className="sideBar-menu">
				<li
					className="sideBar-menu-item"
					style={{
						zIndex: 2,
						backgroundColor: '#2D4056'
					}}>
					<Input.Search
						type="search"
						placeholder={t('Search')}
						autoFocus
						// value={searchValue}
						// // onChange={(e) => onSearch(e.target.value)}
						onChange={(e) => {
							// setSearchValue(e.target.value)
							if (!e.target.value.length) {
								setSearchEls([])
								setSearchValue('')
							}
						}}
						onSearch={(e) => onSearch(e)}
						size="large"
						style={{
							margin: 10,
							width: 'calc(100% - 20px)'
						}}
					/>
				</li>

				{!searchValue.length ? (
					(user.email.includes('@zarafshon.org')
						? sideBarMenu
						: sideBarMenu.filter((a) => !a.isAdmin)
					).map(
						({
							path,
							name,
							Icon,
							last = false,
							children = [],
							isOpen = location.pathname.includes(path)
						}) => (
							<li
								className={`sideBar-menu-item${
									location.pathname.includes(path)
										? ' selected'
										: ''
								}${last ? ' sidebar-last' : ''}${
									children.length ? ' hasSub' : ''
								}`}
								style={{
									minHeight: 66,
									maxHeight: !isOpen
										? 66
										: 'calc(100vh - 110px)'
								}}
								key={name}>
								<Link
									to={children.length ? '#' : path}
									className="sideBar-menu-item-link"
									onClick={
										children.length
											? (e) => {
													e.preventDefault()
													setSideBarMenu(
														sideBarMenu.map(
															(
																item
															) => {
																if (
																	item.name ===
																	name
																) {
																	item.isOpen =
																		!item.isOpen
																}
																return item
															}
														)
													)
												}
											: () => {}
									}>
									<Icon
										isActive={location.pathname.includes(
											path
										)}
									/>
									<span className="sideBar-menu-item-text">
										{t(name)}
									</span>
									{children.length ? (
										<CollapseIcon
											collapsed={isOpen}
										/>
									) : (
										''
									)}
								</Link>
								{children.length ? (
									<ul
										className={`sideBar-menu sub${
											isOpen ? ' open' : ''
										}`}>
										{(user.email.includes(
											'@zarafshon.org'
										)
											? children
											: children.filter(
													(a) => !a.isAdmin
												)
										).map(({ path, name }) => {
											return (
												<li
													className={`sideBar-menu-item child${
														location.pathname.includes(
															path
														)
															? ' selected'
															: ''
													}`}
													key={name}>
													<Link
														to={path}
														className="sideBar-menu-item-link"
														style={{
															pointerEvents:
																!isSensorsFetched
																	? 'none'
																	: 'auto'
														}}>
														<span
															className="sideBar-menu-item-text"
															style={{
																display:
																	'flex',
																justifyContent:
																	'space-between',
																alignItems:
																	'center'
															}}>
															{t(name)}
															{!isSensorsFetched ? (
																<Spin size="large" />
															) : (
																''
															)}
														</span>
													</Link>
												</li>
											)
										})}
									</ul>
								) : (
									''
								)}
							</li>
						)
					)
				) : searchEls.length && searchValue.length ? (
					searchEls.map(({ path, name }) => (
						<li
							className={`sideBar-menu-item${
								location.pathname.includes(path)
									? ' selected'
									: ''
							}`}
							key={name}>
							<Link
								to={path}
								className="sideBar-menu-item-link">
								<span className="sideBar-menu-item-text">
									{t(name)}
								</span>
							</Link>
						</li>
					))
				) : !searchEls.length && searchValue.length ? (
					<li
						className={`sideBar-menu-item`}
						key={t('NoResults')}>
						<Link
							to={'#'}
							onClick={() => setSearchValue('')}
							className="sideBar-menu-item-link">
							<b className="sideBar-menu-item-text">
								{t('NoResults')}
							</b>
						</Link>
					</li>
				) : (
					''
				)}
			</ul>
		</div>
	)
}
