import { create } from 'zustand'
import { Sensor } from '../helpers/types/sensor'
import {
	addSensor,
	deleteSensor,
	fetchSensors,
	restoreSensor,
	updateSensor
} from '../api/sensor'
import { SensorData } from '../helpers/types/sensorData'

export interface SensorState {
	sensors: Sensor[]
	isFetched: boolean
	fetchedCarriers: number[]
	fetchSensors: (
		carrierId: number,
		signal?: AbortSignal
	) => Promise<void>
	addSensor: (sensor: Sensor) => Promise<void>
	setNewSensorData: (data: SensorData) => void
	updateSensor: (sensor: Sensor) => Promise<void>
	deleteSensor: (id: number) => Promise<void>
	restoreSensor: (id: number) => Promise<void>
}

export const sensorStore = create<SensorState>((set) => ({
	sensors: [],
	fetchedCarriers: [],
	isFetched: true,
	fetchSensors: async (carrierId /*, signal*/) => {
		if (
			sensorStore.getState().fetchedCarriers.includes(carrierId)
		)
			return
		set(() => ({ isFetched: false }))
		const data = await fetchSensors(carrierId) //, signal)
		set((old) => {
			let last: Sensor[] = []
			if (old.sensors.length) {
				const inner = (sensorId: number) =>
					data
						.filter((s) => s.id === sensorId)
						.flatMap((s) => s.sensorData)

				last = old.sensors.map((sensor) => {
					return {
						...sensor,
						sensorData: [
							...sensor.sensorData,
							...inner(sensor.id)
						]
					}
				})
			}

			return {
				sensors: last.length ? last : data,
				isFetched: true,
				fetchedCarriers: [...old.fetchedCarriers, carrierId]
			}
		})
	},
	addSensor: async (sensor: Sensor) => {
		const data = await addSensor(sensor)
		set((sensors) => ({
			sensors: [...sensors.sensors, data]
		}))
	},
	setNewSensorData: (data) => {
		set((sensors) => {
			return {
				sensors: sensors.sensors.map((u) =>
					u.id === data.sensorId
						? {
								...u,
								sensorData: [
									data,
									...u.sensorData.filter(
										(s) => s.id !== data.sensorId
									)
								] /*.sort((a, b) => {
									return (
										+new Date(b.happenedAt) -
										+new Date(a.happenedAt)
									)
								})*/
							}
						: u
				)
			}
		})
	},
	updateSensor: async (sensor: Sensor) => {
		await updateSensor(sensor)
		set((sensors) => ({
			sensors: sensors.sensors.map((u) =>
				u.id === sensor.id ? sensor : u
			)
		}))
	},
	deleteSensor: async (id: number) => {
		await deleteSensor(id)
		set((sensors) => ({
			sensors: sensors.sensors.map((u) =>
				u.id === id ? { ...u, isDeleted: true } : u
			)
		}))
	},
	restoreSensor: async (id: number) => {
		await restoreSensor(id)
		set((sensors) => ({
			sensors: sensors.sensors.map((u) =>
				u.id === id ? { ...u, isDeleted: false } : u
			)
		}))
	}
}))
