import { FC } from 'react'

interface Props {
	percent: number
	color: string
}

const AqualiteIcon: FC<Props> = ({ percent, color }) => {
	return (
		<div style={{ position: 'relative', width: 110, height: 70 }}>
			<svg
				width="130"
				height="105"
				viewBox="0 0 130 105"
				fill="none"
				transform="translate(-15, -20)"
				xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_dd_4094_15696)">
					<rect
						x="115"
						y="20"
						width="70"
						height="100"
						rx="30"
						transform="rotate(90 115 20)"
						fill="url(#paint0_linear_4094_15696)"
					/>
				</g>
				<g filter="url(#filter1_i_4094_15696)">
					<rect
						x="105"
						y="28"
						width="54"
						height="80"
						rx="20"
						transform="rotate(90 105 28)"
						fill="url(#paint1_linear_4094_15696)"
					/>
				</g>
				<g filter="url(#filter2_di_4094_15696)">
					<rect
						x="95"
						y="38"
						width="34"
						height="60"
						rx="10"
						transform="rotate(90 95 38)"
						fill={color}
					/>
				</g>
				<rect
					x="55"
					y="24"
					width="20"
					height="4"
					fill={color}
				/>
				<rect
					x="53"
					y="21"
					width="24"
					height="3"
					fill={color}
				/>
				<defs>
					<filter
						id="filter0_dd_4094_15696"
						x="0"
						y="0"
						width="130"
						height="105"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="-5" />
						<feGaussianBlur stdDeviation="7.5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_4094_15696"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="5" />
						<feGaussianBlur stdDeviation="5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.823529 0 0 0 0 0.85098 0 0 0 0 0.905882 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_dropShadow_4094_15696"
							result="effect2_dropShadow_4094_15696"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect2_dropShadow_4094_15696"
							result="shape"
						/>
					</filter>
					<filter
						id="filter1_i_4094_15696"
						x="25"
						y="28"
						width="80"
						height="55"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="BackgroundImageFix"
							result="shape"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="1" />
						<feGaussianBlur stdDeviation="1" />
						<feComposite
							in2="hardAlpha"
							operator="arithmetic"
							k2="-1"
							k3="1"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
						/>
						<feBlend
							mode="normal"
							in2="shape"
							result="effect1_innerShadow_4094_15696"
						/>
					</filter>
					<filter
						id="filter2_di_4094_15696"
						x="35"
						y="38"
						width="60"
						height="35"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB">
						<feFlood
							floodOpacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="1" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.956863 0 0 0 0 0.737255 0 0 0 0 0.403922 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_4094_15696"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect1_dropShadow_4094_15696"
							result="shape"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dy="1" />
						<feComposite
							in2="hardAlpha"
							operator="arithmetic"
							k2="-1"
							k3="1"
						/>
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.629167 0 0 0 0 0.417347 0 0 0 0 0.0996181 0 0 0 1 0"
						/>
						<feBlend
							mode="normal"
							in2="shape"
							result="effect2_innerShadow_4094_15696"
						/>
					</filter>
					<linearGradient
						id="paint0_linear_4094_15696"
						x1="185"
						y1="80.9524"
						x2="115"
						y2="80.9524"
						gradientUnits="userSpaceOnUse">
						<stop stopColor={color + '30'} />
						<stop offset="1" stopColor="white" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_4094_15696"
						x1="159"
						y1="62"
						x2="105"
						y2="62"
						gradientUnits="userSpaceOnUse">
						<stop stopColor={color + 'e3'} />
						<stop offset="1" stopColor={color + '90'} />
					</linearGradient>
				</defs>
			</svg>
			<p
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(calc(-50% - 5px), -50%)',
					fontSize: '1.2rem',
					fontWeight: 'bold',
					color: 'white'
				}}>
				{percent}%
			</p>
		</div>
	)
}

export default AqualiteIcon
