import { useContext, useEffect, useMemo } from 'react'
import './App.scss'
import { SideBar } from './components'
import AppRouter from './components/AppRouter'
// import { Search } from './components/Search'
import { authStore } from './store/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	RNCarriers,
	RNLogin,
	RNLogout,
	RNWelcome
} from './helpers/routeNames'
import Cookies from 'universal-cookie'
import { userStore } from './store/user'
import { carrierStore } from './store/carrier'
import { sensorStore } from './store/sensor'
import { io } from 'socket.io-client'
import { loggingStore } from './store/logging'
import { SensorData } from './helpers/types/sensorData'
import { notificationStore } from './store/notification'
import { ConfigProvider, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { canvasStore } from './store/canvas'
import { CanvasData } from './helpers/types/canvas'
import { carrierPermissionStore } from './store/carrierPermission'
import { balanceStore } from './store/balance'
import { DeltaData } from './helpers/types/deltaData'
import { deltaStore } from './store/delta'
import { utilityStore } from './store/utils'
import { css } from '@emotion/css'

import 'swiper/css'

function App() {
	const cookie = useMemo(() => new Cookies(), [])
	// const socketRef = useRef<null | Socket>(null)
	const { isLoggedIn, refresh, userinfo, logout, user, tgLogin } =
		authStore()
	const { fetchUsers, users } = userStore()
	const { fetchCarriers, carriers } = carrierStore()
	const { sensors, setNewSensorData, fetchedCarriers } =
		sensorStore()
	const { fetchCanvas, canvas, setNewCanvasData } = canvasStore()
	const { fetchLoggings, loggings } = loggingStore()
	const {
		fetchNotificationCount,
		addNotification,
		setIsOpen: setIsNotificationModalOpen,
		isCountFetched
	} = notificationStore()
	const { setNewDeltaData } = deltaStore()
	const { fetchCarrierPermission } = carrierPermissionStore()
	const { setBalance, fetchListBalance } = balanceStore()
	const navigate = useNavigate()
	const location = useLocation()

	const { t: translate } = useTranslation()
	const t = useMemo(() => translate, [translate])

	const [api, contextHolder] = notification.useNotification({
		stack: { threshold: 3 }
	})

	const { sideBarShown, setSideBarShown } = utilityStore()

	useEffect(() => {
		if ((window as any)?.Telegram?.WebApp?.initData) {
			const tg = (window as any).Telegram.WebApp
			console.log(JSON.stringify(tg, null, 2))
			tg.ready()
			tg.setHeaderColor('#EBEFF6')
			tg.setBackgroundColor('#EBEFF6')
			tg.disableVerticalSwipes()
			tg.enableClosingConfirmation()
			tg.expand()

			const checkTgUserId = async () => {
				const data = await tgLogin(
					(window as any).Telegram.WebApp
				)
				return data
			}
			checkTgUserId()
		}
	}, [tgLogin])

	useEffect(() => {
		if (!user?.id) return

		// if (socketRef.current !== null) return
		const socket = io(process.env.REACT_APP_API_URL as string, {
			transports: ['websocket'],
			withCredentials: true
		})
		// socketRef.current = socket

		fetchedCarriers.map((c) =>
			socket.on(`sensorData:${c}`, (data: SensorData) => {
				setNewSensorData(data)
			})
		)

		socket.on('logging', (data) => {
			if (data in loggings) {
				delete loggings[data]
				fetchLoggings(data)
				// api.info({
				// 	message: t(
				// 		`New logging in carrier № ${data}`
				// 	),
				// 	description: t('Click to see more'),
				// 	onClick: () => {
				// 		navigate(RNCarriers + '/' + data)
				// 		api.destroy(`logging${data}`)
				// 	},
				// 	style: {
				// 		cursor: 'pointer'
				// 	},
				// 	key: `logging${data}`
				// })
			}
		})

		socket.on(`notification${user.id}`, (data) => {
			addNotification(data)
			const notificationMessage = {
				message: t('New notification'),
				description: data.title,
				onClick: () => {
					setIsNotificationModalOpen(true)
					api.destroy(`notification${data.id}`)
				},
				style: {
					cursor: 'pointer'
				},
				key: `notification${data.id}`
			}
			if (data.type === 'error') {
				api.error(notificationMessage)
			} else if (data.type === 'success') {
				api.success(notificationMessage)
			} else if (data.type === 'warning') {
				api.warning(notificationMessage)
			} else {
				api.info(notificationMessage)
			}
		})

		// socket.on('sensorData', (data: SensorData) => {
		// 	setNewSensorData(data)
		// 	api.info({
		// 		message:
		// 			t(`New sensor data in carrier № `) +
		// 			data.carrierId,
		// 		description: t('Click to see more'),
		// 		onClick: () => {
		// 			navigate(
		// 				RNCarriers + '/' + data.carrierId
		// 			)
		// 			api.destroy(`sensorData${data.id}`)
		// 		},
		// 		style: {
		// 			cursor: 'pointer'
		// 		},
		// 		key: `sensorData${data.id}`
		// 	})
		// })

		socket.on(
			'canvasData',
			(data: CanvasData['canvasData'][0]) => {
				setNewCanvasData(data)
				// api.info({
				// 	message: t(
				// 		`New canvas data in carrier № ${data.carrierId}`
				// 	),
				// 	description: t('Click to see more'),
				// 	onClick: () => {
				// 		navigate(
				// 			RNCarriers +
				// 				'/' +
				// 				data.carrierId +
				// 				'#' +
				// 				(data.canvasId > 3
				// 					? 'monitoring-heating'
				// 					: 'monitoring-conditioning')
				// 		)
				// 		api.destroy(`canvasData${data.id}`)
				// 	},
				// 	style: {
				// 		cursor: 'pointer'
				// 	},
				// 	key: `canvasData${data.id}`
				// })
			}
		)

		socket.on(
			'deltaData',
			(data: DeltaData & { isPCH?: boolean }) => {
				setNewDeltaData(data)
				// api.info({
				// 	message: t(
				// 		`New delta data in carrier № ${data.carrierId}`
				// 	),
				// 	description: t('Click to see more'),
				// 	onClick: () => {
				// 		navigate(
				// 			RNCarriers +
				// 				'/' +
				// 				data.carrierId +
				// 				'#' +
				// 				(data.isPCH
				// 					? 'settings-pch'
				// 					: 'monitoring')
				// 		)
				// 		api.destroy(`deltaData${data.id}`)
				// 	},
				// 	style: {
				// 		cursor: 'pointer'
				// 	},
				// 	key: `deltaData${data.id}`
				// })
			}
		)

		socket.on('balance', (data) => {
			setBalance(data)
			// api.info({
			// 	message: t(
			// 		`New balance data in carrier № ${data.carrierId}`
			// 	),
			// 	description: t('Click to see more'),
			// 	onClick: () => {
			// 		navigate(
			// 			RNCarriers + '/' + data.carrierId
			// 		)
			// 		api.destroy(`balance${data.id}`)
			// 	},
			// 	style: {
			// 		cursor: 'pointer'
			// 	},
			// 	key: `balance${data.id}`
			// })
		})

		return () => {
			socket.disconnect()
		}
	}, [
		loggings,
		fetchLoggings,
		setNewSensorData,
		setNewCanvasData,
		addNotification,
		fetchedCarriers,
		setBalance,
		setNewDeltaData,
		t,
		user?.id,
		api,
		navigate,
		setIsNotificationModalOpen
	])

	// useEffect(() => {
	// 	const tgInitiated = async () => {
	// 		console.log((window as any).Telegram)
	// 		if (
	// 			Object.keys(
	// 				(window as any)?.Telegram?.WebApp?.initDataUnsafe
	// 			).length
	// 		) {
	// 			await tgLogin
	// 		}
	// 	}

	// 	tgInitiated()
	// }, [])

	useEffect(() => {
		const init = async () => {
			if (!cookie.get('access')) {
				if (!cookie.get('refresh')) {
					if (
						location.pathname !== RNLogin &&
						location.pathname !== RNLogout
					) {
						// NEEDED TO FIX THIS WITH HTTP ONLY COOKIES MODE
						navigate(
							'/login?path=' +
								encodeURIComponent(
									location.pathname +
										location.search +
										location.hash
								)
						)
						logout()
					}
				} else {
					await refresh()
					navigate(
						location.pathname === RNLogin
							? RNWelcome
							: location.pathname +
									location.search +
									location.hash
					)
				}
			} else {
				try {
					const { locale } = await userinfo()
					if (localStorage.getItem('language') === null) {
						localStorage.setItem('language', locale)
						navigate(RNCarriers)
					}
				} catch (e) {
					if (!!cookie.get('refresh')) {
						logout()
					}
				}
			}
		}
		init()
	}, [cookie, navigate, location, refresh, userinfo, logout])

	useEffect(() => {
		if (
			!!cookie.get('access') &&
			location.pathname !== RNLogin &&
			!users.length &&
			!carriers.length &&
			!sensors.length &&
			!canvas.length
		) {
			const initials = async () => {
				await fetchUsers()
				await fetchCarriers()

				await fetchCanvas()
			}
			initials()
		}
	}, [
		fetchUsers,
		fetchCarriers,
		fetchCanvas,
		location.pathname,
		cookie,
		users.length,
		carriers.length,
		sensors.length,
		canvas.length
	])

	// useEffect(() => {
	// 	const initials = async () => {
	// 		// if (carriers.length > 0) {
	// 		// 	const carrierId = carriers.map(
	// 		// 		(carrier) => carrier.id
	// 		// 	)
	// 		await fetchSensors(2)
	// 		// }
	// 	}
	// 	initials()
	// }, [fetchSensors, carriers])

	useEffect(() => {
		if (
			!isLoggedIn ||
			location.pathname === RNLogout ||
			location.pathname === RNLogin ||
			isCountFetched
		)
			return
		const initials = async () => {
			if (!user || !carriers.length) return
			await fetchNotificationCount()
			await fetchCarrierPermission(user.id)
			await fetchListBalance()
		}
		initials()
	}, [
		isLoggedIn,
		fetchNotificationCount,
		location.pathname,
		isCountFetched,
		carriers.length,
		user,
		fetchCarrierPermission,
		fetchListBalance
	])

	useEffect(() => {
		const handleMediaChange = (x: any) => {
			if (x.matches) {
				setSideBarShown(false)
			} else {
				setSideBarShown(true)
			}
		}

		const x = window.matchMedia('(max-width: 768px)')

		handleMediaChange(x)

		x.addEventListener('change', handleMediaChange)
		return () => {
			x.removeEventListener('change', handleMediaChange)
		}
	}, [setSideBarShown])

	const { getPrefixCls } = useContext(ConfigProvider.ConfigContext)
	const rootPrefixCls = getPrefixCls()
	const linearGradientButton = css`
		&.${rootPrefixCls}-btn-primary:not([disabled]):not(
				.${rootPrefixCls}-btn-dangerous
			) {
			border-width: 0;

			> span {
				position: relative;
			}

			&::before {
				content: '';
				background: linear-gradient(135deg, #6253e1, #04befe);
				position: absolute;
				inset: 0;
				opacity: 1;
				transition: all 0.3s;
				border-radius: inherit;
			}

			&:hover::before {
				opacity: 0;
			}
		}
	`

	return (
		<ConfigProvider
			direction="ltr"
			locale={localStorage.getItem('language') as any}
			button={{
				className: linearGradientButton
			}}>
			{(!!isLoggedIn || !!cookie.get('access')) && (
				<>
					{contextHolder}
					{sideBarShown && <SideBar />}
					<section
						style={{ overflow: 'auto', height: '100vh' }}>
						{/* <Search /> */}
						<AppRouter
							sideBarShown={sideBarShown}
							isLoggedIn={
								!!isLoggedIn || !!cookie.get('access')
							}
						/>
					</section>
				</>
			)}
			{!isLoggedIn && !cookie.get('access') && (
				<AppRouter
					isLoggedIn={isLoggedIn}
					sideBarShown={sideBarShown}
				/>
			)}
		</ConfigProvider>
	)
}

export default App
