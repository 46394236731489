import { Flex } from 'antd'
import { FC } from 'react'
import { SensorCHD } from './sensors'
import { sensorStore } from '../store/sensor'
import { useTranslation } from 'react-i18next'

export const CarrierOneConsumers: FC<{
	carrierId: number
	sideBarShown: boolean
}> = ({ carrierId, sideBarShown }) => {
	const { sensors } = sensorStore()
	const { t } = useTranslation()

	const finderFC = (code: string) => {
		return (sensors
			.find((s) => {
				return (
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
				)
			})
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.value === '1') as boolean
	}
	const finderColor = (code: string) => {
		return sensors
			.find(
				(s) =>
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
			)
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.color as 'red' | 'green' | 'yellow' | undefined
	}

	return (
		<Flex gap={10} vertical>
			{!sideBarShown && (
				<header className="page-header">
					<h1 className="page-h1">{t('Consumers')}</h1>
				</header>
			)}
			<Flex
				wrap={!sideBarShown}
				style={{
					borderRadius: 10,
					overflow: 'hidden',
					boxShadow: '0px 4px 8px 0px #E0E7F2'
				}}>
				<SensorCHD
					name={'Инвертор 1'}
					value={finderFC('inverter_1')}
					color={finderColor('inverter_1')}
					code="inverter_1"
				/>
				<SensorCHD
					name={'Резерв'}
					value={finderFC('consumer_reserve1')}
					color={finderColor('consumer_reserve1')}
					code="consumer_reserve1"
				/>
				<SensorCHD
					name={'TV / DVD'}
					value={finderFC('tv_dvd')}
					color={finderColor('tv_dvd')}
					code="tv_dvd"
				/>
				<SensorCHD
					name={'Микроволновая печь'}
					value={finderFC('consumer_microwave')}
					color={finderColor('consumer_microwave')}
					code="consumer_microwave"
				/>
				<SensorCHD
					name={'Холодильник Водоохлодитель'}
					value={finderFC('refrigerator_waterchipper')}
					color={finderColor('refrigerator_waterchipper')}
					code="refrigerator_waterchipper"
				/>
				<SensorCHD
					name={'Резерв'}
					value={finderFC('consumer_reserve2')}
					color={finderColor('consumer_reserve2')}
					code="consumer_reserve2"
				/>
			</Flex>
			<Flex
				wrap={!sideBarShown}
				style={{
					borderRadius: 10,
					overflow: 'hidden',
					boxShadow: '0px 4px 8px 0px #E0E7F2',
					width: sideBarShown ? 'fit-content' : '100%'
				}}>
				<SensorCHD
					name={'Бойлер'}
					value={finderFC('consumer_boiler1')}
					color={finderColor('consumer_boiler1')}
					code="consumer_boiler1"
				/>
				<SensorCHD
					name={'Водонагреватель'}
					value={finderFC('consumer_waterheater')}
					color={finderColor('consumer_waterheater')}
					code="consumer_waterheater"
				/>
				<SensorCHD
					name={'Кипятильник'}
					value={finderFC('consumer_boiler2')}
					color={finderColor('consumer_boiler2')}
					code="consumer_boiler2"
				/>
			</Flex>
		</Flex>
	)
}
