import React, { FC } from 'react'

interface Props {
	speed: number
}

export const CarrierSpeed: FC<Props> = ({ speed }) => {
	function calculateRotation(speed: number) {
		if (speed < 0) return -145
		if (speed > 160) return 145
		return (speed / 160) * 270 - 135
	}

	return (
		<svg
			width="156"
			height="156"
			viewBox="0 0 156 156"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ transition: '1s' }}>
			<path
				d="M78 156C121.078 156 156 121.078 156 78C156 34.9218 121.078 0 78 0C34.9218 0 0 34.9218 0 78C0 121.078 34.9218 156 78 156Z"
				fill="var(--bg-color-zarafshon)"
			/>
			<path
				d="M78 146.438C115.797 146.438 146.438 115.797 146.438 78C146.438 40.203 115.797 9.5625 78 9.5625C40.203 9.5625 9.5625 40.203 9.5625 78C9.5625 115.797 40.203 146.438 78 146.438Z"
				fill="#4F6781"
			/>
			<path
				d="M78 124.875C103.888 124.875 124.875 103.888 124.875 78C124.875 52.1116 103.888 31.125 78 31.125C52.1116 31.125 31.125 52.1116 31.125 78C31.125 103.888 52.1116 124.875 78 124.875Z"
				fill="#E0F2FF"
			/>
			<path
				d="M78.0106 25.3202C78.7281 25.3202 79.3298 24.7412 79.3298 24V17.4452C79.3298 16.7272 78.7512 16.125 78.0106 16.125C77.2931 16.125 76.6914 16.704 76.6914 17.4452V24C76.7146 24.7412 77.2931 25.3202 78.0106 25.3202Z"
				fill="#8197AF"
			/>
			<path
				d="M64.3783 27.1271C65.0726 26.9418 65.4892 26.2238 65.304 25.5289L63.6145 19.1595C63.4294 18.4646 62.7119 18.0477 62.0176 18.233C61.3233 18.4183 60.9067 19.1363 61.0918 19.8311L62.7813 26.1775C62.9665 26.8955 63.6839 27.3124 64.3783 27.1271Z"
				fill="#8197AF"
			/>
			<path
				d="M51.6742 32.3843C52.2991 32.0137 52.5074 31.2262 52.1602 30.6008L48.8738 24.9262C48.5035 24.3009 47.7166 24.0924 47.0917 24.4398C46.4668 24.8104 46.2586 25.5979 46.6057 26.2233L49.869 31.8979C50.2393 32.5233 51.0493 32.7549 51.6742 32.3843Z"
				fill="#8197AF"
			/>
			<path
				d="M40.7489 40.7688C41.258 40.2593 41.258 39.4254 40.7489 38.9159L36.1201 34.2835C35.6109 33.774 34.7778 33.774 34.2686 34.2835C33.7594 34.7931 33.7594 35.6269 34.2686 36.1365L38.8974 40.7688C39.4065 41.2784 40.2397 41.2784 40.7489 40.7688Z"
				fill="#8197AF"
			/>
			<path
				d="M121.777 121.858C122.286 121.349 122.286 120.515 121.777 120.005L117.148 115.373C116.639 114.863 115.806 114.863 115.297 115.373C114.788 115.882 114.788 116.716 115.297 117.226L119.926 121.858C120.412 122.368 121.245 122.368 121.777 121.858Z"
				fill="#FF5454"
			/>
			<path
				d="M32.3707 51.7012C32.741 51.0759 32.5096 50.2652 31.8847 49.9178L26.2145 46.6288C25.5896 46.2582 24.7796 46.4899 24.4324 47.1152C24.0621 47.7406 24.2935 48.5512 24.9184 48.8987L30.5887 52.1876C31.2135 52.5351 32.0004 52.3266 32.3707 51.7012Z"
				fill="#8197AF"
			/>
			<path
				d="M131.589 109.026C131.96 108.401 131.728 107.59 131.103 107.243L125.433 103.954C124.808 103.583 123.998 103.815 123.651 104.44C123.281 105.066 123.512 105.876 124.137 106.224L129.807 109.513C130.432 109.86 131.242 109.652 131.589 109.026Z"
				fill="#FF5454"
			/>
			<path
				d="M27.118 64.4172C27.3032 63.7223 26.8866 63.0043 26.1923 62.819L19.8509 61.1051C19.1566 60.9198 18.4391 61.3367 18.2539 62.0315C18.0688 62.7264 18.4854 63.4444 19.1797 63.6297L25.5211 65.3205C26.1923 65.5289 26.9329 65.112 27.118 64.4172Z"
				fill="#8197AF"
			/>
			<path
				d="M137.769 94.0874C137.955 93.3925 137.538 92.6745 136.844 92.4892L130.502 90.7984C129.808 90.6131 129.09 91.03 128.905 91.7249C128.72 92.4197 129.137 93.1378 129.831 93.323L136.172 95.0139C136.867 95.1991 137.584 94.7822 137.769 94.0874Z"
				fill="#FF5454"
			/>
			<path
				d="M25.3131 78.06C25.3131 77.3419 24.7345 76.7397 23.9939 76.7397H17.4442C16.7267 76.7397 16.125 77.3188 16.125 78.06C16.125 78.778 16.7036 79.3802 17.4442 79.3802H23.9939C24.7345 79.3802 25.3131 78.8011 25.3131 78.06Z"
				fill="#8197AF"
			/>
			<path
				d="M139.875 78.06C139.875 77.3419 139.296 76.7397 138.555 76.7397H132.006C131.288 76.7397 130.687 77.3188 130.687 78.06C130.687 78.778 131.265 79.3802 132.006 79.3802H138.579C139.296 79.3802 139.875 78.8011 139.875 78.06Z"
				fill="#FF5454"
			/>
			<path
				d="M27.1177 91.7249C26.9326 91.03 26.2151 90.6131 25.5208 90.7984L19.1563 92.4892C18.4619 92.6745 18.0454 93.3925 18.2305 94.0874C18.4157 94.7822 19.1331 95.1991 19.8274 95.0139L26.1688 93.323C26.8863 93.1378 27.3029 92.4197 27.1177 91.7249Z"
				fill="#8197AF"
			/>
			<path
				d="M137.769 62.0315C137.584 61.3367 136.867 60.9198 136.172 61.1051L129.831 62.7959C129.137 62.9812 128.72 63.6992 128.905 64.394C129.09 65.0889 129.808 65.5058 130.502 65.3205L136.844 63.6297C137.561 63.4676 137.955 62.7495 137.769 62.0315Z"
				fill="#8197AF"
			/>
			<path
				d="M32.3732 104.44C32.0029 103.815 31.216 103.607 30.5911 103.954L24.9208 107.243C24.296 107.614 24.0877 108.401 24.4348 109.026C24.8051 109.652 25.592 109.86 26.2169 109.513L31.8871 106.224C32.512 105.876 32.7435 105.066 32.3732 104.44Z"
				fill="#8197AF"
			/>
			<path
				d="M131.589 47.1157C131.219 46.4903 130.432 46.2819 129.807 46.6293L124.137 49.9182C123.512 50.2888 123.303 51.0763 123.651 51.7017C124.021 52.3271 124.808 52.5355 125.433 52.1881L131.103 48.8991C131.751 48.5285 131.959 47.741 131.589 47.1157Z"
				fill="#8197AF"
			/>
			<path
				d="M40.7489 115.35C40.2397 114.84 39.4065 114.84 38.8974 115.35L34.2686 119.982C33.7594 120.492 33.7594 121.326 34.2686 121.835C34.7778 122.345 35.6109 122.345 36.1201 121.835L40.7489 117.203C41.258 116.716 41.258 115.883 40.7489 115.35Z"
				fill="#8197AF"
			/>
			<path
				d="M121.777 34.2835C121.268 33.774 120.435 33.774 119.926 34.2835L115.297 38.9159C114.788 39.4254 114.788 40.2593 115.297 40.7688C115.806 41.2784 116.639 41.2784 117.148 40.7688L121.777 36.1365C122.286 35.6269 122.286 34.7931 121.777 34.2835Z"
				fill="#8197AF"
			/>
			<path
				d="M108.955 24.4399C108.33 24.0693 107.52 24.3009 107.173 24.9263L103.887 30.6009C103.516 31.2262 103.748 32.0369 104.373 32.3843C104.997 32.7549 105.807 32.5233 106.155 31.8979L109.441 26.2233C109.788 25.5979 109.58 24.8105 108.955 24.4399Z"
				fill="#8197AF"
			/>
			<path
				d="M94.0267 18.2564C93.3324 18.0711 92.6149 18.488 92.4298 19.1829L90.7403 25.5292C90.5551 26.2241 90.9717 26.9421 91.666 27.1274C92.3603 27.3127 93.0778 26.8957 93.263 26.2009L94.9525 19.8546C95.1376 19.1597 94.721 18.4417 94.0267 18.2564Z"
				fill="#8197AF"
			/>
			<path
				d="M76.0498 28.6122C75.2811 31.3017 71.4092 44.7763 71.4377 47.412C71.4946 50.8815 74.4555 53.6517 78.0712 53.6248C81.6868 53.571 84.5908 50.747 84.5623 47.2775C84.5338 44.6418 80.3203 31.2479 79.4662 28.5853C79.2384 27.8591 78.5267 27.375 77.7295 27.375C76.9608 27.375 76.2491 27.886 76.0498 28.6122Z"
				fill="#FE8935"
				transform={`rotate(${calculateRotation(
					speed
				)} 78 78)`}
			/>
			<path
				d="M62.5 101.922H62.4688V95.6641H60.1875V106.938H62.4688V104.172L63.0625 103.531L65.4609 106.938H68.0859L64.7344 102.156L67.9297 98.5625H65.3438L62.5 101.922ZM68.7422 106.938H71.0234V101.883C71.0234 100.945 71.6094 100.289 72.4766 100.289C73.3203 100.289 73.8281 100.82 73.8281 101.719V106.938H76.0234V101.852C76.0234 100.922 76.5938 100.289 77.4609 100.289C78.3438 100.289 78.8281 100.82 78.8281 101.789V106.938H81.1094V101.219C81.1094 99.5312 80.0078 98.4062 78.3672 98.4062C77.1406 98.4062 76.1406 99.0703 75.7656 100.125H75.7188C75.4375 99.0312 74.5938 98.4062 73.3828 98.4062C72.2422 98.4062 71.3438 99.0703 70.9922 100.078H70.9453V98.5625H68.7422V106.938ZM83.9219 109.359L87.9219 95.6641H85.8203L81.8125 109.359H83.9219ZM88.7969 106.938H91.0781V102.156C91.0781 101.055 91.7109 100.297 92.7891 100.297C93.8281 100.297 94.3594 100.953 94.3594 102.031V106.938H96.6406V101.547C96.6406 99.6094 95.5781 98.4062 93.7188 98.4062C92.4453 98.4062 91.5312 99 91.0859 100.055H91.0391V95.6641H88.7969V106.938Z"
				fill="#8A9EB4"
			/>
			<text
				fill="#142D49"
				fontSize="40px"
				fontWeight="bold"
				fontFamily="Bebas Neue"
				textAnchor="middle">
				<tspan x="77" y="88">
					{speed}
				</tspan>
			</text>
		</svg>
	)
}
