import { FC } from 'react'

interface CarrierSidebarOutlinedProps {
	color: string
	index: string
}

const CarrierSidebarOutlined: FC<CarrierSidebarOutlinedProps> = ({
	color,
	index
}) => {
	return (
		<>
			<div className={'sensor-outlined' + index}></div>
			<style>{`
				.sensor-outlined${index} {
					width: 22px;
					height: 22px;
					border-radius: 50%;
					background-color: #506780;
					position: relative;
				}

				.sensor-outlined${index}:before {
					content: '';
					display: block;
					width: 18px;
					height: 18px;
					border-radius: 50%;
					background-color: #2A3B4C;
					position: absolute;
					top: 2px;
					left: 2px;
				}

				.sensor-outlined${index}:after {
					content: '';
					display: block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: var(--${color});
					position: absolute;
					top: 5px;
					left: 5px;
				}
			`}</style>
		</>
	)
}

export default CarrierSidebarOutlined
