import axios from '../axios'
import { CarrierPermission } from '../helpers/types/carrierPermission'

export const fetchCarrierPermission = async (userId: number) => {
	const { data } = await axios.get(
		`/api/v1/carrier-permission/${userId}`
	)
	return data
}

export const addCarrierPermission: (
	body: Partial<CarrierPermission>
) => Promise<CarrierPermission> = async (body) => {
	const { data } = await axios.post(
		'/api/v1/carrier-permission',
		body
	)
	return data
}

export const deleteCarrierPermission = async (id: number) => {
	const { data } = await axios.delete(
		`/api/v1/carrier-permission/${id}`
	)
	return data
}
