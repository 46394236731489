import { create } from 'zustand'
import {
	login,
	logout,
	refresh,
	tgWidget,
	tgLogin,
	userinfo
} from '../api/auth'
import { Token, User } from '../helpers/types/user'

export interface AuthState {
	user: User | null
	isLoggedIn: boolean
	isFetched: boolean
	isTelegramUser: boolean
	login: (email: string, password: string) => Promise<Token>
	logout: () => void
	refresh: () => Promise<void>
	userinfo: () => Promise<User>
	tgWidget: (body: Object) => Promise<void>
	tgLogin: (body: Object) => Promise<void>
}

export const authStore = create<AuthState>()((set) => ({
	isLoggedIn: false,
	user: null,
	isFetched: false,
	isTelegramUser: false,

	login: async (email: string, password: string) => {
		const data = await login(email, password)
		set(() => ({ isLoggedIn: true, isFetched: true }))
		return data
	},
	logout: async () => {
		await logout()
		set(() => ({
			user: null,
			isLoggedIn: false,
			isFetched: true
		}))
	},
	refresh: async () => {
		// const data =
		await refresh()
		set(() => ({
			isLoggedIn: true,
			// user: data,
			isFetched: true
		}))
	},
	userinfo: async () => {
		const data = await userinfo()
		set(() => ({
			isLoggedIn: true,
			user: data,
			isFetched: true
		}))
		return data
	},
	tgWidget: async (body: Object) => {
		const data = await tgWidget(body)
		return data
	},
	tgLogin: async (body: Object) => {
		set(() => ({
			isTelegramUser: true
		}))
		const data = await tgLogin(body)

		return data
	}
}))
