import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	id?: number
	text: string
	value: number
	value2: number
}

export const CHV: FC<Props> = ({ id, value, text, value2 }) => {
	const { t } = useTranslation()
	return (
		<Flex gap={10} vertical>
			<b>{t(text) + ' ' + (id ? id : '')}</b>
			<Flex gap={10}>
				<div
					style={{
						backgroundColor: '#4F6781',
						color: '#fff',
						fontSize: 25,
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4
					}}>
					-
				</div>
				<b
					style={{
						fontSize: 26,
						color: '#3894eb',
						width: 60,
						height: 38,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4,
						backgroundColor: '#fff',
						border: '1px solid #DDE2EB',
						fontFamily: 'Bebas Neue'
					}}>
					{value}
				</b>
				<div
					style={{
						backgroundColor: '#4F6781',
						color: '#fff',
						fontSize: 25,
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4
					}}>
					+
				</div>
				<div
					style={{
						width: 50,
						height: 28,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						borderRadius: 4,
						backgroundColor: '#F3F5F8',
						border: '1px solid #DDE2EB',
						fontFamily: 'Bebas Neue',
						padding: '5px'
					}}>
					<b
						style={{
							fontSize: 26,
							color: '#3894eb'
						}}>
						{value2}
					</b>
					<span
						style={{
							marginTop: 'auto',
							color: '#8A9EB4'
						}}>
						{t('Volt')}
					</span>
				</div>
			</Flex>
		</Flex>
	)
}
