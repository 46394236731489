import { Flex } from 'antd'
import { FC, ReactNode, JSX } from 'react'
import { useNavigate } from 'react-router-dom'
import { CollapseIcon } from '..'

interface HeaderBackBtnAndSettingsProps {
	title: string
	backBtn?: boolean
	backLink?: string
	settings?: ReactNode | JSX.Element[]
}

export const HeaderBackBtnAndSettings: FC<
	HeaderBackBtnAndSettingsProps
> = ({ title, backBtn, backLink, settings }) => {
	const navigate = useNavigate()

	return (
		<Flex
			align="center"
			justify="space-between"
			style={{
				height: 50,
				padding: '0 16px'
			}}>
			<Flex>
				{backBtn && (
					<span
						onClick={() => {
							if (backLink) return navigate(backLink)
							navigate(-1)
						}}
						style={{
							rotate: '270deg',
							transform: 'scale(1.8) translateY(-3px)',
							width: 33,
							height: 33,
							cursor: 'pointer'
						}}>
						<CollapseIcon
							collapsed
							collapsedColor="#3894eb"
						/>
					</span>
				)}
			</Flex>
			<h2
				style={{
					margin: 0
				}}>
				{title}
			</h2>
			<div>{settings && <></>}</div>
		</Flex>
	)
}
