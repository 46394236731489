import {
	Routes,
	Route,
	useLocation,
	Navigate
} from 'react-router-dom'
import { loginRoutes, Routes as routes } from '../routes'
import { RNLogin, RNWelcome } from '../helpers/routeNames'
import { FC } from 'react'

interface AppRouterProps {
	isLoggedIn: boolean
	sideBarShown: boolean
}

const AppRouter: FC<AppRouterProps> = ({
	isLoggedIn,
	sideBarShown
}) => {
	const location = useLocation()

	return (
		<Routes location={location}>
			{isLoggedIn
				? routes.map(({ path, Element }) => {
						if (location.pathname === '/') {
							return (
								<Route
									key={path}
									path={path}
									element={
										<Navigate to={RNWelcome} />
									}
								/>
							)
						} else if (path === '*') {
							return (
								<Route
									key={404}
									path={'*'}
									element={
										<center>
											{/* NEED TO FIX THIS!!! */}
											<h1>Загрузка ...</h1>
										</center>
									}
								/>
							)
						}
						return (
							<Route
								key={path}
								path={path}
								element={
									<Element
										sideBarShown={sideBarShown}
									/>
								}
							/>
						)
					})
				: loginRoutes.map(({ path, Element }) => {
						if (path === '*') {
							return (
								<Route
									key={404}
									path={'*'}
									element={
										<Navigate to={RNLogin} />
									}
								/>
							)
						} else {
							return (
								<Route
									key={path}
									path={path}
									element={
										<Element
											sideBarShown={
												sideBarShown
											}
										/>
									}
								/>
							)
						}
					})}
		</Routes>
	)
}

export default AppRouter
