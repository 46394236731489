import { FC } from 'react'

interface TickIconProps {
	color?: string
}

export const TickIcon: FC<TickIconProps> = ({ color }) => {
	return (
		<svg
			width="23"
			height="17"
			viewBox="0 0 23 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.1151 13.7791L2.31912 9.04748C2.19371 8.92224 2.04434 8.8228 1.87969 8.75494C1.71504 8.68707 1.53837 8.65213 1.35992 8.65213C1.18148 8.65213 1.00481 8.68707 0.840152 8.75494C0.675498 8.8228 0.526136 8.92224 0.400724 9.04748C0.273782 9.1712 0.172995 9.31856 0.104208 9.48101C0.0354205 9.64345 0 9.81775 0 9.9938C0 10.1699 0.0354205 10.3442 0.104208 10.5066C0.172995 10.669 0.273782 10.8164 0.400724 10.9401L6.1422 16.6046C6.67661 17.1318 7.53989 17.1318 8.0743 16.6046L22.5993 2.288C22.7262 2.16427 22.827 2.01691 22.8958 1.85447C22.9646 1.69202 23 1.51772 23 1.34167C23 1.16562 22.9646 0.991324 22.8958 0.828879C22.827 0.666435 22.7262 0.519077 22.5993 0.395347C22.4739 0.270108 22.3245 0.170674 22.1598 0.102809C21.9952 0.0349449 21.8185 0 21.6401 0C21.4616 0 21.285 0.0349449 21.1203 0.102809C20.9557 0.170674 20.8063 0.270108 20.6809 0.395347L7.1151 13.7791Z"
				fill={color ? color : '#3894EB'}
			/>
		</svg>
	)
}
