import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../components'
import { RNBalance } from '../helpers/routeNames'
import { CellCols } from '../helpers/cellColumns'
import { balanceStore } from '../store/balance'
import { Link, useNavigate } from 'react-router-dom'
import { SyncIcon } from '../components/icons/SyncIcon'
import { BalanceList } from '../helpers/types/balance'

const Balance: FC = () => {
	const { t } = useTranslation()
	const { balanceList } = balanceStore()
	const navigate = useNavigate()

	const columns = CellCols(
		RNBalance,
		[
			{
				title: t('Name'),
				key: 'name',
				render: (text: string, a: BalanceList) => (
					<Link to={`${RNBalance}/${a.id}`}>{a.name}</Link>
				),
				sorter: (a: BalanceList, b: BalanceList) =>
					a.name.localeCompare(b.name)
			},
			{
				title: t('FactoryNumber'),
				key: 'factoryNumber',
				render: (text: string, a: BalanceList) => (
					<span>{a.factoryNumber}</span>
				)
			},
			{
				title: t('Balance'),
				key: 'balance',
				render: (text: string, a: BalanceList) => (
					<span>{a.balance[0]?.balance || '—'}</span>
				),
				sorter: (a: BalanceList, b: BalanceList) =>
					+a.balance[0]?.balance - +b.balance[0]?.balance
			},
			{
				title: t('ReplanishmentDate'),
				render: (text: string, a: BalanceList) =>
					a.balance[0]?.replanishmentDate ? (
						<span>
							{new Date(
								a.balance[0]?.replanishmentDate
							).toLocaleString('ru') || '—'}
						</span>
					) : (
						<span>—</span>
					),
				key: 'replanishmentDate',
				sorter: (a: BalanceList, b: BalanceList) =>
					a.balance[0]?.replanishmentDate.localeCompare(
						b.balance[0]?.replanishmentDate
					)
			},
			{
				title: t('LastUpdate'),
				render: (text: string, a: BalanceList) =>
					a.balance[0]?.createdAt ? (
						<span>
							{new Date(
								a.balance[0]?.createdAt
							).toLocaleString('ru') || '—'}
						</span>
					) : (
						<span>—</span>
					),
				key: 'createdAt'
			},
			{
				title: t('UpdateNow'),
				key: 'updateNow',
				render: () => <SyncIcon loading={true} />
			}
		],
		true
	)
	const [searchEls, setSearchEls] = useState(balanceList)

	useEffect(() => {
		setSearchEls(balanceList)
	}, [balanceList])

	const onSearch = (value: string) => {
		if (value === '') {
			setSearchEls(balanceList)
			return
		}
		setSearchEls(
			balanceList.filter((el) => {
				return (
					el.name
						.toLowerCase()
						.includes(value.toLowerCase()) ||
					el.factoryNumber
						.toLowerCase()
						.includes(value.toLowerCase())
				)
			})
		)
	}

	const onAdd = () => {
		navigate(RNBalance + '/new')
	}

	const onRefresh = () => {}
	return (
		<div className="balance">
			<header className="page-header">
				<h1 className="page-h1">{t('Balance')}</h1>
			</header>
			<main>
				<Table
					dataSource={searchEls as any[]}
					columns={columns}
					onSearch={onSearch}
					onAdd={onAdd}
					onRefresh={onRefresh}
					disableAddButton
				/>
			</main>
		</div>
	)
}

export default Balance
