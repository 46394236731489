import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { RNSensors } from '../helpers/routeNames'
import { sensorStore } from '../store/sensor'
import { Sensor } from '../helpers/types/sensor'

const SensorAdd: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { addSensor } = sensorStore()

	const onFinish = async (values: Sensor) => {
		await addSensor({ ...values, measurement: 'Hz', type: '123' })
		navigate(RNSensors)
	}

	return (
		<div className="sensors">
			<header className="page-header">
				<h1 className="page-h1">{t('AddingNewSensor')}</h1>
			</header>
			<main className="sensor-one">
				<Form labelCol={{ span: 3 }} onFinish={onFinish}>
					<Form.Item
						label={t('Name')}
						name={'name'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Name') as string} />
					</Form.Item>
					<Form.Item
						label={t('Code')}
						name={'code'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Code') as string} />
					</Form.Item>
					{/* <Form.Item
						label={t('Measurement')}
						name={'measurement'}
						rules={[{ required: true }]}>
						<Select>
							<Select.Option value="celsius">
								{t('Celsius')}
							</Select.Option>
							<Select.Option value="fahrenheit">
								{t('Fahrenheit')}
							</Select.Option>
							<Select.Option value="kelvin">
								{t('Kelvin')}
							</Select.Option>
							<Select.Option value="pascal">
								{t('Pascal')}
							</Select.Option>
							<Select.Option value="bar">
								{t('Bar')}
							</Select.Option>
							<Select.Option value="psi">
								{t('Psi')}
							</Select.Option>
							<Select.Option value="mmhg">
								{t('Mmhg')}
							</Select.Option>
							<Select.Option value="torr">
								{t('Torr')}
							</Select.Option>
							<Select.Option value="atm">
								{t('Atm')}
							</Select.Option>
							<Select.Option value="rh">
								{t('Rh')}
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label={t('Type')}
						name={'type'}
						rules={[{ required: true }]}>
						<Select>
							<Select.Option value="temperature">
								{t('Temperature')}
							</Select.Option>
							<Select.Option value="humidity">
								{t('Humidity')}
							</Select.Option>
							<Select.Option value="pressure">
								{t('Pressure')}
							</Select.Option>
						</Select>
					</Form.Item> */}
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{t('Save')}
						</Button>
						<Button
							htmlType="button"
							onClick={() => navigate(RNSensors)}
							style={{ marginLeft: '20px' }}
							danger>
							{t('Cancel')}
						</Button>
					</Form.Item>
				</Form>
			</main>
		</div>
	)
}

export default SensorAdd
