import { create } from 'zustand'
import {
	fetchNotificationCount,
	fetchNotifications,
	readAllNotification,
	readNotification
} from '../api/notification'
import {
	Notification,
	NotificationResponse
} from '../helpers/types/notification'

export interface NotificationState {
	notification: NotificationResponse['rows']
	currentPage: number
	totalPages: number
	count: number
	isOpen: boolean
	isNotificationFetched: boolean
	isCountFetched: boolean
	setIsOpen: (isOpen: boolean) => void
	fetchNotifications: (
		limit: number,
		offset: number,
		userId: number
	) => Promise<void>
	fetchNotificationCount: () => Promise<void>
	addNotification: (notification: Notification) => void
	readNotification: (id: number) => Promise<void>
	readAllNotification: () => Promise<void>
	clearNotification: () => void
}

export const notificationStore = create<NotificationState>((set) => ({
	notification: [],
	currentPage: 0,
	totalPages: 1,
	count: 0,
	isOpen: false,
	isNotificationFetched: false,
	isCountFetched: false,
	setIsOpen: (isOpen) => {
		set(() => ({
			isOpen
		}))
	},
	fetchNotifications: async (limit, offset, userId) => {
		set(() => ({
			isNotificationFetched: false
		}))
		const response = await fetchNotifications(limit, offset)
		set((state) => ({
			notification: [...state.notification, ...response.rows],
			currentPage: state.currentPage + 1,
			totalPages: Math.ceil(response.count / limit),
			isNotificationFetched: true
		}))
	},
	fetchNotificationCount: async () => {
		set(() => ({
			isCountFetched: false
		}))
		const count = await fetchNotificationCount()
		set(() => ({
			count,
			isCountFetched: true
		}))
	},
	addNotification: (notification) => {
		set((state) => ({
			notification: [notification, ...state.notification],
			count: state.count + 1
		}))
	},
	readNotification: async (id: number) => {
		await readNotification(id)
		set((state) => ({
			notification: state.notification.map((notification) => {
				if (notification.id === id) {
					return {
						...notification,
						isRead: true
					}
				}
				return notification
			}),
			count: state.count === 0 ? 0 : state.count - 1
		}))
	},
	readAllNotification: async () => {
		await readAllNotification()
		set((state) => ({
			notification: state.notification.map((notification) => ({
				...notification,
				isRead: true
			})),
			count: 0
		}))
	},
	clearNotification: () => {
		set(() => ({
			notification: [],
			currentPage: 0,
			totalPages: 1,
			count: 0
		}))
	}
}))
