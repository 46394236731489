import { create } from 'zustand'
import { Balance, BalanceList } from '../helpers/types/balance'
import { fetchBalance, fetchListBalance } from '../api/balance'
import { Carrier } from '../helpers/types/carrier'

export interface BalanceState {
	balance: Record<Carrier['id'], Balance[]>
	balanceList: BalanceList[]
	fetchListBalance: () => Promise<void>
	setBalance: (balance: Balance) => void
	fetchBalance: (carrierId: number) => Promise<void>
	isFetched: boolean
}

export const balanceStore = create<BalanceState>((set) => ({
	balance: {},
	balanceList: [],
	isFetched: false,
	fetchListBalance: async () => {
		set({ isFetched: false })
		const data = await fetchListBalance()
		set((state) => ({
			balanceList: data,
			isFetched: true
		}))
	},
	setBalance: (balance) => {
		set((state) => ({
			balance: {
				...state.balance,
				[balance.carrierId]: [
					balance,
					...state.balance[balance.carrierId]
				]
			}
		}))
	},
	fetchBalance: async (carrierId) => {
		set({ isFetched: false })

		if (carrierId in balanceStore.getState().balance) {
			set({ isFetched: true })
			return
		}
		const data = await fetchBalance(carrierId)
		set((state) => ({
			balance: {
				...state.balance,
				[carrierId]: data
			},
			isFetched: true
		}))
	}
}))
