import { useState, FC, useEffect } from 'react'
import { Button, Flex, Form, FormInstance, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

export const ChangePassword: FC = () => {
	const [open, setOpen] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [form] = Form.useForm()

	const showModal = () => {
		setOpen(true)
	}

	const SubmitButton = ({ form }: { form: FormInstance }) => {
		const [submittable, setSubmittable] = useState(false)
		const values = Form.useWatch([], form)

		useEffect(() => {
			form.validateFields({ validateOnly: true }).then(
				() => {
					setSubmittable(true)
				},
				() => {
					setSubmittable(false)
				}
			)
		}, [values, form])
		return (
			<Button
				type="primary"
				color="secondary"
				style={{
					backgroundColor: '#4AC382',
					color: '#fff',
					borderColor: '#4AC382'
				}}
				htmlType="submit"
				disabled={!submittable}
				onClick={handleOk}
				loading={confirmLoading}
				size="large"
				block>
				{t('Submit')}
			</Button>
		)
	}

	const handleOk = () => {
		setConfirmLoading(true)
		setTimeout(() => {
			setOpen(false)
			setConfirmLoading(false)
		}, 2000)
	}

	const handleCancel = () => {
		setOpen(false)
	}
	const { t } = useTranslation()
	return (
		<>
			<Button type="primary" onClick={showModal} size="large">
				{t('changePassword')}
			</Button>
			<Modal
				title={
					<h2 style={{ margin: 0 }}>
						{t('changePassword')}
					</h2>
				}
				open={open}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
				footer={null}>
				<Form
					name="validateOnly"
					layout="vertical"
					autoComplete="off"
					form={form}>
					<Form.Item
						name="oldPassword"
						label={t('oldPassword')}
						rules={[{ required: true, min: 8 }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="newPassword"
						label={t('newPassword')}
						rules={[
							{
								required: true,
								min: 8
							}
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="confirmPassword"
						label={t('confirmPassword')}
						rules={[
							{
								required: true,
								min: 8
							}
						]}>
						<Input />
					</Form.Item>
				</Form>
				<Flex gap="small">
					<Button
						onClick={handleCancel}
						size="large"
						block
						style={{
							backgroundColor: '#B1B9C9',
							color: '#fff',
							borderColor: '#B1B9C9'
						}}>
						{t('Cancel')}
					</Button>
					<SubmitButton form={form} />
				</Flex>
			</Modal>
		</>
	)
}
