import { Flex } from 'antd'
import { FC } from 'react'
import { SensorCHD } from './sensors'
import { useTranslation } from 'react-i18next'
import { sensorStore } from '../store/sensor'

export const CarrierOneLightning: FC<{
	carrierId: number
	sideBarShown: boolean
}> = ({ carrierId, sideBarShown }) => {
	const { t } = useTranslation()
	const { sensors } = sensorStore()

	const finderFC = (code: string) => {
		return (sensors
			.find((s) => {
				return (
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
				)
			})
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.value === '1') as boolean
	}
	const finderColor = (code: string) => {
		return sensors
			.find(
				(s) =>
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
			)
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.color as 'red' | 'green' | 'yellow' | undefined
	}

	return (
		<Flex gap={10} vertical>
			{!sideBarShown && (
				<header className="page-header">
					<h1 className="page-h1">{t('Lightning')}</h1>
				</header>
			)}
			<Flex
				wrap={!sideBarShown}
				style={{
					borderRadius: 10,
					overflow: 'hidden',
					boxShadow: '0px 4px 8px 0px #E0E7F2'
				}}>
				<SensorCHD
					name={'Купе софиты'}
					value={finderFC('coupe_soffits')}
					color={finderColor('coupe_soffits')}
					code="coupe_soffits"
				/>
				<SensorCHD
					name={'Тамбур туалет тормозные'}
					value={finderFC('vestibule_brake_toilet')}
					color={finderColor('vestibule_brake_toilet')}
					code="vestibule_brake_toilet"
				/>
				<SensorCHD
					name={'Переходные плошадки не тормозные'}
					value={finderFC('transition_flesh_not_braking')}
					color={finderColor(
						'transition_flesh_not_braking'
					)}
					code="transition_flesh_not_braking"
				/>
				<SensorCHD
					name={'Сигнальные фогари тормозные'}
					value={finderFC('brake_warning_lights')}
					color={finderColor('brake_warning_lights')}
					code="brake_warning_lights"
				/>
				<SensorCHD
					name={'Сигнальные фогари не тормозные'}
					value={finderFC('brake_not_warning_lights')}
					color={finderColor('brake_not_warning_lights')}
					code="brake_not_warning_lights"
				/>
				<SensorCHD
					name={'Туалеты'}
					value={finderFC('light_toilets')}
					color={finderColor('light_toilets')}
					code="light_toilets"
				/>
			</Flex>
			<Flex
				vertical
				style={{
					borderRadius: 10,
					overflow: 'hidden',
					boxShadow: '0px 4px 8px 0px #E0E7F2',
					backgroundColor: '#fff'
				}}>
				<Flex justify="center">
					<h3>{t('EmergencyLighting')}</h3>
				</Flex>
				<Flex wrap={!sideBarShown}>
					<SensorCHD
						name={'Купе'}
						value={finderFC('light_coupe2')}
						color={finderColor('light_coupe2')}
						code="light_coupe2"
					/>
					<SensorCHD
						name={'Проход'}
						value={finderFC('light_passage2')}
						color={finderColor('light_passage2')}
						code="light_passage2"
					/>
					<SensorCHD
						name={'Косой колидор'}
						value={finderFC('slopic_number2')}
						color={finderColor('slopic_number2')}
						code="slopic_number2"
					/>
					<SensorCHD
						name={'Служебное отделение'}
						value={finderFC('service_department2')}
						color={finderColor('service_department2')}
						code="service_department2"
					/>
				</Flex>
			</Flex>
			<Flex
				vertical
				style={{
					borderRadius: 10,
					overflow: 'hidden',
					boxShadow: '0px 4px 8px 0px #E0E7F2',
					backgroundColor: '#fff'
				}}>
				<Flex justify="center">
					<h3>{t('MainLightning')}</h3>
				</Flex>
				<Flex wrap={!sideBarShown}>
					<SensorCHD
						name={'Купе'}
						value={finderFC('light_coupe1')}
						color={finderColor('light_coupe1')}
						code="light_coupe1"
					/>
					<SensorCHD
						name={'Проход'}
						value={finderFC('light_passage1')}
						color={finderColor('light_passage1')}
						code="light_passage1"
					/>
					<SensorCHD
						name={'Косой колидор'}
						value={finderFC('slopic_number1')}
						color={finderColor('slopic_number1')}
						code="slopic_number1"
					/>
					<SensorCHD
						name={'Служебное отделение'}
						value={finderFC('service_department1')}
						color={finderColor('service_department1')}
						code="service_department1"
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
