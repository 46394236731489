import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { utilityStore } from '../store/utils'
import { useNavigate } from 'react-router-dom'
import { RNCarrierSelect } from '../helpers/routeNames'

const Welcome: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { sideBarShown } = utilityStore()

	return (
		<main className="welcomeMain">
			<img src="/logo.png" alt="Logo" className="welcomeImg" />
			<h1 className="welcomeHeader">{t('Welcome')}</h1>

			{!sideBarShown && (
				<Button
					type="primary"
					size="large"
					onClick={() => navigate(RNCarrierSelect)}>
					{t('GoAhead')}
				</Button>
			)}
		</main>
	)
}
export default Welcome
