import { FC } from 'react'
import { IconProps } from './ClimatIcon'

const ConsumersIcon: FC<IconProps> = ({ selected }) => {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 0C1.34315 0 0 1.34315 0 3V20C0 21.6569 1.34315 23 3 23H20C21.6569 23 23 21.6569 23 20V3C23 1.34315 21.6569 0 20 0H3ZM11.5 4C7.35786 4 4 7.35786 4 11.5C4 15.6421 7.35786 19 11.5 19C15.6421 19 19 15.6421 19 11.5C19 7.35786 15.6421 4 11.5 4Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
			<rect
				x="8"
				y="9"
				width="2"
				height="5"
				rx="1"
				fill={selected ? '#fff' : '#3894EB'}
			/>
			<rect
				x="13"
				y="9"
				width="2"
				height="5"
				rx="1"
				fill={selected ? '#fff' : '#3894EB'}
			/>
		</svg>
	)
}

export default ConsumersIcon
