import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NetworkIcon from './icons/NetworkIcon'
import { Flex, Skeleton } from 'antd'
import CarrierSidebarOutlined from './CarrierSidebarOutlined'
import { SensorBGC } from './sensors'
import { sensorStore } from '../store/sensor'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import { utilityStore } from '../store/utils'
import { authStore } from '../store/auth'

interface CarriersOneSidebarProps {
	carrierId: number
	sideBarShown: boolean
}

export const CarrierOneSidebar: FC<CarriersOneSidebarProps> = ({
	carrierId,
	sideBarShown
}) => {
	const { t } = useTranslation()
	const { isFetched: isSensorsFetched, sensors } = sensorStore()
	const { weather, fetchWeather } = utilityStore()
	const { isLoggedIn, isTelegramUser } = authStore()

	useEffect(() => {
		if (!weather.length && isLoggedIn) {
			if (!sideBarShown) {
				setTimeout(async () => await fetchWeather(), 1)
			}
		}
	}, [sideBarShown, fetchWeather, weather.length, isLoggedIn])

	useEffect(() => {
		if (sideBarShown) return
		if (
			(window as any)?.Telegram?.WebApp?.initData &&
			isTelegramUser
		) {
			const tg = (window as any).Telegram.WebApp

			tg.setHeaderColor('#2D4056')
			tg.setBackgroundColor('#2D4056')
			document.documentElement.style.setProperty(
				'--bg-color-zarafshon',
				'#2D4056'
			)

			return () => {
				tg.setHeaderColor('#EBEFF6')
				tg.setBackgroundColor('#EBEFF6')
				document.documentElement.style.setProperty(
					'--bg-color-zarafshon',
					'#EBEFF6'
				)
			}
		}
		document.documentElement.style.setProperty(
			'--bg-color-zarafshon',
			'#2D4056'
		)
		return () => {
			document.documentElement.style.setProperty(
				'--bg-color-zarafshon',
				'#EBEFF6'
			)
		}
	}, [sideBarShown, isTelegramUser])

	const finderFC = (code: string) => {
		return (
			sensors
				.find((s) => {
					return (
						s.code === code &&
						!!s.sensorData.find(
							(a) => a.carrierId === carrierId
						)
					)
				})
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.value || 0
		)
	}
	const finderColor = (code: string) => {
		return sensors
			.find(
				(s) =>
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
			)
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.value !== '0'
			? 'green'
			: 'red'
	}

	const finderText = (code: string) => {
		return sensors
			.find(
				(s) =>
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
			)
			?.sensorData.find((s) => s.carrierId === carrierId)?.value
	}

	const finderRightColor = (code: string) => {
		return (
			sensors
				.find(
					(s) =>
						s.code === code &&
						!!s.sensorData.find(
							(a) => a.carrierId === carrierId
						)
				)
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.color || 'gray'
		)
	}

	return (
		<Flex style={{ width: sideBarShown ? 'auto' : '100%' }}>
			<Flex
				vertical
				gap={10}
				style={{
					width: sideBarShown ? 216 : '100%'
				}}>
				<Flex gap={10} wrap>
					<Flex
						flex={1}
						style={{
							backgroundColor: '#4F647B',
							color: '#fff',
							padding: '10px',
							borderRadius: '6px',
							gap: '10px'
						}}>
						<div>
							<p
								style={{
									fontSize: '40px',
									fontFamily: 'Bebas Neue',
									lineHeight: '40px'
								}}>
								{new Date()
									.toLocaleTimeString('ru')
									.slice(0, 5)}
							</p>
							<p
								style={{
									color: '#9EB4CC'
								}}>
								{new Date().toLocaleDateString('ru')}
							</p>
						</div>
						<div>
							<Flex gap={10}>
								<div>
									<NetworkIcon count={3} />
								</div>
								<div>{t('Uzmobile')}</div>
							</Flex>
							<button
								style={{
									backgroundColor: '#9EB4CC',
									color: '#fff',
									borderRadius: '6px',
									border: 'none'
								}}
								disabled={true}>
								{t('CheckTheBalance')}
							</button>
						</div>
					</Flex>
					{!sideBarShown && !!weather.length && (
						<Flex
							style={{
								backgroundColor: '#4F647B',
								color: '#fff',
								borderRadius: '6px',
								maxWidth: '100%',
								minWidth: 250,
								flex: 1
							}}>
							<Swiper
								spaceBetween={1}
								slidesPerView={1}
								loop
								autoplay={{ delay: 2000 }}
								coverflowEffect={{
									rotate: 100,
									stretch: 0,
									depth: 5,
									modifier: 1,
									slideShadows: true
								}}
								effect="coverflow"
								modules={[Autoplay, EffectCoverflow]}>
								{weather.map((a) => (
									<SwiperSlide
										key={a.region}
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											padding: 10,
											boxSizing: 'border-box'
										}}>
										<Flex
											gap={10}
											justify="space-between">
											<Flex gap={5} vertical>
												<h4
													style={{
														margin: 0,
														height: 42,
														textOverflow:
															'ellipsis',
														whiteSpace:
															'nowrap',
														overflow:
															'hidden',
														width: 160
													}}>
													{a.region}
												</h4>
												<h5
													style={{
														margin: 0,
														textOverflow:
															'ellipsis',
														whiteSpace:
															'nowrap',
														overflow:
															'hidden',
														width: 160
													}}>
													{a.description
														.charAt(0)
														.toUpperCase() +
														a.description.slice(
															1
														)}
												</h5>
											</Flex>
											<Flex
												gap={5}
												style={{
													fontSize: '40px'
												}}>
												<h4
													style={{
														margin: 0,
														fontFamily:
															'Bebas Neue'
													}}>
													{a.temperature}
												</h4>
												<span
													style={{
														width: 60,
														fontFamily:
															'monospace'
													}}>
													C&deg;
												</span>
											</Flex>
										</Flex>
									</SwiperSlide>
								))}
							</Swiper>
						</Flex>
					)}
				</Flex>
				<Flex
					vertical
					style={{
						gap: '10px',
						backgroundColor: '#3C526A',
						color: '#fff',
						padding: '10px',
						borderRadius: '6px'
					}}>
					<h4 style={{ margin: 0 }}>{t('Power')}</h4>
					<Flex align="center" gap={15}>
						<CarrierSidebarOutlined
							color={finderRightColor('battery_type_1')} // side_battery
							index="1"
						/>
						<h5
							style={{
								margin: 0,
								color: '#9EB4CC',
								flex: 1
							}}>
							{t(
								finderText('battery_type_1')?.split(
									' '
								)[0] || 'Battery'
							)}
						</h5>
						{isSensorsFetched ? (
							<p
								style={{
									fontSize: 22,
									fontFamily: 'Bebas Neue'
								}}>
								{
									finderText(
										'battery_type_1' // side_battery
									)?.split(' ')[1]
								}
							</p>
						) : (
							<Skeleton.Input
								active
								style={{ width: 30, minWidth: 30 }}
							/>
						)}
						<p
							style={{
								color: '#9EB4CC',
								width: '33px'
							}}>
							{
								// side_battery
								finderText('battery_type_1')?.split(
									' '
								)[2]
							}
						</p>
					</Flex>
					<Flex align="center" gap={15}>
						<CarrierSidebarOutlined
							color={finderRightColor('side_volt_net')}
							index="2"
						/>
						<h5
							style={{
								margin: 0,
								color: '#9EB4CC',
								flex: 1
							}}>
							{t('VoltageNetwork')}
						</h5>
						{isSensorsFetched ? (
							<p
								style={{
									fontSize: 22,
									fontFamily: 'Bebas Neue'
								}}>
								{finderFC('side_volt_net')}
							</p>
						) : (
							<Skeleton.Input
								active
								style={{ width: 30, minWidth: 30 }}
							/>
						)}
						<p
							style={{
								color: '#9EB4CC',
								width: '30px'
							}}>
							{t('Volt')}
						</p>
					</Flex>
					<Flex align="center" gap={15}>
						<CarrierSidebarOutlined
							color={finderRightColor('side_amp_net')}
							index="3"
						/>
						<h5
							style={{
								margin: 0,
								color: '#9EB4CC',
								flex: 1
							}}>
							{t('AmperageNetwork')}
						</h5>
						{isSensorsFetched ? (
							<p
								style={{
									fontSize: 22,
									fontFamily: 'Bebas Neue'
								}}>
								{finderFC('side_amp_net')}
							</p>
						) : (
							<Skeleton.Input
								active
								style={{ width: 30, minWidth: 30 }}
							/>
						)}
						<p
							style={{
								color: '#9EB4CC',
								width: '30px'
							}}>
							{t('Amp')}
						</p>
					</Flex>
					<Flex align="center" gap={15}>
						<CarrierSidebarOutlined
							color={finderRightColor('side_amp_batt')}
							index="4"
						/>
						<h5
							style={{
								margin: 0,
								color: '#9EB4CC',
								flex: 1
							}}>
							{t('AmperageBattery')}
						</h5>
						{isSensorsFetched ? (
							<p
								style={{
									fontSize: 22,
									fontFamily: 'Bebas Neue'
								}}>
								{finderFC('side_amp_batt')}
							</p>
						) : (
							<Skeleton.Input
								active
								style={{ width: 30, minWidth: 30 }}
							/>
						)}
						<p
							style={{
								color: '#9EB4CC',
								width: '30px'
							}}>
							{t('Amp')}
						</p>
					</Flex>
					<Flex align="center" gap={15}>
						<CarrierSidebarOutlined
							color={finderRightColor('side_temp_batt')}
							index="5"
						/>
						<h5
							style={{
								margin: 0,
								color: '#9EB4CC',
								flex: 1
							}}>
							{t('TemperatureBattery')}
						</h5>
						{isSensorsFetched ? (
							<p
								style={{
									fontSize: 22,
									fontFamily: 'Bebas Neue'
								}}>
								{finderFC('side_temp_batt')}
							</p>
						) : (
							<Skeleton.Input
								active
								style={{ width: 30, minWidth: 30 }}
							/>
						)}
						<p
							style={{
								color: '#9EB4CC',
								width: '30px'
							}}>
							C°
						</p>
					</Flex>
				</Flex>
				<Flex
					vertical
					style={{
						backgroundColor: '#3C526A',
						color: '#fff',
						padding: '10px',
						borderRadius: '6px',
						gap: '10px'
					}}>
					<h4 style={{ margin: 0 }}>{t('Control')}</h4>
					<Flex vertical gap={5}>
						<SensorBGC
							color={finderColor('side_manage_contr')}
							text={t('ControllerManagement')}
						/>
						<SensorBGC
							color={finderColor('side_manage_rng')}
							text={t('RNGControl')}
						/>
					</Flex>
				</Flex>
				<Flex
					vertical
					style={{
						backgroundColor: '#3C526A',
						color: '#fff',
						padding: '10px',
						borderRadius: '6px',
						gap: '10px'
					}}>
					<h4 style={{ margin: 0 }}>
						{t('InternalCommunication')}
					</h4>
					<Flex vertical gap={5} style={{ width: '100%' }}>
						<Flex gap={5} style={{ width: '100%' }}>
							<SensorBGC
								color={finderColor('side_netw_left')}
								text={t('LeftDoor')}
							/>
							<SensorBGC
								color={finderColor('side_netw_right')}
								text={t('RightDoor')}
							/>
						</Flex>
						<Flex gap={5}>
							<SensorBGC
								color={finderColor('side_netw_rng')}
								text={t('RNG')}
							/>
							<SensorBGC
								color={finderColor('side_netw_pch')}
								text={t('PCH')}
							/>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					vertical
					style={{
						backgroundColor: '#3C526A',
						color: '#fff',
						padding: '10px',
						borderRadius: '6px',
						gap: '10px'
					}}>
					<h4 style={{ margin: 0 }}>{t('Protection')}</h4>
					<Flex vertical gap={5}>
						<Flex gap={5}>
							<SensorBGC
								color={finderColor('side_prot_vts')}
								text={t('VTS')}
							/>
							<SensorBGC
								color={finderColor('side_prot_vtb')}
								text={t('VTB')}
							/>
							<SensorBGC
								color={finderColor('side_prot_vns')}
								text={t('VNS')}
							/>
						</Flex>
						<Flex gap={5}>
							<SensorBGC
								color={finderColor('side_prot_min1')}
								text={t('MIN.1')}
							/>
							<SensorBGC
								color={finderColor('side_prot_min2')}
								text={t('MIN.2')}
							/>
						</Flex>
						<Flex gap={5}>
							<SensorBGC
								color={finderColor('side_prot_over')}
								text={t('ControllerManagement')}
							/>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			<div></div>
		</Flex>
	)
}
