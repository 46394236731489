import { FC } from 'react'

interface CarriagesIconProps {
	isActive?: boolean
}

export const CarriagesIcon: FC<CarriagesIconProps> = ({
	isActive = false
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4711 3C8.4023 3 6.67485 4.57754 6.48754 6.63786L5.39664 18.6379C5.18368 20.9803 7.02808 23 9.38021 23H20.6199C22.9721 23 24.8165 20.9803 24.6035 18.6379L23.5126 6.63786C23.3253 4.57754 21.5979 3 19.529 3H10.4711ZM10.6683 5C9.64785 5 8.79106 5.76819 8.68011 6.78255L8.24261 10.7825C8.11316 11.9661 9.0401 13 10.2308 13H19.7694C20.9601 13 21.887 11.9661 21.7575 10.7825L21.32 6.78255C21.2091 5.76819 20.3523 5 19.3319 5H10.6683ZM8.00006 18C8.00006 16.8954 8.89549 16 10.0001 16C11.1046 16 12.0001 16.8954 12.0001 18C12.0001 19.1046 11.1046 20 10.0001 20C8.89549 20 8.00006 19.1046 8.00006 18ZM20.0001 16C18.8955 16 18.0001 16.8954 18.0001 18C18.0001 19.1046 18.8955 20 20.0001 20C21.1046 20 22.0001 19.1046 22.0001 18C22.0001 16.8954 21.1046 16 20.0001 16Z"
				fill={isActive ? '#fff' : '#61748A'}
			/>
			<path
				d="M10 25H20L23 28H7L10 25Z"
				fill={isActive ? '#fff' : '#61748A'}
			/>
		</svg>
	)
}
