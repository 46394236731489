import { create } from 'zustand'
import { CarrierPermission } from '../helpers/types/carrierPermission'
import {
	addCarrierPermission,
	deleteCarrierPermission,
	fetchCarrierPermission
} from '../api/carrierPermission'
import { User } from '../helpers/types/user'

export interface CarrierPermissionState {
	carrierPermission: Record<User['id'], CarrierPermission[]>
	fetchCarrierPermission: (userId: number) => Promise<void>
	createCarrierPermission: (
		body: Partial<CarrierPermission>
	) => Promise<void>
	deleteCarrierPermission: (
		carrierPermissionId: number,
		carrierId: number,
		userId: number,
		type: CarrierPermission['type']
	) => Promise<void>
	isFetched: boolean
}

export const carrierPermissionStore = create<CarrierPermissionState>(
	(set) => ({
		carrierPermission: {},
		isFetched: false,
		fetchCarrierPermission: async (userId) => {
			set({ isFetched: false })
			if (
				userId in
				carrierPermissionStore.getState().carrierPermission
			) {
				set({ isFetched: true })
				return
			}
			const data = await fetchCarrierPermission(userId)
			set((state) => ({
				carrierPermission: {
					...state.carrierPermission,
					[userId]: data
				},
				isFetched: true
			}))
		},
		createCarrierPermission: async (body) => {
			const data = await addCarrierPermission(body)
			set((state) => ({
				carrierPermission: {
					...state.carrierPermission,
					[data.userId]: [
						...(state.carrierPermission[data.userId] ??
							[]),
						data
					]
				}
			}))
		},
		deleteCarrierPermission: async (
			id,
			carrierId,
			userId,
			type
		) => {
			await deleteCarrierPermission(id)
			set((state) => ({
				carrierPermission: Object.fromEntries(
					Object.entries(state.carrierPermission).map(
						([key, value]) => [
							key,
							value.filter(
								(item) =>
									item.userId !== userId ||
									item.carrierId !== carrierId ||
									item.id !== id ||
									item.type !== type
							)
						]
					)
				)
			}))
		}
	})
)
