import { Flex } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CarrierOneCalorifelCanvas } from './CarrierOneCalorifelCanvas'
import { CarrierOneCondensatorCanvas } from './CarrierOneCondensatorCanvas'
import { CarrierOneConditioningCanvas } from './CarrierOneConditioningCanvas'
import { CarrierOneHighHeatCanvas } from './CarrierOneHighHeatCanvas'
import { CarrierOneLowHeatCanvas } from './CarrierOneLowHeatCanvas'
import { CarrierOneCompressorCanvas } from './CarrierOneCompressorCanvas'
import { DeltaComponent } from './DeltaComponent'
import { canvasStore } from '../store/canvas'
import { utilityStore } from '../store/utils'
import { CollapseIcon } from './icons/CollapseIcon'
import { HeaderBackBtnAndSettings } from './mobile-components/HeaderBackBtnAndSettings'

export const CarrierOneMonitoring: FC<{ id: number }> = ({ id }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { hash } = useLocation()
	const { canvasData } = canvasStore()
	const { sideBarShown, setHeaderSwitchCarrierNotificationShown } =
		utilityStore()

	const canvasStyle = {
		backgroundColor: '#fff',
		gap: 5,
		padding: 10,
		margin: '10px 0',
		borderRadius: 6,
		width: 'max-content',
		overflow: 'hidden'
	}

	useEffect(() => {
		if (!sideBarShown && hash.includes('#monitoring-'))
			setHeaderSwitchCarrierNotificationShown(false)
		else setHeaderSwitchCarrierNotificationShown(true)
		return () => setHeaderSwitchCarrierNotificationShown(true)
	}, [hash, setHeaderSwitchCarrierNotificationShown, sideBarShown])

	return (
		<Flex
			style={{
				height: '100%',
				width: sideBarShown ? 761 : 'auto'
			}}
			vertical>
			{!sideBarShown && hash === '#monitoring' && (
				<header className="page-header">
					<h1 className="page-h1">{t('Monitoring')}</h1>
				</header>
			)}
			{!sideBarShown && hash === '#monitoring' && (
				<Flex
					vertical
					style={{
						width: '100%',
						backgroundColor: '#fff',
						borderRadius: 10,
						padding: 15,
						fontSize: 19,
						fontWeight: 'bold',
						boxSizing: 'border-box',
						gap: 15,
						cursor: 'pointer'
					}}>
					<Flex
						onClick={() => navigate('#monitoring-in-out')}
						align="center"
						justify="space-between"
						style={{
							borderBottom: '1px solid #EAECF3',
							paddingBottom: 15
						}}>
						{t('Enter/exit')}
						<div
							style={{
								scale: 1.2,
								rotate: '90deg',
								width: 30,
								height: 30
							}}>
							<CollapseIcon
								collapsed
								collapsedColor="#3894eb"
							/>
						</div>
					</Flex>
					<Flex
						onClick={() =>
							navigate('#monitoring-conditioning')
						}
						align="center"
						justify="space-between"
						style={{
							borderBottom: '1px solid #EAECF3',
							paddingBottom: 15
						}}>
						{t('Conditioning')}
						<div
							style={{
								scale: 1.2,
								rotate: '90deg',
								width: 30,
								height: 30
							}}>
							<CollapseIcon
								collapsed
								collapsedColor="#3894eb"
							/>
						</div>
					</Flex>
					<Flex
						onClick={() =>
							navigate('#monitoring-heating')
						}
						align="center"
						justify="space-between"
						// style={{
						// 	borderBottom: '1px solid #EAECF3',
						// 	paddingBottom: 15
						// }}
					>
						{t('Heating')}
						<div
							style={{
								scale: 1.2,
								rotate: '90deg',
								width: 30,
								height: 30
							}}>
							<CollapseIcon
								collapsed
								collapsedColor="#3894eb"
							/>
						</div>
					</Flex>
				</Flex>
			)}
			{sideBarShown && (
				<Flex
					align="center"
					style={{
						width: '100%',
						height: 40,
						minHeight: 40,
						backgroundColor: '#647D99',
						fontWeight: 'bold',
						gap: 1,
						borderRadius: '6px',
						overflow: 'hidden'
					}}>
					<Flex
						align="center"
						justify="center"
						style={{
							flex: 1,
							fontSize: 17,
							cursor: 'pointer',
							color:
								hash === '#monitoring'
									? '#fff'
									: '#A4B1BE',
							backgroundColor:
								hash === '#monitoring'
									? '#364F69'
									: '#4F6781',
							height: 40
						}}
						onClick={() => navigate('#monitoring')}>
						{t('Enter/exit')}
					</Flex>
					<Flex
						align="center"
						justify="center"
						style={{
							flex: 1,
							fontSize: 17,
							cursor: 'pointer',
							color:
								hash === '#monitoring-conditioning'
									? '#fff'
									: '#A4B1BE',
							backgroundColor:
								hash === '#monitoring-conditioning'
									? '#364F69'
									: '#4F6781',
							height: 40
						}}
						onClick={() =>
							navigate('#monitoring-conditioning')
						}>
						{t('Conditioning')}
					</Flex>
					<Flex
						align="center"
						justify="center"
						style={{
							flex: 1,
							fontSize: 17,
							cursor: 'pointer',
							color:
								hash === '#monitoring-heating'
									? '#fff'
									: '#A4B1BE',
							backgroundColor:
								hash === '#monitoring-heating'
									? '#364F69'
									: '#4F6781',
							height: 40
						}}
						onClick={() =>
							navigate('#monitoring-heating')
						}>
						{t('Heating')}
					</Flex>
				</Flex>
			)}
			{(hash === '#monitoring' && sideBarShown) ||
			hash === '#monitoring-in-out' ? (
				<Flex vertical>
					{!sideBarShown && (
						<HeaderBackBtnAndSettings
							title={t('Enter/exit')}
							backBtn
						/>
					)}
					<div style={{ width: '100%', overflow: 'auto' }}>
						<Flex style={canvasStyle}>
							<DeltaComponent carrierId={id} />
						</Flex>
					</div>
				</Flex>
			) : hash === '#monitoring-conditioning' ? (
				<Flex vertical>
					{!sideBarShown && (
						<HeaderBackBtnAndSettings
							title={t('Conditioning')}
							backBtn
						/>
					)}
					<Flex vertical style={canvasStyle}>
						<h2 style={{ margin: 0 }}>
							{t('SupplyFan')}
						</h2>
						{
							<CarrierOneConditioningCanvas
								canvasData={
									canvasData[id]
										?.find(
											(a) =>
												a.canvasData[0]
													?.canvasId === 1
										)
										?.canvasData?.find(
											(a) => a?.canvasId === 1
										)?.value || []
								}
							/>
						}
						<h2 style={{ margin: 0 }}>
							{t('Capacitor')}
						</h2>
						<CarrierOneCondensatorCanvas
							canvasData={
								canvasData[id]
									?.find(
										(a) =>
											a.canvasData[0]
												?.canvasId === 2
									)
									?.canvasData?.find(
										(a) => a?.canvasId === 2
									)?.value || []
							}
						/>
						<h2 style={{ margin: 0 }}>
							{t('Compressor')}
						</h2>
						<CarrierOneCompressorCanvas
							canvasData={
								canvasData[id]
									?.find(
										(a) =>
											a.canvasData[0]
												?.canvasId === 3
									)
									?.canvasData?.find(
										(a) => a?.canvasId === 3
									)?.value || []
							}
						/>
					</Flex>
				</Flex>
			) : hash === '#monitoring-heating' ? (
				<Flex vertical>
					{!sideBarShown && (
						<HeaderBackBtnAndSettings
							title={t('Heating')}
							backBtn
						/>
					)}
					<Flex vertical style={canvasStyle}>
						<h2 style={{ margin: 0 }}>{t('HighHeat')}</h2>
						<CarrierOneHighHeatCanvas
							canvasData={
								canvasData[id]
									?.find(
										(a) =>
											a.canvasData[0]
												?.canvasId === 4
									)
									?.canvasData?.find(
										(a) => a?.canvasId === 4
									)?.value || []
							}
						/>
						<h2 style={{ margin: 0 }}>{t('LowHeat')}</h2>
						<CarrierOneLowHeatCanvas
							canvasData={
								canvasData[id]
									?.find(
										(a) =>
											a.canvasData[0]
												?.canvasId === 5
									)
									?.canvasData?.find(
										(a) => a?.canvasId === 5
									)?.value || []
							}
						/>
						<h2 style={{ margin: 0 }}>
							{t('Calorifel')}
						</h2>
						<CarrierOneCalorifelCanvas
							canvasData={
								canvasData[id]
									?.find(
										(a) =>
											a.canvasData[0]
												?.canvasId === 6
									)
									?.canvasData?.find(
										(a) => a?.canvasId === 6
									)?.value || []
							}
						/>
					</Flex>
				</Flex>
			) : (
				<></>
			)}
		</Flex>
	)
}
