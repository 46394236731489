import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	multiplier: number
	name: string
	value: number
	measurement: string
}

export const CHM: FC<Props> = ({
	multiplier,
	name,
	value,
	measurement
}) => {
	const { t } = useTranslation()
	return (
		<Flex gap={10} vertical>
			<b>{t(name)}</b>
			<Flex gap={10}>
				<div
					style={{
						backgroundColor: '#4F6781',
						color: '#fff',
						fontSize: 25,
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4
					}}>
					-
				</div>
				<div
					style={{
						backgroundColor: '#4F6781',
						color: '#fff',
						fontSize: 25,
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4
					}}>
					+
				</div>
				<b
					style={{
						backgroundColor: '#EB6338',
						color: '#fff',
						fontSize: 26,
						fontFamily: 'Bebas Neue',
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4
					}}>
					X{multiplier}
				</b>
				<div
					style={{
						width: 50,
						height: 28,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						borderRadius: 4,
						backgroundColor: '#F3F5F8',
						border: '1px solid #DDE2EB',
						fontFamily: 'Bebas Neue',
						padding: '5px'
					}}>
					<b
						style={{
							fontSize: 26,
							color: '#3894eb'
						}}>
						{value}
					</b>
					<span
						style={{
							marginTop: 'auto',
							color: '#8A9EB4'
						}}>
						{t(measurement)}
					</span>
				</div>
			</Flex>
		</Flex>
	)
}
