import { FC } from 'react'

interface ProfileIconProps {
	isActive?: boolean
}

export const ProfileIcon: FC<ProfileIconProps> = ({ isActive }) => {
	return (
		<svg
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect
				width="36"
				height="36"
				rx="18"
				fill={isActive ? '#3894EB' : '#BCC6D8'}
			/>
			<path
				d="M26 25.0033C26 25.0054 26 25.0074 26 25.0099C23.8856 26.8681 21.0789 28 18.0003 28C14.9211 28 12.1144 26.8681 10 25.0099C10 25.0074 10 25.0054 10 25.0033C10 21.9128 11.8661 19.2451 14.5655 18C15.58 18.9806 16.7679 19.509 18.0003 19.509C19.2332 19.509 20.4205 18.9806 21.4355 18C24.1349 19.2451 26 21.9128 26 25.0033Z"
				fill="white"
			/>
			<path
				d="M18.5 8C20.9851 8 23 9.93979 23 12.333C23 14.7267 20.9851 18 18.5 18C16.0149 18 14 14.7267 14 12.333C14 9.93979 16.0149 8 18.5 8Z"
				fill="white"
			/>
		</svg>
	)
}
