import { Flex, Button, Switch } from 'antd'
import { FC } from 'react'
import { CHV } from './sensors/CHV'
import { SBV } from './sensors/SBV'
import { useTranslation } from 'react-i18next'
import { sensorStore } from '../store/sensor'

interface Props {
	carrierId: number
}

export const CarrierOneSettingsComp: FC<Props> = ({ carrierId }) => {
	const { t } = useTranslation()

	const { sensors } = sensorStore()

	const sensorSelection = (sensor: string) => {
		return (
			sensors.find((s) => s.name === sensor)?.sensorData[0]
				?.value || '0'
		)
	}

	return (
		<Flex gap={10} vertical>
			<Flex
				gap={10}
				vertical
				style={{
					backgroundColor: '#fff',
					padding: 10,
					borderRadius: '6px'
				}}>
				<h3 style={{ margin: 0 }}>{t('ThermalSensors')}</h3>
				<Flex gap={10} justify="space-between">
					<CHV
						id={1}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_1_1')}
						value2={
							+sensorSelection('climate_termsr_1_2')
						}
					/>
					<CHV
						id={2}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_2_1')}
						value2={
							+sensorSelection('climate_termsr_2_2')
						}
					/>
					<CHV
						id={3}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_3_1')}
						value2={
							+sensorSelection('climate_termsr_3_2')
						}
					/>
				</Flex>
				<Flex gap={10} justify="space-between">
					<CHV
						id={4}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_4_1')}
						value2={
							+sensorSelection('climate_termsr_4_2')
						}
					/>
					<CHV
						id={5}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_5_1')}
						value2={
							+sensorSelection('climate_termsr_5_2')
						}
					/>
					<CHV
						id={6}
						text="ThermalSensor"
						value={+sensorSelection('climate_termsr_6_1')}
						value2={
							+sensorSelection('climate_termsr_6_2')
						}
					/>
				</Flex>
			</Flex>
			<Flex gap={10}>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: 'fit-content'
					}}>
					<h3 style={{ margin: 0 }}>{t('Timers')}</h3>
					<Flex gap={15}>
						<Flex gap={10} vertical>
							<SBV
								name={t('Timer') + ' 1'}
								value={
									+sensorSelection(
										'climate_timer_1'
									)
								}
							/>
							<SBV
								name={t('Timer') + ' 2'}
								value={
									+sensorSelection(
										'climate_timer_2'
									)
								}
							/>
						</Flex>
						<div
							style={{
								height: '100%',
								width: 1,
								backgroundColor: '#DDE2EB'
							}}></div>
						<Flex gap={10} vertical>
							<SBV
								name={t('Timer') + ' 30 ' + t('sec')}
								value={
									+sensorSelection(
										'climate_timer_30'
									)
								}
							/>
							<SBV
								name={t('Timer') + ' 420 ' + t('sec')}
								value={
									+sensorSelection(
										'climate_timer_420'
									)
								}
							/>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: 200
					}}>
					<h3 style={{ margin: 0 }}>
						{t('SensorInversion')}
					</h3>
					<Flex justify="space-between" align="center">
						{t('WaterAvailablity')}
						<Switch
							checked={
								sensorSelection(
									'si_water_avaibility'
								) !== '0'
							}
							style={{
								backgroundColor:
									sensorSelection(
										'si_water_avaibility'
									) !== '0'
										? 'green'
										: 'red'
							}}
						/>
					</Flex>
					<Flex justify="space-between" align="center">
						{t('WaterTemperature')}
						<Switch
							checked={
								sensorSelection(
									'si_water_temperature'
								) !== '0'
							}
							style={{
								backgroundColor:
									sensorSelection(
										'si_water_temperature'
									) !== '0'
										? 'green'
										: 'red'
							}}
						/>
					</Flex>
					<Flex justify="space-between" align="center">
						{t('WaterTank')}
						<Switch
							checked={
								sensorSelection('si_water_tank') !==
								'0'
							}
							style={{
								backgroundColor:
									sensorSelection(
										'si_water_tank'
									) !== '0'
										? 'green'
										: 'red'
							}}
						/>
					</Flex>
					<style>
						{`.ant-switch-handle::before {border-radius: 2px !important}.ant-switch {border-radius: 4px !important}`}
					</style>
				</Flex>
			</Flex>
			<Flex gap={10}>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: '100%'
					}}>
					<h3 style={{ margin: 0 }}>
						{t('PressureSensors')}
					</h3>
					<Flex gap={10} justify="space-between" vertical>
						<Flex vertical gap={10}>
							<b>{t('HighPressure')}</b>
							<Flex
								justify="space-between"
								gap={10}
								align="center">
								<Flex
									justify="space-between"
									align="center"
									style={{
										border: '1px solid #DDE2EB',
										width: 110,
										height: 40,
										borderRadius: 4,
										backgroundColor: '#fff',
										boxSizing: 'border-box'
									}}>
									<b
										style={{
											fontSize: 26,
											color: '#3894eb',
											fontFamily: 'Bebas Neue',
											paddingLeft: 10
										}}>
										{sensorSelection(
											'ps_high_pressure_1'
										) || '0-30'}
									</b>
									<span
										style={{
											paddingRight: 10,
											color: '#8A9EB4',
											fontSize: 12,
											margin: 'auto 0 8px 0'
										}}>
										BAR
									</span>
								</Flex>
								<Switch
									checked={
										sensorSelection(
											'ps_high_pressure_2'
										) !== '0'
									}
									style={{
										backgroundColor:
											sensorSelection(
												'ps_high_pressure_2'
											) !== '0'
												? 'green'
												: 'red'
									}}
								/>
								<Button
									type="primary"
									style={{
										backgroundColor: '#EE4A4A',
										borderRadius: 4,
										border: 'none',
										color: '#fff',
										width: 180,
										height: 40
									}}>
									{sensorSelection(
										'ps_high_pressure_3'
									) === '0'
										? t('SensorNotConfigured')
										: t('SensorConfigured')}
								</Button>
								<Flex
									align="center"
									style={{
										border: '1px solid #DDE2EB',
										color: '#3894eb',
										width: 60,
										height: 40,
										borderRadius: 4,
										backgroundColor: '#fff',
										boxSizing: 'border-box'
									}}>
									<b
										style={{
											fontSize: 26,
											color: '#3894eb',
											fontFamily: 'Bebas Neue',
											paddingLeft: 10
										}}>
										{sensorSelection(
											'ps_high_pressure_4'
										) !== '0' || '0.00'}
									</b>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
					<Flex gap={10} justify="space-between" vertical>
						<Flex vertical gap={10}>
							<b>{t('LowPressure')}</b>
							<Flex
								justify="space-between"
								gap={10}
								align="center">
								<Flex
									justify="space-between"
									align="center"
									style={{
										border: '1px solid #DDE2EB',
										width: 110,
										height: 40,
										borderRadius: 4,
										backgroundColor: '#fff',
										boxSizing: 'border-box'
									}}>
									<b
										style={{
											fontSize: 26,
											color: '#3894eb',
											fontFamily: 'Bebas Neue',
											paddingLeft: 10
										}}>
										{sensorSelection(
											'ps_low_pressure_1'
										) || '0-30'}
									</b>
									<span
										style={{
											paddingRight: 10,
											color: '#8A9EB4',
											fontSize: 12,
											margin: 'auto 0 8px 0'
										}}>
										BAR
									</span>
								</Flex>
								<Switch
									checked={
										sensorSelection(
											'ps_low_pressure_2'
										) !== '0'
									}
									style={{
										backgroundColor:
											sensorSelection(
												'ps_low_pressure_2'
											) !== '0'
												? 'green'
												: 'red'
									}}
								/>
								<Button
									type="primary"
									style={{
										backgroundColor: '#EE4A4A',
										borderRadius: 4,
										border: 'none',
										color: '#fff',
										width: 180,
										height: 40
									}}>
									{sensorSelection(
										'ps_low_pressure_3'
									) === '0'
										? t('SensorNotConfigured')
										: t('SensorConfigured')}
								</Button>
								<Flex
									align="center"
									style={{
										border: '1px solid #DDE2EB',
										color: '#3894eb',
										width: 60,
										height: 40,
										borderRadius: 4,
										backgroundColor: '#fff',
										boxSizing: 'border-box'
									}}>
									<b
										style={{
											fontSize: 26,
											color: '#3894eb',
											fontFamily: 'Bebas Neue',
											paddingLeft: 10
										}}>
										{sensorSelection(
											'ps_high_pressure_2'
										) !== '0' || '0.00'}
									</b>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					gap={10}
					vertical
					style={{
						backgroundColor: '#fff',
						padding: 10,
						borderRadius: '6px',
						width: 200
					}}>
					<h3 style={{ margin: 0 }}>{t('Megalith')}</h3>
					<Flex justify="space-between" align="center">
						{t('ResetResource')} 1
						<Switch
							checked={
								sensorSelection(
									'm_reset_resource_1'
								) !== '0'
							}
							style={{
								backgroundColor:
									sensorSelection(
										'm_reset_resource_1'
									) !== '0'
										? 'green'
										: 'red'
							}}
						/>
					</Flex>
					<Flex justify="space-between" align="center">
						{t('ResetResource')} 2
						<Switch
							checked={
								sensorSelection(
									'm_reset_resource_2'
								) !== '0'
							}
							style={{
								backgroundColor:
									sensorSelection(
										'm_reset_resource_2'
									) !== '0'
										? 'green'
										: 'red'
							}}
						/>
					</Flex>
					<Flex justify="space-between" align="center">
						<SBV
							name="Timer 3"
							value={+sensorSelection('m_timer_1')}
							measurement="Hz"
						/>
					</Flex>
					<Flex justify="space-between" align="center">
						<SBV
							name="Timer 3"
							value={+sensorSelection('m_timer_2')}
							measurement="Hz"
						/>
					</Flex>
					<style>
						{`.ant-switch-handle::before {border-radius: 2px !important}.ant-switch {border-radius: 4px !important}`}
					</style>
				</Flex>
			</Flex>
		</Flex>
	)
}
