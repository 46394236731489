import { FC } from 'react'

interface SensorIconProps {
	isActive?: boolean
}

export const SensorIcon: FC<SensorIconProps> = ({
	isActive = false
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="15"
				cy="15"
				r="2"
				fill={isActive ? '#fff' : '#61748A'}
			/>
			<path
				d="M19 19C21.2091 16.7909 21.2091 13.2091 19 11M11 19C8.79087 16.7909 8.79087 13.2091 11 11"
				stroke={isActive ? '#fff' : '#61748A'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M22.5 22.5C26.6421 18.3578 26.6421 11.6421 22.5 7.49999M7.50001 22.5C3.35788 18.3578 3.35788 11.6421 7.50001 7.49999"
				stroke={isActive ? '#fff' : '#61748A'}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	)
}
