import { Flex, Skeleton, Switch } from 'antd'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import SnowIcon from './icons/SnowIcon'
import HeaterIcon from './icons/HeaterIcon'
import FanIcon from './icons/FanIcon'
import VentilationIcon from './icons/VentilationIcon'
import WaterBoilerIcon from './icons/WaterBoilerIcon'
import AqualiteIcon from './icons/AqualiteIcon'
import MegalithIcon from './icons/MegalithIcon'
import { sensorStore } from '../store/sensor'
import { utilityStore } from '../store/utils'

export const CarrierOneHome: FC<{ carrierId: number }> = ({
	carrierId
}) => {
	const { t } = useTranslation()
	const { isFetched: isSensorsFetched, sensors } = sensorStore()
	const { sideBarShown } = utilityStore()

	function getColorByPercentage(percentage: number) {
		percentage = Math.max(0, Math.min(100, percentage))
		const value = percentage / 100
		const redColor = { r: 238, g: 94, b: 94 }
		const greenColor = { r: 116, g: 194, b: 79 }
		const color = {
			r: Math.round(
				redColor.r * (1 - value) + greenColor.r * value
			),
			g: Math.round(
				redColor.g * (1 - value) + greenColor.g * value
			),
			b: Math.round(
				redColor.b * (1 - value) + greenColor.b * value
			)
		}
		const colorHex = `#${(
			(1 << 24) |
			(color.r << 16) |
			(color.g << 8) |
			color.b
		)
			.toString(16)
			.slice(1)}`

		return colorHex
	}

	const colorsIcon = (value: string) => {
		switch (value) {
			case '1':
				return ['#fff', '#3894eb']
			default:
				return ['#fff', '#bcc6d8']
		}
	}

	const bgcSensorColor = (value: string) => {
		switch (value) {
			case '1':
				return ['red', '#fff']
			case '2':
				return ['yellow', '#fff']
			case '3':
				return ['green', '#fff']
			case '4':
				return ['default', '#fff']
			case '6':
				return ['gray', '#000']
			default:
				return ['gray', '#000']
		}
	}

	const sensorSelection = (sensor: string) => {
		return (
			sensors
				.find(
					(s) =>
						s.code === sensor &&
						!!s.sensorData.find(
							(a) => a.carrierId === carrierId
						)
				)
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.value || '0'
		)
	}

	const finderFC = (code: string) => {
		return (sensors
			.find((s) => {
				return (
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
				)
			})
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.value === '1') as boolean
	}

	const simpleColorSelection = (sensor: string) => {
		return (
			sensors
				.find(
					(s) =>
						s.code === sensor &&
						!!s.sensorData.find(
							(a) => a.carrierId === carrierId
						)
				)
				?.sensorData.find((s) => s.carrierId === carrierId)
				?.color || 'gray'
		)
	}

	const bgcSensors = [
		{
			name: 'Наличие 3000 Вольт',
			color: bgcSensorColor(sensorSelection('climate_3000_v'))
		},
		{
			name: 'Низковольтное отопление 1 гр',
			color: bgcSensorColor(
				sensorSelection('climate_low_heat_1')
			)
		},
		{
			name: 'Низковольтное отопление 2 гр',
			color: bgcSensorColor(
				sensorSelection('climate_low_heat_2')
			)
		},
		{
			name: 'Электокалорифель 1гр',
			color: bgcSensorColor(
				sensorSelection('climate_el_pool_1')
			)
		},
		{
			name: 'Электокалорифель 2гр',
			color: bgcSensorColor(
				sensorSelection('climate_el_pool_2')
			)
		},
		{
			name: 'Высокое отопление 1гр',
			color: bgcSensorColor(
				sensorSelection('climate_high_heat_1')
			)
		},
		{
			name: 'Высокое отопление 2гр',
			color: bgcSensorColor(
				sensorSelection('climate_high_heat_2')
			)
		},
		{
			name: 'Насос отопление',
			color: bgcSensorColor(
				sensorSelection('climate_pump_heat')
			),
			hr: true
		},
		{
			name: 'Работа приточного вентилятора',
			color: bgcSensorColor(
				sensorSelection('climate_supply_fan')
			)
		},
		{
			name: 'Работа конденсатора',
			color: bgcSensorColor(
				sensorSelection('climate_capacitor')
			)
		},
		{
			name: 'Работа компрессора',
			color: bgcSensorColor(
				sensorSelection('climate_compressor')
			)
		},
		{
			name: 'Клапан кондиционера',
			color: bgcSensorColor(
				sensorSelection('climate_air_valve')
			)
		}
	]

	return (
		<Flex gap={10} wrap={!sideBarShown}>
			<Flex
				gap={10}
				wrap={!sideBarShown}
				style={{
					backgroundColor: '#fff',
					padding: 10,
					borderRadius: 6,
					boxShadow: '0px 4px 8px 0px #E0E7F2',
					width: sideBarShown ? 'auto' : '100%'
				}}>
				<Flex vertical gap={11} flex={sideBarShown ? 0 : 1}>
					<h2 style={{ margin: '0 0 6px 0' }}>
						{t('ClimateControl')}
					</h2>
					<Flex
						align="center"
						style={{
							borderRadius: '4px',
							overflow: 'hidden',
							gap: 1
						}}>
						<Flex
							align="center"
							justify="center"
							style={{
								backgroundColor:
									sensorSelection(
										'climate_auto_manual'
									) !== '0'
										? '#DAE1E9'
										: '#3894EB',
								height: '40px',
								textAlign: 'center',
								fontWeight: 700,
								color:
									sensorSelection(
										'climate_auto_manual'
									) !== '0'
										? '#8A9EB4'
										: '#fff',
								flex: 1
							}}>
							{t('Auto')}
						</Flex>
						<Flex
							align="center"
							justify="center"
							style={{
								backgroundColor:
									sensorSelection(
										'climate_auto_manual'
									) !== '1'
										? '#DAE1E9'
										: '#3894EB',
								height: '40px',
								textAlign: 'center',
								fontWeight: 700,
								color:
									sensorSelection(
										'climate_auto_manual'
									) !== '1'
										? '#8A9EB4'
										: '#fff',
								flex: 1
							}}>
							{t('Manual')}
						</Flex>
					</Flex>
					<Flex gap={8} justify="center">
						<SnowIcon
							color={
								colorsIcon(
									sensorSelection('climate_snow')
								)[0]
							}
							bgColor={
								colorsIcon(
									sensorSelection('climate_snow')
								)[1]
							}
						/>
						<HeaterIcon
							color={
								colorsIcon(
									sensorSelection('climate_heating')
								)[0]
							}
							bgColor={
								colorsIcon(
									sensorSelection('climate_heating')
								)[1]
							}
						/>
						<FanIcon
							color={
								colorsIcon(
									sensorSelection('climate_fan')
								)[0]
							}
							bgColor={
								colorsIcon(
									sensorSelection('climate_fan')
								)[1]
							}
						/>
						<VentilationIcon
							color={
								colorsIcon(
									sensorSelection(
										'climate_ventilation'
									)
								)[0]
							}
							bgColor={
								colorsIcon(
									sensorSelection(
										'climate_ventilation'
									)
								)[1]
							}
						/>
					</Flex>
					<Flex
						align="center"
						style={{
							backgroundColor: '#3894EB',
							color: '#fff',
							height: '40px',
							borderRadius: '4px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								textAlign: 'center',
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('Specified')}
						</span>
						<Flex
							align="center"
							style={{
								backgroundColor: '#fff',
								color: '#3894EB',
								height: '36px',
								width: '55px',
								borderRadius: '0 3px 3px 0',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection(
										'climate_specified'
									)}
								</p>
							) : (
								<Skeleton.Input
									active
									style={{
										width: 30,
										minWidth: 30
									}}
								/>
							)}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								С°
							</p>
						</Flex>
					</Flex>
					<hr style={{ margin: 0 }} />
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('Coupe')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection('climate_kupe')}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}{' '}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								С°
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('Outdoor')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection('climate_outer')}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}{' '}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								С°
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('SupplyFan')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection(
										'climate_superior'
									)}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								С°
							</p>
						</Flex>
					</Flex>
					<hr style={{ margin: 0 }} />
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('HighPressure')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection(
										'climate_high_press'
									)}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								Bar
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('LowPressure')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection(
										'climate_low_press'
									)}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								Bar
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('CompressorFrequency')}
						</span>
						<Flex
							align="center"
							style={{
								color: '#3894EB',
								height: '36px',
								width: '55px',
								marginRight: '2px'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '22px'
									}}>
									{sensorSelection(
										'climate_compr_freq'
									)}
								</p>
							) : (
								<Skeleton.Input
									style={{
										width: 30,
										minWidth: 30
									}}
									active
								/>
							)}
							<p
								style={{
									color: '#8A9EB4',
									fontSize: 12,
									marginRight: 5
								}}>
								Hz
							</p>
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							backgroundColor: '#bcc6d8',
							borderRadius: '4px',
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('Damper 1 open')}
						</span>
						<Flex
							align="center"
							justify="center"
							style={{
								color: '#3894EB',
								height: '22px',
								width: '55px',
								marginRight: '4px',
								borderRadius: '3px',
								backgroundColor: '#fff'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '16px'
									}}>
									{sensorSelection(
										'climate_dumper_1'
									)}
									%
								</p>
							) : (
								<Skeleton.Input
									active
									style={{
										width: 30,
										minWidth: 30,
										height: 24
									}}
								/>
							)}
						</Flex>
					</Flex>
					<Flex
						align="center"
						style={{
							backgroundColor: '#bcc6d8',
							borderRadius: '4px',
							height: '30px',
							fontWeight: 700,
							fontSize: '16px'
						}}>
						<span
							style={{
								flex: 1,
								fontSize: 14,
								marginLeft: '5px'
							}}>
							{t('Damper 2 open')}
						</span>
						<Flex
							align="center"
							justify="center"
							style={{
								color: '#3894EB',
								height: '22px',
								width: '55px',
								marginRight: '4px',
								borderRadius: '3px',
								backgroundColor: '#fff'
							}}>
							{isSensorsFetched ? (
								<p
									style={{
										flex: 1,
										textAlign: 'center',
										fontSize: '22px',
										fontFamily: 'Bebas Neue',
										lineHeight: '16px'
									}}>
									{sensorSelection(
										'climate_dumper_2'
									)}
									%
								</p>
							) : (
								<Skeleton.Input
									active
									style={{
										width: 30,
										minWidth: 30,
										height: 24
									}}
								/>
							)}
						</Flex>
					</Flex>
				</Flex>
				<Flex
					vertical
					gap={15}
					style={{
						marginTop: 7,
						flex: sideBarShown ? 0 : 1
					}}>
					{bgcSensors.map((sensor) => (
						<Fragment key={sensor.name}>
							{!isSensorsFetched ? (
								<Skeleton.Input
									active
									style={{
										height: 30,
										width: sideBarShown
											? 210
											: 'auto'
									}}
								/>
							) : (
								<>
									<div
										style={{
											height: 30,
											width: sideBarShown
												? 210
												: 'auto',
											backgroundColor: `var(--${sensor.color[0]})`,
											color: sensor.color[1],
											borderRadius: 4,
											display: 'flex',
											alignItems: 'center',
											paddingLeft: 10,
											fontSize: 14,
											minWidth: 218
										}}>
										{sensor.name}
									</div>
									{sensor.hr && (
										<hr style={{ margin: 0 }} />
									)}
								</>
							)}
						</Fragment>
					))}
				</Flex>
			</Flex>
			<Flex
				vertical={sideBarShown}
				gap={16}
				wrap={!sideBarShown}
				style={{
					width: '100%'
				}}>
				<Flex
					vertical
					flex={1}
					gap={10}
					style={{
						backgroundColor: '#fff',
						borderRadius: 6,
						boxShadow: '0px 4px 8px 0px #E0E7F2',
						padding: 10
					}}>
					<h3
						style={{
							margin: 0
						}}>
						{t('WaterBoiler')}
					</h3>
					<Flex gap={10} flex={1}>
						<WaterBoilerIcon
							percent={
								+sensorSelection('water_percent')
							}
							color={getColorByPercentage(
								+sensorSelection('water_percent')
							)}
						/>
						<Flex style={{ flex: 1 }} vertical gap={10}>
							{isSensorsFetched ? (
								<Flex align="center">
									<span>{t('Temperature')}</span>
									<b
										style={{
											fontSize: 22,
											fontFamily: 'Bebas Neue',
											color: '#3894EB',
											marginLeft: 'auto'
										}}>
										{sensorSelection(
											'water_temp'
										)}
									</b>
									<p
										style={{
											marginLeft: 10
										}}>
										C°
									</p>
								</Flex>
							) : (
								<Skeleton.Input block active />
							)}
							{isSensorsFetched ? (
								<Flex>
									<Flex
										align="center"
										style={{
											backgroundColor:
												sensorSelection(
													'water_heat'
												) === '0'
													? '#bcc6d8'
													: '#ee5e5e',
											height: 30,
											color:
												sensorSelection(
													'water_heat'
												) === '0'
													? '#000'
													: '#fff',
											width: '100%',
											borderRadius: 4,
											paddingLeft: 10,
											boxSizing: 'border-box'
										}}>
										{t('WaterOverheat')}
									</Flex>
								</Flex>
							) : (
								<Skeleton.Input block active />
							)}
						</Flex>
					</Flex>
				</Flex>
				<Flex
					flex={1}
					vertical
					gap={10}
					style={{
						backgroundColor: '#fff',
						borderRadius: 6,
						boxShadow: '0px 4px 8px 0px #E0E7F2',
						padding: 10
					}}>
					<h3
						style={{
							margin: 0,
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						{t('Aqualite')}
						{isSensorsFetched ? (
							<Switch
								checked={finderFC('aqualit_check')}
								style={{
									backgroundColor: `var(--${simpleColorSelection(
										'aqualit_check'
									)})`
								}}
							/>
						) : (
							<Skeleton.Input
								active
								style={{
									width: 44,
									minWidth: 44,
									height: 25,
									marginLeft: 'calc(100% - 44px)'
								}}
							/>
						)}
					</h3>
					<Flex gap={10} align="flex-end">
						<AqualiteIcon
							percent={
								+sensorSelection('aqualit_percent')
							}
							color={getColorByPercentage(
								+sensorSelection('aqualit_percent')
							)}
						/>
						{isSensorsFetched ? (
							<Flex
								align="center"
								style={{
									backgroundColor: `var(--${
										bgcSensorColor(
											sensorSelection(
												'aqualit_work'
											)
										)[0]
									})`,
									height: 30,
									color: bgcSensorColor(
										sensorSelection(
											'aqualit_work'
										)
									)[1],
									width: '100%',
									borderRadius: 4,
									paddingLeft: 10,
									boxSizing: 'border-box'
								}}>
								{t('Work')}
							</Flex>
						) : (
							<Skeleton.Input block active />
						)}
					</Flex>
				</Flex>
				<Flex
					vertical
					flex={1}
					gap={10}
					style={{
						backgroundColor: '#fff',
						borderRadius: 6,
						boxShadow: '0px 4px 8px 0px #E0E7F2',
						padding: 10
					}}>
					<h3
						style={{
							margin: 0,
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						{t('Megalith')}
						{isSensorsFetched ? (
							<Switch
								checked={finderFC('megalith_check')}
								style={{
									backgroundColor: `var(--${simpleColorSelection(
										'megalith_check'
									)})`
								}}
							/>
						) : (
							<Skeleton.Input
								active
								style={{
									width: 44,
									minWidth: 44,
									height: 25,
									marginLeft: 'calc(100% - 44px)'
								}}
							/>
						)}
					</h3>
					<Flex gap={10}>
						<MegalithIcon />
						<Flex vertical flex={1} gap={10}>
							{isSensorsFetched ? (
								<Flex
									align="center"
									style={{
										backgroundColor: `var(--${
											bgcSensorColor(
												sensorSelection(
													'megalith_work'
												)
											)[0]
										})`,
										height: 30,
										color: bgcSensorColor(
											sensorSelection(
												'megalith_work'
											)
										)[1],
										width: '100%',
										borderRadius: 4,
										paddingLeft: 10,
										boxSizing: 'border-box'
									}}>
									{t('Work')}
								</Flex>
							) : (
								<Skeleton.Input block active />
							)}
							{isSensorsFetched ? (
								<Flex
									align="center"
									style={{
										backgroundColor: `var(--${
											bgcSensorColor(
												sensorSelection(
													'megalith_damper'
												)
											)[0]
										})`,
										maxHeight: 50,
										color: bgcSensorColor(
											sensorSelection(
												'megalith_damper'
											)
										)[1],
										width: '100%',
										minWidth: 157,
										borderRadius: 4,
										padding: '5.5px 10px',
										boxSizing: 'border-box'
									}}>
									{sensorSelection(
										'megalith_damper'
									) === '1'
										? t('FireDamperClosed')
										: sensorSelection(
													'megalith_damper'
											  ) === '2'
											? t('FireDamperOpen')
											: t('FireDamper')}
								</Flex>
							) : (
								<Skeleton.Input block active />
							)}
						</Flex>
					</Flex>
				</Flex>
				<Flex
					flex={1}
					vertical
					gap={10}
					style={{
						backgroundColor: '#fff',
						borderRadius: 6,
						boxShadow: '0px 4px 8px 0px #E0E7F2',
						padding: 10
					}}>
					<h3
						style={{
							margin: 0
						}}>
						{t('Drinking water tank')}
					</h3>
					<WaterBoilerIcon
						color={getColorByPercentage(
							+sensorSelection('water_tank_percent')
						)}
						percent={
							+sensorSelection('water_tank_percent')
						}
					/>
				</Flex>
			</Flex>
			<style>
				{`.ant-switch-handle::before {
						border-radius: 2px !important;
					}
					.ant-switch {
						border-radius: 4px !important;
					}`}
			</style>
		</Flex>
	)
}
