import { FC } from 'react'

interface NotificationProps {
	count: number
	onClick: () => void
	color?: false | string
}

export const NotificationIcon: FC<NotificationProps> = ({
	count,
	onClick,
	color
}) => {
	return (
		<>
			<svg
				width="25"
				height="30"
				viewBox="0 0 25 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				onClick={onClick}>
				<g clipPath="url(#clip0_107_2)">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.2489 5.14339C11.0181 5.05095 10.7653 5 10.5 5C9.41063 5 8.53125 5.85897 8.53125 6.92308V7.79487C4.76438 8.66667 2.625 11.9487 2.625 15.8974V22.3077L0 24.8718V26.1538H21V24.8718L18.375 22.3077V15.8974C18.375 15.2436 18.3156 14.6075 18.1993 13.9972C18.1331 13.9991 18.0667 14 18 14C14.134 14 11 10.866 11 7C11 6.35722 11.0866 5.73467 11.2489 5.14339ZM13.125 27.4359C13.125 28.8462 11.9438 30 10.5 30C9.80381 30 9.13613 29.7299 8.64385 29.249C8.15156 28.7681 7.875 28.1159 7.875 27.4359H13.125Z"
						fill={color ? color : '#BCC6D8'}
					/>
				</g>
			</svg>
			<div className="notification-count">
				{count > 99 ? '99+' : count}
			</div>
		</>
	)
}
