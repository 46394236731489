import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../components'
import { RNBalance } from '../helpers/routeNames'
import { balanceStore } from '../store/balance'
import { useNavigate, useParams } from 'react-router-dom'
import { Balance } from '../helpers/types/balance'

const BalanceOne: FC = () => {
	const { t } = useTranslation()
	const { balance, fetchBalance } = balanceStore()
	const navigate = useNavigate()
	const { id } = useParams<{ id: string }>()

	useEffect(() => {
		if (!id) return
		fetchBalance(+id)
	}, [id, fetchBalance])

	const columns: any[] = [
		{
			title: t('Balance'),
			key: 'balance',
			render: (text: string, a: Balance) => (
				<span>{a.balance}</span>
			),
			sorter: (a: Balance, b: Balance) =>
				+a.balance - +b.balance
		},
		{
			title: t('LastUpdate'),
			render: (text: string, a: Balance) => (
				<span>
					{new Date(a.createdAt).toLocaleString('ru')}
				</span>
			),
			key: 'createdAt'
		}
	]

	const [searchEls, setSearchEls] = useState(balance[+id!])

	useEffect(() => {
		setSearchEls(balance[+id!])
	}, [balance, id])

	const onSearch = (value: string) => {
		if (value === '') {
			setSearchEls(balance[+id!])
			return
		}
		setSearchEls(
			balance[+id!].filter((el) => {
				return el.replanishmentDate
					.toLowerCase()
					.includes(value.toLowerCase())
			})
		)
	}

	const onAdd = () => {
		navigate(RNBalance + '/new')
	}

	const onRefresh = () => {}
	if (!id || !balance[+id]?.length)
		return (
			<div className="balance">
				<h3 style={{margin: 0, textAlign: 'center'}
				}>{t('NoData')}</h3>
			</div>
		)
	return (
		<div className="balance">
			<header className="page-header">
				<h1 className="page-h1">
					{t('BalanceOfCarrier') + ' №' + id}
				</h1>
			</header>
			<main>
				<Table
					dataSource={searchEls as any[]}
					columns={columns}
					onSearch={onSearch}
					onAdd={onAdd}
					onRefresh={onRefresh}
					disableAddButton
				/>
				{t('ReplanishmentDate')} -{' '}
				{new Date(
					balance[+id!][0]?.replanishmentDate
				).toLocaleString('ru')}
			</main>
		</div>
	)
}

export default BalanceOne
