import { Table } from 'antd'
import { FC } from 'react'
import { loggingStore } from '../store/logging'
import { useTranslation } from 'react-i18next'

interface Props {
	id: number
}

const CarrierOneLogging: FC<Props> = ({ id }) => {
	const { loggings } = loggingStore()
	const { t } = useTranslation()

	return (
		<div>
			<main
				style={{
					maxHeight: 200,
					position: 'relative',
					overflow: 'hidden'
				}}>
				<Table
					title={() => (
						<h2 style={{ margin: 0 }}>{t('EventLog')}</h2>
					)}
					dataSource={loggings[id]}
					locale={{ emptyText: t('NoData') }}
					style={{ maxHeight: 200 }}
					columns={[
						{
							title: '№',
							dataIndex: 'id',
							key: 'id'
						},
						{
							title: t('Time'),
							dataIndex: 'happenedAt',
							key: 'happenedAt',
							render: (happenedAt: string) => {
								const date = new Date(happenedAt)
								return date.toLocaleString('ru')
							}
						},
						{
							title: t('Type'),
							dataIndex: 'type',
							key: 'type'
						},
						{
							title: t('Source'),
							dataIndex: 'source',
							key: 'source'
						},
						{
							title: t('EventText'),
							dataIndex: 'text',
							key: 'text'
						}
					]}
				/>
				<nav
					style={{
						position: 'absolute',
						top: 65,
						width: '100%',
						left: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontSize: 24,
						fontWeight: 'bold',
						height: '-webkit-fill-available',
						backdropFilter: 'blur(8px)'
					}}>
					{t('TestMode')}
				</nav>
			</main>
		</div>
	)
}

export default CarrierOneLogging
