import { create } from 'zustand'
import { Weather } from '../helpers/types/weather'
import { fetchWeather } from '../api/util'

export interface UtilityState {
	sideBarShown: boolean
	headerSwitchCarrierNotificationShown: boolean
	weather: Weather[]
	setSideBarShown: (isShown: boolean) => void
	setHeaderSwitchCarrierNotificationShown: (
		isShown: boolean
	) => void
	fetchWeather: () => Promise<void>
}

export const utilityStore = create<UtilityState>((set) => ({
	sideBarShown: false,
	setSideBarShown: (isShown) => set({ sideBarShown: isShown }),

	headerSwitchCarrierNotificationShown: true,

	setHeaderSwitchCarrierNotificationShown: (isShown) =>
		set({ headerSwitchCarrierNotificationShown: isShown }),

	weather: [],
	fetchWeather: async () => {
		const weather = await fetchWeather()
		set({ weather })
	}
}))
