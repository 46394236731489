import { FC } from 'react'

interface Props {
	color: string
	bgColor: string
}

const HeaterIcon: FC<Props> = ({ color, bgColor }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none">
			<rect width="44" height="44" rx="4" fill={bgColor} />
			<path
				d="M11.0241 15.9813C11.5244 16.0871 12 15.73 12 15.2486V13.7514C12 13.27 11.5244 12.9129 11.0241 13.0187L8.62413 13.5264C8.25922 13.6036 8 13.908 8 14.2592V14.7408C8 15.092 8.25922 15.3963 8.62413 15.4736L11.0241 15.9813Z"
				fill={color}
			/>
			<path
				d="M32.9759 13.0187C32.4756 12.9129 32 13.27 32 13.7514L32 15.2486C32 15.73 32.4756 16.0871 32.9759 15.9813L35.3759 15.4736C35.7408 15.3964 36 15.092 36 14.7408L36 14.2592C36 13.908 35.7408 13.6037 35.3759 13.5264L32.9759 13.0187Z"
				fill={color}
			/>
			<rect
				x="13"
				y="12"
				width="3"
				height="19"
				rx="1.5"
				fill={color}
			/>
			<rect
				x="18"
				y="12"
				width="3"
				height="19"
				rx="1.5"
				fill={color}
			/>
			<rect
				x="23"
				y="12"
				width="3"
				height="19"
				rx="1.5"
				fill={color}
			/>
			<rect
				x="28"
				y="12"
				width="3"
				height="19"
				rx="1.5"
				fill={color}
			/>
		</svg>
	)
}

export default HeaterIcon
