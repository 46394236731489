import { create } from 'zustand'
import { Logging } from '../helpers/types/logging'
import { fetchLoggings } from '../api/logging'

export interface LoggingState {
	loggings: Record<Logging['carrierId'], Logging[]>
	fetchLoggings: (carrierId: number) => void
}

export const loggingStore = create<LoggingState>((set) => ({
	loggings: [],
	fetchLoggings: async (carrierId) => {
		if (carrierId in loggingStore.getState().loggings) return
		const loggings = await fetchLoggings(carrierId)
		set((state) => {
			return {
				loggings: {
					[carrierId]: loggings,
					...state.loggings
				}
			}
		})
	}
}))
