import axios from '../axios'
import { Token, User } from '../helpers/types/user'

export const login = async (email: string, password: string) => {
	const { data } = await axios.post('/api/v1/auth/login', {
		email,
		password
	})
	return data as Token
}

export const userInfo = async () => {
	const { data } = await axios.get('/api/v1/auth/userinfo', {
		withCredentials: true
	})
	return data as User
}

export const refresh = async () => {
	const { data } = await axios.get('/api/v1/auth/refresh', {
		withCredentials: true
	})
	return data as User
}

export const userinfo = async () => {
	const { data } = await axios.get('/api/v1/auth/userinfo', {
		withCredentials: true
	})
	return data as User
}

export const logout = async () => {
	const { data } = await axios.delete('/api/v1/auth/logout', {
		withCredentials: true
	})
	return data as User
}

export const tgLogin = async (body: Object) => {
	const { data } = await axios.post('/api/v1/auth/tg-login', body)

	return data
}

export const tgWidget = async (body: Object) => {
	const { data } = await axios.post('/api/v1/auth/tg-widget', body)

	return data
}
