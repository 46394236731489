import { Button, Modal, Upload } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload'
import { userStore } from '../store/user'

interface UploadProfileImageProps {
	src: string
	name: string
}

export const UploadProfileImage: FC<UploadProfileImageProps> = ({
	src,
	name
}) => {
	const { t } = useTranslation()

	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [uploading, setUploading] = useState(false)

	const [previewImage, setPreviewImage] = useState('')
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewTitle, setPreviewTitle] = useState('')

	const { changeAvatar } = userStore()

	const handleUpload = async () => {
		setUploading(true)
		changeAvatar(fileList[0].originFileObj as RcFile)
		setUploading(false)
		setFileList([])
	}

	const props: UploadProps = {
		onRemove: (file) => {
			const index = fileList.indexOf(file)
			const newFileList = fileList.slice()
			newFileList.splice(index, 1)
			setFileList(newFileList)
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file])

			return false
		},
		fileList,
		maxCount: 1,
		listType: 'picture',
		onPreview: async (file: UploadFile) => {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(
					file.originFileObj as RcFile
				)
			}

			setPreviewImage(file.url || (file.preview as string))
			setPreviewOpen(true)
			setPreviewTitle(
				file.name ||
					file.url!.substring(
						file.url!.lastIndexOf('/') + 1
					)
			)
		},
		onChange: ({ fileList: newFileList }) =>
			setFileList(newFileList),
		showUploadList: {
			showDownloadIcon: false
		}
	}
	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = (error) => reject(error)
		})

	const handleCancel = () => setPreviewOpen(false)

	return (
		<>
			<Upload {...props} accept="image/*">
				<figure style={{ margin: 0 }}>
					<img
						src={src}
						alt={name}
						srcSet={src}
						style={{
							width: '160px',
							height: '190px',
							borderRadius: '6px'
						}}
					/>
				</figure>

				{!fileList.length ? (
					<Button block type="link">
						{t('uploadPhoto')}
					</Button>
				) : null}
			</Upload>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}>
				<img
					alt="example"
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
			{fileList.length ? (
				<Button
					type="primary"
					onClick={handleUpload}
					disabled={fileList.length === 0}
					loading={uploading}
					style={{ marginTop: 16 }}
					block
					hidden={!fileList.length}>
					{t(uploading ? 'Uploading' : 'StartUpload')}
				</Button>
			) : null}
		</>
	)
}
