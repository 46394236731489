import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CarrierOneSettingsComp } from './CarrierOneSettingsComp'
import { CarrierOneSettingsRNGComp } from './CarrierOneSettingsRNGComp'
import { CarrierOneSettingsPCHComp } from './CarrierOneSettingsPCHComp'

export const CarrierOneSettings: FC<{ carrierId: number }> = ({
	carrierId
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { hash } = useLocation()

	return (
		<Flex style={{ height: '100%' }} gap={10} vertical>
			<Flex
				align="center"
				style={{
					width: '100%',
					height: 40,
					minHeight: 40,
					backgroundColor: '#647D99',
					fontWeight: 'bold',
					gap: 1,
					borderRadius: '6px',
					overflow: 'hidden'
				}}>
				<Flex
					align="center"
					justify="center"
					style={{
						flex: 1,
						fontSize: 17,
						cursor: 'pointer',
						color:
							hash === '#settings' ? '#fff' : '#A4B1BE',
						backgroundColor:
							hash === '#settings'
								? '#364F69'
								: '#4F6781',
						height: 40
					}}
					onClick={() => navigate('#settings')}>
					{t('Climate')}
				</Flex>
				<Flex
					align="center"
					justify="center"
					style={{
						flex: 1,
						fontSize: 17,
						cursor: 'pointer',
						color:
							hash === '#settings-rng'
								? '#fff'
								: '#A4B1BE',
						backgroundColor:
							hash === '#settings-rng'
								? '#364F69'
								: '#4F6781',
						height: 40
					}}
					onClick={() => navigate('#settings-rng')}>
					{t('RNG')}
				</Flex>
				<Flex
					align="center"
					justify="center"
					style={{
						flex: 1,
						fontSize: 17,
						cursor: 'pointer',
						color:
							hash === '#settings-pch'
								? '#fff'
								: '#A4B1BE',
						backgroundColor:
							hash === '#settings-pch'
								? '#364F69'
								: '#4F6781',
						height: 40
					}}
					onClick={() => navigate('#settings-pch')}>
					{t('PCH')}
				</Flex>
			</Flex>
			{hash === '#settings' && (
				<CarrierOneSettingsComp carrierId={+carrierId} />
			)}
			{hash === '#settings-rng' && (
				<CarrierOneSettingsRNGComp carrierId={+carrierId} />
			)}
			{hash === '#settings-pch' && (
				<CarrierOneSettingsPCHComp carrierId={carrierId} />
			)}
		</Flex>
	)
}
