import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { RNUsers } from '../helpers/routeNames'
import { userStore } from '../store/user'

const UserAdd: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { addUser } = userStore()

	const onFinish = async (values: any) => {
		await addUser({ ...values, password: '123456' })
		navigate(RNUsers)
	}

	return (
		<div className="users">
			<header className="page-header">
				<h1 className="page-h1">{t('AddUser')}</h1>
			</header>
			<main className="user-one">
				<Form labelCol={{ span: 2 }} onFinish={onFinish}>
					<Form.Item
						label={t('Name')}
						name={'name'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Name') as string} />
					</Form.Item>

					<Form.Item
						label={t('Email')}
						name={'email'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Email') as string} />
					</Form.Item>

					{/* <Form.Item
						label={t('IsDeleted')+'?'}
						name={'isDeleted'}
						rules={[{ required: true }]}
						valuePropName="checked">
						<Checkbox name="isDeleted" />
					</Form.Item> */}
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{t('Save')}
						</Button>
						<Button
							htmlType="button"
							onClick={() => navigate(RNUsers)}
							style={{ marginLeft: '20px' }}
							danger>
							{t('Cancel')}
						</Button>
					</Form.Item>
				</Form>
			</main>
		</div>
	)
}

export default UserAdd
