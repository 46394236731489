import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { RNCarriers } from '../helpers/routeNames'
import { carrierStore } from '../store/carrier'

const CarrierAdd: FC = () => {
	const { t } = useTranslation()
	const { id } = useParams<{ id: string }>()
	const navigate = useNavigate()

	const { addCarrier } = carrierStore()

	const onFinish = async (values: any) => {
		await addCarrier(values)
		navigate(RNCarriers)
	}

	return (
		<div className="carriers">
			<header className="page-header">
				<h1 className="page-h1">
					{t('ViewCarrier')} № {id}
				</h1>
			</header>
			<main className="carrier-one">
				<Form labelCol={{ span: 3 }} onFinish={onFinish}>
					<Form.Item
						label={t('Name')}
						name={'name'}
						rules={[{ required: true }]}>
						<Input placeholder={t('Name') as string} />
					</Form.Item>

					<Form.Item
						label={t('FactoryNumber')}
						name={'factoryNumber'}
						rules={[{ required: true }]}>
						<Input
							placeholder={
								t('FactoryNumber') as string
							}
						/>
					</Form.Item>

					{/* <Form.Item
						label={t('IsDeleted?')}
						name={'isDeleted'}
						rules={[{ required: true }]}
						valuePropName="checked">
						<Checkbox name="isDeleted" />
					</Form.Item> */}
					<Form.Item>
						<Button type="primary" htmlType="submit">
							{t('Save')}
						</Button>
						<Button
							htmlType="button"
							onClick={() => navigate(RNCarriers)}
							style={{ marginLeft: '20px' }}
							danger>
							{t('Cancel')}
						</Button>
					</Form.Item>
				</Form>
			</main>
		</div>
	)
}

export default CarrierAdd
