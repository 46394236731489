import { FC } from 'react'

interface Props {
	color: string
	bgColor: string
}

const FanIcon: FC<Props> = ({ color, bgColor }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="44"
			height="44"
			viewBox="0 0 44 44"
			fill="none">
			<rect width="44" height="44" rx="4" fill={bgColor} />
			<path
				d="M22.1393 17.9504C23.049 17.9504 23.8828 18.2314 24.5651 18.7167C25.3736 18.027 26.8392 17.7971 28.1026 16.8521C31.3622 14.4255 28.0773 8.78054 19.8147 10.2365C13.725 11.3093 16.3782 16.7754 18.5007 19.9427C19.2588 18.7422 20.6232 17.9504 22.1393 17.9504Z"
				fill={color}
			/>
			<path
				d="M17.7932 22.318C17.7932 22.2159 17.7932 22.0881 17.8185 21.986C16.7825 21.6539 15.8476 20.4279 14.382 19.7893C10.6676 18.129 7.4838 23.8506 12.8659 30.3385C16.833 35.115 20.1684 30.1086 21.8108 26.6858C19.562 26.507 17.7932 24.6169 17.7932 22.318Z"
				fill={color}
			/>
			<path
				d="M26.0053 20.3774C26.3086 20.9649 26.4602 21.629 26.4602 22.3187C26.4602 24.0811 25.4242 25.6137 23.9333 26.3033C24.1355 27.3761 23.5796 28.7554 23.7565 30.3391C24.186 34.4004 30.6799 34.3493 33.5605 26.4055C35.6578 20.5818 29.7703 20.122 26.0053 20.3774Z"
				fill={color}
			/>
			<path
				d="M25.0452 22.3179C25.0452 20.6832 23.7312 19.355 22.1141 19.355C20.4969 19.355 19.183 20.6832 19.183 22.3179C19.183 23.9527 20.4969 25.2809 22.1141 25.2809C23.7312 25.2809 25.0452 23.9527 25.0452 22.3179Z"
				fill={color}
			/>
		</svg>
	)
}

export default FanIcon
