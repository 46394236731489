import { FC } from 'react'
import { IconProps } from './ClimatIcon'

const HomeIcon: FC<IconProps> = ({ selected }) => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M24.4826 11.8034L13.1237 0.262836C13.042 0.179517 12.9448 0.113416 12.8379 0.0683154C12.7309 0.0232147 12.6163 0 12.5005 0C12.3847 0 12.2701 0.0232147 12.1632 0.0683154C12.0562 0.113416 11.9591 0.179517 11.8773 0.262836L0.518442 11.8034C0.187522 12.1398 0 12.5969 0 13.0735C0 14.0633 0.791451 14.8679 1.76491 14.8679H2.96174V24.1028C2.96174 24.5991 3.35608 25 3.84419 25H10.7356V17.7194H13.8242V25H21.1568C21.645 25 22.0393 24.5991 22.0393 24.1028V14.8679H23.2361C23.7049 14.8679 24.1544 14.6801 24.4853 14.3408C25.172 13.6399 25.172 12.5043 24.4826 11.8034Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
		</svg>
	)
}

export default HomeIcon
