import { FC } from 'react'

interface CollapseProps {
	collapsed: boolean
	collapsedColor?: string
	expandedColor?: string
}

export const CollapseIcon: FC<CollapseProps> = ({
	collapsed,
	collapsedColor,
	expandedColor
}) => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				transform: !collapsed ? 'rotate(180deg)' : '',
				transition: '0.3s'
			}}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.2983 18.1484C19.866 18.6096 19.134 18.6096 18.7017 18.1484L15 14.2L11.2983 18.1484C10.866 18.6096 10.134 18.6096 9.70166 18.1484C9.30704 17.7275 9.30704 17.0725 9.70166 16.6516L15 11L20.2983 16.6516C20.693 17.0725 20.693 17.7275 20.2983 18.1484Z"
				fill={
					collapsed
						? (collapsedColor ?? '#fff')
						: (expandedColor ?? '#61748A')
				}
			/>
		</svg>
	)
}
