import { create } from 'zustand'
import { User } from '../helpers/types/user'
import {
	addUser,
	changeAvatar,
	deleteUser,
	fetchUsers,
	restoreUser,
	updateUser
} from '../api/user'
import { RcFile } from 'antd/es/upload'

export interface UserState {
	users: User[]
	isFetched: boolean
	fetchUsers: () => Promise<void>
	addUser: (user: Partial<User>) => Promise<void>
	updateUser: (user: User) => Promise<void>
	changeAvatar: (form: RcFile) => Promise<void>
	deleteUser: (id: number) => Promise<void>
	restoreUser: (id: number) => Promise<void>
}

export const userStore = create<UserState>((set) => ({
	users: [],
	isFetched: false,
	fetchUsers: async () => {
		set(() => ({ isFetched: false }))
		const data = await fetchUsers()
		set(() => ({ users: data, isFetched: true }))
	},
	addUser: async (user: Partial<User>) => {
		const data = await addUser(user)
		set((users) => ({
			users: [...users.users, data]
		}))
	},
	updateUser: async (user: User) => {
		await updateUser(user)
		set((users) => ({
			users: users.users.map((u) =>
				u.id === user.id ? user : u
			)
		}))
	},
	changeAvatar: async (form: RcFile) => {
		const formData = new FormData()
		formData.append('avatar', form)
		const data = await changeAvatar(formData)
		set((users) => ({
			users: users.users.map((u) =>
				u.id === data.id ? { ...u, avatar: data.avatar } : u
			)
		}))
	},
	deleteUser: async (id: number) => {
		await deleteUser(id)
		set((users) => ({
			users: users.users.map((u) =>
				u.id === id ? { ...u, isDeleted: true } : u
			)
		}))
	},
	restoreUser: async (id: number) => {
		await restoreUser(id)
		set((users) => ({
			users: users.users.map((u) =>
				u.id === id ? { ...u, isDeleted: false } : u
			)
		}))
	}
}))
