import { FC } from 'react'
import { IconProps } from './ClimatIcon'

const LightningIcon: FC<IconProps> = ({ selected }) => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17 21V22.6667C17 23.9467 16.0783 25 14.9583 25H12.0417C11.0617 25 10 24.1467 10 22.28V21H17Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
			<path
				d="M18.8553 3.82627C16.864 2.26789 14.2414 1.6586 11.6795 2.18587C8.46196 2.83031 5.85148 5.36122 5.18369 8.46627C4.50375 11.6533 5.74221 14.817 8.38911 16.7503C9.10547 17.2659 9.60328 18.0626 9.77327 18.9883V19C9.79755 18.9883 9.83397 18.9883 9.85826 18.9883H17.1433C17.1676 18.9883 17.1797 18.9883 17.204 19V18.9883C17.374 18.0978 17.9204 17.2776 18.7582 16.6449C20.8101 15.0747 22 12.7313 22 10.2004C22 7.71637 20.8587 5.39637 18.8553 3.82627ZM18.0539 10.7863C17.5561 10.7863 17.1433 10.3879 17.1433 9.90748C17.1433 8.12647 15.6499 6.68526 13.8043 6.68526C13.3065 6.68526 12.8937 6.28688 12.8937 5.80647C12.8937 5.32607 13.3065 4.92768 13.8043 4.92768C16.6455 4.92768 18.9646 7.16566 18.9646 9.90748C18.9646 10.3879 18.5517 10.7863 18.0539 10.7863Z"
				fill={selected ? '#fff' : '#3894EB'}
			/>
		</svg>
	)
}

export default LightningIcon
