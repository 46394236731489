import { Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SensorCHD } from './sensors'
import { sensorStore } from '../store/sensor'

export const CarrierOneClimat: FC<{
	carrierId: number
	sideBarShown: boolean
}> = ({ carrierId, sideBarShown }) => {
	const { t } = useTranslation()
	const { sensors } = sensorStore()

	const finderFC = (code: string) => {
		return (sensors
			.find((s) => {
				return (
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
				)
			})
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.value === '1') as boolean
	}

	const finderColor = (code: string) => {
		return sensors
			.find(
				(s) =>
					s.code === code &&
					!!s.sensorData.find(
						(a) => a.carrierId === carrierId
					)
			)
			?.sensorData.find((s) => s.carrierId === carrierId)
			?.color as 'red' | 'green' | 'yellow' | undefined
	}

	return (
		<Flex gap={10} vertical>
			{!sideBarShown && (
				<header className="page-header">
					<h1 className="page-h1">{t('Climat')}</h1>
				</header>
			)}
			<Flex
				vertical
				gap={10}
				style={{
					backgroundColor: '#fff',
					borderRadius: 6,
					boxShadow: '0px 4px 8px 0px #E0E7F2'
				}}>
				<h3 style={{ margin: '10px 0 6px 10px' }}>
					{t('Heating')}
				</h3>
				<h4
					style={{
						margin: 0,
						textAlign: 'center'
					}}>
					{t('High voltage heating 3000 V')}
				</h4>
				<Flex vertical>
					<Flex wrap={!sideBarShown}>
						<SensorCHD
							name={'1 гр'}
							value={finderFC('gr1_3000')}
							color={finderColor('gr1_3000')}
							code="gr1_3000"
						/>
						<SensorCHD
							name={'2 гр'}
							value={finderFC('gr2_3000')}
							color={finderColor('gr2_3000')}
							code="gr2_3000"
						/>
						<SensorCHD
							name={'Контроль'}
							value={finderFC('climate_control')}
							color={finderColor('climate_control')}
							code="climate_control"
						/>
						<SensorCHD
							name={'Сброс зашиты'}
							value={finderFC(
								'climate_reset_protection'
							)}
							color={finderColor(
								'climate_reset_protection'
							)}
							code="climate_reset_protection"
						/>
						<SensorCHD
							name={'Насос отопление'}
							value={finderFC('pump_heating')}
							color={finderColor('pump_heating')}
							code="pump_heating"
						/>
					</Flex>
					<Flex>
						<Flex vertical>
							<h4
								style={{
									margin: 0,
									textAlign: 'center',
									border: '1px solid #EAECF3',
									padding: '10px 0'
								}}>
								{t('Electric heater')}
							</h4>
							<Flex wrap={!sideBarShown}>
								<SensorCHD
									name={'1 гр'}
									value={finderFC('gr1_heater')}
									color={finderColor('gr1_heater')}
									code="gr1_heater"
								/>
								<SensorCHD
									name={'2 гр'}
									value={finderFC('gr2_heater')}
									color={finderColor('gr2_heater')}
									code="gr2_heater"
								/>
							</Flex>
						</Flex>
						<Flex vertical>
							<h4
								style={{
									margin: 0,
									textAlign: 'center',
									border: '1px solid #EAECF3',
									padding: '10px 0'
								}}>
								{t('Electric heater')}
							</h4>
							<Flex wrap={!sideBarShown}>
								<SensorCHD
									name={'1 гр'}
									value={finderFC('gr1_low_heater')}
									color={finderColor(
										'gr1_low_heater'
									)}
									code="gr1_low_heater"
								/>
								<SensorCHD
									name={'2 гр'}
									value={finderFC('gr2_low_heater')}
									color={finderColor(
										'gr2_low_heater'
									)}
									code="gr2_low_heater"
								/>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
			<Flex
				vertical
				gap={6}
				style={{
					backgroundColor: '#fff',
					borderRadius: 6,
					boxShadow: '0px 4px 8px 0px #E0E7F2'
				}}>
				<h3 style={{ margin: '16px 0 6px 10px' }}>
					{t('Conditioning')}
				</h3>
				<Flex wrap={!sideBarShown}>
					<SensorCHD
						name={'Вентилятор'}
						value={finderFC('condit_fan')}
						color={finderColor('condit_fan')}
						code="condit_fan"
					/>
					<SensorCHD
						name={'Компрессор'}
						value={finderFC('condit_compressor')}
						color={finderColor('condit_compressor')}
						code="condit_compressor"
					/>
					<SensorCHD
						name={'Сброс зашиты ПЧ'}
						value={finderFC('condit_reset_prot')}
						color={finderColor('condit_reset_prot')}
						code="condit_reset_prot"
					/>
					<SensorCHD
						name={'Заслонки'}
						value={finderFC('condit_flash')}
						color={finderColor('condit_flash')}
						code="condit_flash"
					/>
				</Flex>
			</Flex>
		</Flex>
	)
}
